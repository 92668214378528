import React, { useEffect, useRef } from 'react';
import { Dropdown } from 'rsuite';
import { uploadDoc } from 'whealth-core-web/assets/images';
import Select2DropDown from '../common/Select2DropDown';
import CoreInputBox from '../CoreInputBox';
import CoreTextArea from '../CoreTextArea';
import { titlizeString } from '../Helper';

function PaymentDocumentationForm(props) {
  const {
    step,
    formCount,
    editIndex,
    setIsSubmitBtn,
    errors,
    setEditIndex,
    step3Data,
    paymentDocumentation,
    setPaymentDocumentation,
    deletePayee,
    editTableData,
    reportUpdateItemId,
    onClick,
    paymentData,
    dynamicServiceData,
    updateData,
    step4Data,
    setStep4Data,
    error,
    paymentServiceDetails,
    setPaymentServiceDetails,
  } = props;
  const inputRef = useRef();

  const handleInputChange = (index, field, value) => {
    const newStepServiceDetailsList = [...paymentDocumentation];
    newStepServiceDetailsList[index] = { ...newStepServiceDetailsList[index], [field]: value };
    setPaymentDocumentation(newStepServiceDetailsList);
  };

  useEffect(() => {
    if (step4Data.insurance_status) {
      setIsSubmitBtn(true);
    }
  }, []);

  const renderPaymentPayeeDetails = (item) => {
    return (
      <>
        <div className='upload-doc d-flex align-items-center gap-10 mb-4'>
          <div className='doc-file d-flex align-items-center w-25'>
            <span class='material-icons-outlined'>attach_file</span>
            <div>{item.payee}</div>
          </div>
          <div className='doc-Detail d-flex align-items-center justify-content-between w-100'>
            <div className='me-4'>{item.description}</div>
            <div className='d-flex align-items-center gap-10 action-icon-part'>
              <span
                onClick={() => updateData(item)}
                class='material-icons-outlined d-flex align-items-center justify-content-center'
              >
                edit
              </span>
              <span
                onClick={() => {
                  deletePayee(item?.id);
                }}
                class='material-icons-outlined  d-flex align-items-center justify-content-center'
              >
                delete
              </span>
            </div>
          </div>
        </div>
      </>
    );
  };

  const status = [
    { title: 'Pending Approval', id: 'pending_approval' },
    { title: 'Approved', id: 'approved' },
    { title: 'Rejected', id: 'rejected' },
  ];

  const renderInsuranceForm = () => {
    const lastIndexTable = dynamicServiceData?.payment_details
      ? dynamicServiceData?.payment_details[
          dynamicServiceData?.payment_details?.length - dynamicServiceData?.payment_details?.length
        ]
      : [];
    return (
      <div className='insurance-form d-flex justify-content-start mb-5'>
        <div className='w-25  me-4 insurance-status-change align-items-center justify-content-between'>
          <label>Status</label>
          <div className='insurance-status'>
            <Dropdown
              className={`capitalize pointer w-100 ${step4Data.insurance_status}`}
              title={
                <div className='d-flex justify-content-between w-100'>
                  <div className='capitalize'>{titlizeString(step4Data.insurance_status) || 'Pending Approval'}</div>
                  <div className='action-icon-part'>
                    <span class='material-icons-outlined d-flex align-items-center justify-content-center'>edit</span>
                  </div>
                </div>
              }
            >
              {status?.map((statusItem) => {
                return (
                  <Dropdown.Item
                    onClick={() => {
                      setStep4Data({
                        ...step4Data,
                        insurance_status: statusItem?.id,
                      });
                      setIsSubmitBtn(true);
                    }}
                  >
                    {statusItem.title}
                  </Dropdown.Item>
                );
              })}
            </Dropdown>
          </div>
        </div>
        {step == '3' && (step4Data.insurance_status == 'approved' || step4Data.insurance_status == 'rejected') ? (
          <div className='w-25  me-4 '>
            <CoreInputBox
              retuired
              showMSG={errors?.final_bill_amount}
              disabled={
                lastIndexTable?.payment_type !== paymentServiceDetails?.payment_type &&
                dynamicServiceData.final_bill_amount > 0
              }
              onlyNumAndDecimals
              label={'Final Bill'}
              value={step4Data?.final_bill_amount}
              setValue={(amount) => {
                setStep4Data({ ...step4Data, final_bill_amount: amount || null });
              }}
            />
          </div>
        ) : null}
        {step4Data.insurance_status == 'approved' ? (
          <div className='w-25  me-4'>
            <CoreInputBox
              retuired
              showMSG={errors.insured_amount}
              onlyNumAndDecimals
              label={'Approved Amount'}
              value={step4Data?.insured_amount || null}
              setValue={(amount) => {
                setStep4Data({ ...step4Data, insured_amount: amount || null });
              }}
            />
          </div>
        ) : null}
      </div>
    );
  };
  return (
    <div className='uploadDocumentPart'>
      {step == '3' && renderInsuranceForm()}

      {/* {paymentData.map((item, index) => {
        return renderPaymentPayeeDetails(item, index);
      })}

      <div className='title mb-2'>Payment Documentation</div>
      {[...Array(formCount)].map((_, index) => (
        <div className='uploadDocument mb-4' key={index}>
          <div className='w-100 d-flex'>
            <div className='w-25  mt-4'>
              <Select2DropDown
                searchable={false}
                retuired
                data={[
                  { title: 'Patient', id: 'patient' },
                  { title: 'Business', id: 'business' },
                  { title: 'Insurance', id: 'insurance' },
                ]}
                className='slot-name'
                labelStyle={{ margin: '', fontWeight: 'var(--lightFont)' }}
                placeholder={'Select'}
                label={'Payee'}
                onSelect={(data) => {
                  handleInputChange(index, 'payee', data);
                }}
                value={paymentDocumentation[index]?.payee || ''}
                showMSG={error && error?.payee[index]}
                msgStyle={{ color: 'red' }}
                valueKey='id'
                labelKey='title'
              />
            </div>
            <div className=' ms-4 mt-4'>
              <CoreInputBox
                retuired
                validateNumbers
                validateZero
                validatedecimal
                label={'Amount'}
                value={paymentDocumentation[index]?.amount || ''}
                showMSG={error && error?.payee[index]}
                setValue={(title) => handleInputChange(index, 'amount', title)}
              />
            </div>
          </div>
          <div className='  mt-4'>
            <CoreTextArea
              label={'Description'}
              rows={'3'}
              value={paymentDocumentation[index]?.description || ''}
              setValue={(title) => handleInputChange(index, 'description', title)}
            />
          </div>
          <div className='mx-5 mt-5'>
            <div
              onClick={() => {
                onClick(index);
              }}
              class='form-control  coreBtn text-white btn btn-secondary btn-lg w-100'
            >
              {reportUpdateItemId ? 'Update Details' : 'Save Details'}
            </div>
          </div>
        </div>
      ))} */}
    </div>
  );
}

export default PaymentDocumentationForm;
