import useApiManager from 'networking/ApiManager';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PaymentDocumentationForm from './PaymentDocumentationForm';

function PaymentDocumentation(props) {
  const {
    step,
    formCount,
    errors,
    editIndex,
    setEditIndex,
    setFormCount,
    editTableData,
    patientId,
    setAlertType,
    step3Data,
    setIsLoading,
    setEditTableData,
    setIsShowAlert,
    setIsAddPayment,
    serviceDataForPayment,
    paymentServiceDetails,
    setPaymentServiceDetails,
    dynamicServiceData,
    setDynamicServiceData,
    step4Data,
    finalSubmitForm,
    setIsSubmitBtn,
    setStep4Data,
  } = props;
  const [paymentDocumentationDetail, setPaymentDocumentationDetail] = useState(
    Array.from({ length: formCount }, () => ({}))
  );
  const [paymentData, setPaymentData] = useState([]);
  const [reportUpdateItemId, setReportUpdateItemId] = useState('');
  const ApiClient = useApiManager();
  const [error, seError] = useState('');
  const navigate = useNavigate();
  useEffect(() => {
    getPatientPaymentDetail();
  }, [patientId]);

  const getPatientPaymentDetail = () => {
    setIsLoading(true);
    let params = { payment_type: 'insurance' };
    ApiClient.getPatientPaymentDocs(patientId, false, params)
      .then((res) => {
        // setTotalPages(res.headers['total-pages']);
        setPaymentData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  const prepateFormData = (data, index) => {
    const formData = new FormData();
    formData.append('payee', paymentDocumentationDetail[index]?.payee || '');
    formData.append('amount', paymentDocumentationDetail[index]?.amount || '');
    formData.append('description', paymentDocumentationDetail[index]?.description || '');
    formData.append('user_order_id', serviceDataForPayment.user_order_id);

    return formData;
  };

  const createPaymentDocs = (index) => {
    setIsLoading(true);
    ApiClient.createPatientPaymentDocs(patientId, prepateFormData(paymentDocumentationDetail, index))
      .then((res) => {
        setIsLoading(false);
        seError('');
        getPatientPaymentDetail();
        if (formCount === 1) {
          const updatedStepServiceDetails = [...paymentDocumentationDetail];
          updatedStepServiceDetails[index] = {};
          setPaymentDocumentationDetail(updatedStepServiceDetails);
        } else {
          setFormCount(formCount - 1);
        }
        setAlertType('alert-success');
        setIsShowAlert('Report created successfully');
      })
      .catch((err) => {
        console.warn('err', err);
        setIsLoading(false);
        setAlertType('alert-danger');
        seError(err.response.data.errors);
      });
  };

  const updatePaymentDocs = (index) => {
    setIsLoading(true);
    ApiClient.updatePayeeDetails(patientId, prepateFormData(paymentDocumentationDetail, index), reportUpdateItemId)
      .then((res) => {
        setIsLoading(false);
        setAlertType('alert-success');
        setIsShowAlert('Report updated successfully');
        getPatientPaymentDetail();
        seError('');
        setReportUpdateItemId('');
        if (formCount === 1) {
          const updatedStepServiceDetails = [...paymentDocumentationDetail];
          updatedStepServiceDetails[index] = {};
          setPaymentDocumentationDetail(updatedStepServiceDetails);
        } else {
          setFormCount(formCount - 1);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setAlertType('alert-danger');
        seError(err.response.data.errors);
        setIsShowAlert(err.response.data.errors.report);
      });
  };

  const updateData = (item) => {
    seError('');
    setReportUpdateItemId(item?.id);
    if (item) {
      setFormCount(1);
      const updatedStepServiceDetails = [...paymentDocumentationDetail];
      updatedStepServiceDetails[0] = {
        payee: item.payee || '',
        amount: item.amount || '',
        description: item.description || '',
      };
      setPaymentDocumentationDetail(updatedStepServiceDetails);
    }
  };

  const deletePayee = (reportId) => {
    setIsLoading(true);
    ApiClient.deletePayee(patientId, reportId)
      .then((res) => {
        setIsLoading(false);
        getPatientPaymentDetail();
        // setStepServiceDetails({});
        setIsShowAlert(res.data.message);
        setAlertType('alert-success');
      })
      .catch((err) => {
        if (err.response.status == 500) {
          setIsShowAlert('Internal server error');
        } else {
          if (!Array.isArray(Object.values(err.response.data)[0])) {
            setIsShowAlert(Object.values(err.response.data)[0]);
          }
        }
        setIsLoading(false);
        seError(err.response.data.errors);
        setAlertType('alert-danger');
      });
  };

  const getServiceData = (patientId, paymentUpdateId) => {
    setIsLoading(true);
    ApiClient.singleDataService(patientId, paymentUpdateId)
      .then((res) => {
        setIsLoading(false);
        setDynamicServiceData(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  // const finalSubmitForm = () => {
  //   const { profile_answers, ...paymentDetails } = paymentServiceDetails;
  //   setIsLoading(true);
  //   seError('');
  //   ApiClient.finalServiceButtonApi(
  //     patientId,
  //     editTableData?.id
  //       ? { ...paymentServiceDetails, payment_id: editTableData?.id }
  //       : { steps: [{ ...paymentDetails }], profile_answers },
  //     serviceDataForPayment?.id || dynamicServiceData?.id
  //   )
  //     .then((res) => {
  //       setIsLoading(false);
  //       setIsAddPayment(false);
  //       setEditTableData('');
  //       setPaymentServiceDetails({ ...paymentServiceDetails, payment_type: '' });
  //       setAlertType('alert-success');
  //       setIsShowAlert(`payment ${editTableData ? 'updated' : 'created'} successfully`);
  //       // setTimeout(() => {
  //       //   navigate(`/patient/${patientId}/dashboard`);
  //       // }, 2500);
  //       getServiceData(patientId, res?.data?.id);
  //     })
  //     .catch((err) => {
  //       setIsLoading(false);
  //       setAlertType('alert-danger');
  //       seError(err.response.data.errors);
  //       setIsShowAlert(err.response.data.errors.report);
  //     });
  // };

  const renderForm = () => {
    return (
      <div className='insuranceTab'>
        <PaymentDocumentationForm
          errors={errors}
          setIsSubmitBtn={setIsSubmitBtn}
          editIndex={editIndex}
          setEditIndex={setEditIndex}
          dynamicServiceData={dynamicServiceData}
          editTableData={editTableData}
          step3Data={step3Data}
          step4Data={step4Data}
          setStep4Data={setStep4Data}
          error={error}
          setPaymentServiceDetails={setPaymentServiceDetails}
          paymentServiceDetails={paymentServiceDetails}
          updateData={updateData}
          deletePayee={deletePayee}
          paymentData={paymentData}
          step={step}
          reportUpdateItemId={reportUpdateItemId}
          onClick={reportUpdateItemId ? updatePaymentDocs : createPaymentDocs}
          formCount={formCount}
          setFormCount={setFormCount}
          paymentDocumentation={paymentDocumentationDetail}
          setPaymentDocumentation={setPaymentDocumentationDetail}
        />
        <div className='d-flex gap-10 Documentbtn w-100'>
          {/* <div
            onClick={() => {
              !reportUpdateItemId && setFormCount(formCount + 1);
            }}
            disabled={step === 0}
            className='form-control coreBtn  btn mt-3 btn-outline-primary  btn-lg w-100'
          >
            + Add Another Payee
          </div> */}
          <div
            onClick={() => {
              if (step4Data?.insurance_status) {
                finalSubmitForm();
              } else {
                alert('please fill the status');
              }
            }}
            disabled={step === 3}
            className='form-control  coreBtn text-white btn btn-secondary btn-lg w-100'
          >
            Submit
          </div>
        </div>
      </div>
    );
  };
  return renderForm();
}

export default PaymentDocumentation;
