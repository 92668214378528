import useApiManager from 'networking/ApiManager';
import React, { useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { useNavigate } from 'react-router-dom';
import { useGetLoginUserDataQuery } from 'redux/apiSlice';
import { useGetCockpitDataQuery } from 'redux/cockpitSlice';
import '../inputBox.css';
import Select2DropDown from '../common/Select2DropDown';
import TagSelectPicker from '../common/TagSelectPicker';
import CoreButton from '../CoreButton';
import CoreInputBox from '../CoreInputBox';
import { dateFormat, scrollToBottom, splitArrData, splitArrObjData, timeFormat, titlizeString } from '../Helper';
import { Dropdown } from 'rsuite';
import CommonToolTip from '../CommonToolTip';

function PaymentDetailsPage(props) {
  const {
    setPaymentServiceDetails,
    paymentServiceDetails,
    setDynamicServiceData,
    finalSubmitId,
    isEditEnsurance,
    setIsEditInsurance,
    patientDetails,
    pid,
    editData,
    setEditData,
    editIndex,
    setEditIndex,
    isShowAlert,
    patientId,
    isPaymentType,
    setIsPaymentType,
    isNivanCare,
    dynamicServiceData,
    serviceDataForPayment,
    setIsLoading,
    setAlertType,
    setIsShowAlert,
    isAddPayment,
    setIsAddPayment,
  } = props;
  const [isPaymentMode, setIsPaymentMode] = useState('');
  const [singleDataPayment, setSingleDataPayment] = useState('');
  const [isShowInsuranceType, setIsShowInsuranceType] = useState('');
  const [isShowInsuranceTPA, setIsShowInsuranceTPA] = useState('');
  const [clinicalTeamList, setClinicalTeamList] = useState([]);
  const [paymentTypeIndex, setPaymentTypeIndex] = useState('');

  const [errors, setErrors] = useState({});
  const ApiClient = useApiManager();
  let userData = useGetLoginUserDataQuery()?.data?.data || {};
  const getCockpitData = useGetCockpitDataQuery();
  let configData = getCockpitData?.data?.data?.business_config;
  const [isClinicShow, setIsClinicShow] = useState(true);
  const [isUpdatePaymentId, setIsUpdatePaymentId] = useState('');
  console.warn('finalSubmitId', finalSubmitId);
  useEffect(() => {
    let isClinicDisable = configData?.find((configItem) => configItem?.key == 'departments_clinics');
    if (isClinicDisable?.key) {
      setIsClinicShow(isClinicDisable?.isActive);
    }
  }, [configData]);
  useEffect(() => {
    if (pid) {
      setPaymentServiceDetails(singleDataPayment.payment_type);
    }
  }, []);

  useEffect(() => {
    if (editData) {
      setErrors({});
      scrollToBottom();
      setIsPaymentType(editData?.payment_type);
      setIsPaymentMode(editData?.payment_mode);
      setPaymentServiceDetails({
        payment_type: editData?.payment_type,
        payment_mode: editData?.payment_mode,
        payment_from: editData?.payment_from,
        amount: editData?.amount,
        patient_attendance_status: editData?.patient_attendance_status,
        payment_with_id: editData?.payment_with?.id || null,
        payment_with_source: editData?.payment_with_source,
        insurance_status: dynamicServiceData?.insurance_status,
        insured_amount: editData?.amount,
        final_bill_amount: dynamicServiceData?.final_bill_amount,
        reason: dynamicServiceData?.reason,
      });
      setIsPaymentType(editData.payment_type);
    }
  }, [editData]);

  useEffect(() => {
    if (singleDataPayment?.id) {
      setIsPaymentType(singleDataPayment?.payment_type);
      setIsPaymentMode(singleDataPayment?.payment_mode);
      setPaymentServiceDetails({
        payment_type: singleDataPayment?.payment_type,
        payment_mode: singleDataPayment?.payment_mode,
        payment_from: singleDataPayment?.payment_from,
        amount: singleDataPayment?.amount,
        patient_attendance_status: singleDataPayment?.patient_attendance_status,
        payment_with_id: singleDataPayment?.payment_with?.id || null,
        payment_with_source: singleDataPayment?.payment_with_source,
      });
    } else if (dynamicServiceData) {
      const catFindIndex = dynamicServiceData?.profile_info?.find((item) => item.key == 'insurance_category');
      const typeFindIndex = dynamicServiceData?.profile_info?.find((item) => item.key == 'insurance_type');
      const tpaFindIndex = dynamicServiceData?.profile_info?.find((item) => item.key == 'insurance_tpa');
      setIsShowInsuranceType(catFindIndex?.answer);
      setIsShowInsuranceTPA(typeFindIndex?.answer);
      setPaymentServiceDetails({
        profile_answers: [catFindIndex, typeFindIndex, tpaFindIndex],
      });
    }
  }, [singleDataPayment]);
  useEffect(() => {
    if (!pid) {
      const catFindIndex = patientDetails?.profile_info?.find((item) => item.key == 'insurance_category');
      const typeFindIndex = patientDetails?.profile_info?.find((item) => item.key == 'insurance_type');
      const tpaFindIndex = patientDetails?.profile_info?.find((item) => item.key == 'insurance_tpa');
      setIsShowInsuranceType(catFindIndex?.answer);
      setIsShowInsuranceTPA(typeFindIndex?.answer);
      setPaymentServiceDetails({
        profile_answers: [catFindIndex, typeFindIndex, tpaFindIndex],
      });
    }
  }, []);

  useEffect(() => {
    if (isNivanCare && paymentServiceDetails?.payment_with_source == 'AdminProfile') {
      getDoctor();
    }
  }, [paymentServiceDetails?.payment_with_source]);

  useEffect(() => {
    if (dynamicServiceData.final_bill_amount > 0) {
      setPaymentServiceDetails({
        ...paymentServiceDetails,
        final_bill_amount: dynamicServiceData.final_bill_amount,
        // amount: dynamicServiceData.final_bill_amount,
      });
    }
  }, [dynamicServiceData]);

  const getPaymentServiceData = (patientId) => {
    setIsLoading(true);
    ApiClient.singleDataService(patientId, !pid ? serviceDataForPayment?.id : dynamicServiceData?.id)
      .then((res) => {
        setIsLoading(false);
        setSingleDataPayment(res?.data);
        setIsUpdatePaymentId(res?.data?.id);
        setDynamicServiceData(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const patientPaymentServiceCreate = (data) => {
    setIsLoading(true);
    ApiClient.patientPaymentServiceCreate(
      { ...data, user_order_id: serviceDataForPayment.user_order_id || dynamicServiceData?.user_order_id },
      patientId
    )
      .then((res) => {
        setIsLoading(false);
        setEditData({});
        setIsAddPayment(false);
        setPaymentServiceDetails({ ...paymentServiceDetails, amount: '' });
        setAlertType('alert-success');
        setIsShowAlert('Payment Service created successfully ');
        getPaymentServiceData(patientId, res.data.id);
      })
      .catch((err) => {
        setIsLoading(false);
        setAlertType('alert-danger');
        setIsShowAlert(err?.response?.data?.errors?.message);
        setErrors(err.response.data.errors);
      });
  };
  const updatePatientPaymentService = (data) => {
    const FinalSubmitId = dynamicServiceData.payment_details?.find((item) => item.payment_type == 'insurance');
    setIsLoading(true);
    ApiClient.updatePaymentServiceData(patientId, editData?.id, {
      ...data,
      user_order_id: serviceDataForPayment.user_order_id || dynamicServiceData?.user_order_id,
    })
      .then((res) => {
        setIsLoading(false);
        setEditData({});
        setEditIndex('');
        setIsEditInsurance(false);
        setIsAddPayment(false);
        setAlertType('alert-success');
        setIsShowAlert('Payment Service updated successfully ');
        getPaymentServiceData(patientId, res.data.id);
      })
      .catch((err) => {
        setIsLoading(false);
        setAlertType('alert-danger');
        setErrors(err.response.data.errors);
        setIsShowAlert(err?.response?.data?.errors?.message);
      });
  };

  const getDoctor = () => {
    setIsLoading(true);
    let params = { can_accept_payment: true, remove_inactive_admins: true };
    ApiClient.getSearchTeam(params)
      .then((res) => {
        setIsLoading(false);
        setClinicalTeamList(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const renderDropdownData = () => {
    if (isShowInsuranceType?.includes('Private insurance') && isShowInsuranceType?.includes('Govt insurance')) {
      return [
        { title: 'Corporate' },
        { title: 'Retail' },
        { title: 'CGHS' },
        { title: 'DGHS' },
        { title: 'DJB' },
        { title: 'ECHS' },
        { title: 'ESIC' },
        { title: 'Ayushman' },
        { title: 'MCD' },
      ];
    } else if (isShowInsuranceType?.includes('Govt insurance')) {
      return [
        { title: 'CGHS' },
        { title: 'DGHS' },
        { title: 'DJB' },
        { title: 'ECHS' },
        { title: 'ESIC' },
        { title: 'Ayushman' },
        { title: 'MCD' },
      ];
    } else if (isShowInsuranceType?.includes('Private insurance')) {
      return [{ title: 'Corporate' }, { title: 'Retail' }];
    }
  };

  const renderInsurance = (item, index) => {
    let type = item.type;
    let { options, question, key } = item;
    let arrData = splitArrObjData(options, '|', 'title');
    if (
      key == 'insurance_type' &&
      (isShowInsuranceType?.includes('Private insurance') || isShowInsuranceType?.includes('Govt insurance')) &&
      type == 'single_select'
    ) {
      let activeData = patientDetails?.profile_info[index]?.answer;
      return (
        <div className='w-25 me-4 capitalize '>
          <Select2DropDown
            retuired={item?.is_mandatory}
            label={titlizeString(question)}
            data={renderDropdownData()}
            style={{ width: '100%' }}
            labelKey='title'
            valueKey='title'
            placeholder={titlizeString(key)}
            defaultValue={activeData}
            onSelect={(val, titleArray) => {
              const updatedProfileAnswer = [...patientDetails.profile_info];
              const catIndex = updatedProfileAnswer.find((item) => item.key == 'insurance_category');
              const TPA_INDEX = updatedProfileAnswer.find((item) => item.key == 'insurance_tpa');
              const type_index = updatedProfileAnswer.find((item) => item.key == 'insurance_type');
              updatedProfileAnswer[index].answer = val;
              setPaymentServiceDetails({
                ...paymentServiceDetails,
                profile_answers: [catIndex, TPA_INDEX, type_index],
              });
              setIsShowInsuranceTPA(val);
            }}
            msgStyle={{ color: 'red' }}
            value={activeData}
          />
        </div>
      );
    } else if (
      key == 'insurance_tpa' &&
      (isShowInsuranceTPA == 'Retail' || isShowInsuranceTPA == 'Corporate') &&
      type == 'single_select'
    ) {
      let activeData = patientDetails?.profile_info[index]?.answer;
      return (
        <div className='w-25 me-4 capitalize paymentDetails-form'>
          <Select2DropDown
            retuired={item?.is_mandatory}
            label={titlizeString(question)}
            data={arrData}
            style={{ width: '100%' }}
            labelKey='title'
            valueKey='title'
            placeholder={titlizeString(key)}
            defaultValue={activeData}
            onSelect={(val, titleArray) => {
              const updatedProfileAnswer = [...patientDetails.profile_info];
              const catIndex = updatedProfileAnswer.find((item) => item.key == 'insurance_category');
              const TPA_INDEX = updatedProfileAnswer.find((item) => item.key == 'insurance_tpa');
              const type_index = updatedProfileAnswer.find((item) => item.key == 'insurance_type');
              updatedProfileAnswer[index].answer = val;
              setPaymentServiceDetails({
                ...paymentServiceDetails,
                profile_answers: [catIndex, TPA_INDEX, type_index],
              });
            }}
            msgStyle={{ color: 'red' }}
            value={activeData}
          />
        </div>
      );
    } else if (key == 'insurance_category' && type == 'multi_select') {
      let activeData = splitArrData(patientDetails?.profile_info[index]?.answer?.toLowerCase(), '|');
      return (
        <div className='w-25 me-4 capitalize'>
          <TagSelectPicker
            label={titlizeString(question)}
            data={arrData}
            style={{ width: '100%' }}
            labelKey='title'
            valueKey='value'
            placeholder={titlizeString(key)}
            defaultValue={activeData}
            onSelect={(val, titleArray) => {
              const updatedProfileAnswer = [...patientDetails.profile_info];
              updatedProfileAnswer[index].answer = titleArray.join('|');
              const catIndex = updatedProfileAnswer.find((item) => item.key == 'insurance_category');
              setPaymentServiceDetails({
                ...paymentServiceDetails,
                profile_answers: [catIndex],
              });
              setIsShowInsuranceType(titleArray.join('|'));
            }}
            msgStyle={{ color: 'red' }}
            value={activeData}
          />
        </div>
      );
    } else {
      return;
    }
  };
  const renderPaymentDetails = () => {
    return (
      <>
        <div className='table-responsive'>
          <table className='w-100 table'>
            <thead>
              <tr>
                <th>Sr.No.</th>
                <th>Payment Type</th>
                <th> Payment Mode</th>
                <th> Payment Time & Date</th>
                <th> Amount</th>
                <th> Status</th>
                <th> Action</th>
              </tr>
            </thead>
            <tbody>
              {dynamicServiceData?.payment_details?.map((item, index) => {
                return (
                  <tr className='capitalize'>
                    <td>{index + 1}</td>
                    <td className={` ${item.payment_type == 'emi' && 'text-uppercase'}`}>
                      {item.payment_type == 'paid' ? 'Fully Paid' : titlizeString(item.payment_type) || 'N/A'}
                    </td>
                    <td>{item.payment_mode || 'N/A'}</td>
                    <td>
                      {' '}
                      {dateFormat(item.created_at) || 'N/A'} {timeFormat(item.created_at)}
                    </td>
                    <td>{item.amount || 'N/A'}</td>
                    <td>
                      {titlizeString(item.payment_status) || 'N/A'} {item.payment_type == 'insurance' && '('}
                      {item.payment_type == 'insurance' && titlizeString(dynamicServiceData?.last_step)}
                      {item.payment_type == 'insurance' && ')'}
                    </td>
                    <td>
                      <div className='d-flex align-items-center gap-10 action-icon-part'>
                        <button
                          onClick={() => {
                            setEditData(item);
                            setEditIndex(index);
                            setIsEditInsurance(true);
                          }}
                          type='button'
                          class='btn '
                        >
                          <span class='material-icons-outlined d-flex align-items-center justify-content-center'>
                            edit
                          </span>
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </>
    );
  };

  const paymentData = [
    isNivanCare &&
    dynamicServiceData?.payment_details?.some((item) => item?.payment_type === 'partially_paid') &&
    editIndex !== 0
      ? [
          { title: 'Free', id: 'free' },
          { title: 'Partially Paid', id: 'partially_paid' },
          { title: 'Insurance', id: 'insurance' },
          { title: 'EMI', id: 'emi' },
        ]
      : [
          { title: 'Free', id: 'free' },
          { title: 'Fully Paid', id: 'paid' },
          { title: 'Partially Paid', id: 'partially_paid' },
          { title: 'Insurance', id: 'insurance' },
          { title: 'EMI', id: 'emi' },
        ],
  ];

  const handleItemClick = (paymentId, isInsurance, index) => {
    setPaymentTypeIndex(index);
    if (paymentId === 'insurance' && isInsurance && editData?.payment_type !== 'insurance') {
      alert('you have already created insurance payment,please select another payment method.');
    } else if (
      (paymentId === 'free' || paymentId == 'emi' || paymentId == 'partially_paid' || paymentId == 'paid') &&
      isNivanCare
    ) {
      setPaymentServiceDetails({
        ...paymentServiceDetails,
        payment_type: paymentId,
        amount: !editData?.id && !isAddPayment ? dynamicServiceData?.final_bill_amount : null,
        payment_mode: '',
        final_bill_amount: !editData?.id || editIndex > 0 ? dynamicServiceData?.final_bill_amount : null,
        payment_with_source: null,
      });
      setIsPaymentType(paymentId);
      setIsPaymentMode('');
      setIsAddPayment(true);
    } else {
      setPaymentServiceDetails({
        ...paymentServiceDetails,
        payment_type: paymentId,
        // payment_mode: '',
      });
      setIsPaymentType(paymentId);
      setIsPaymentMode('');
    }
  };

  const showPaymentDetails = () => {
    const isInsurance = dynamicServiceData?.payment_details?.find((item) => item?.payment_type === 'insurance');
    const isFullyPaidExists = dynamicServiceData?.payment_details?.some((item) => item?.payment_type === 'paid');

    const lastIndexTable = dynamicServiceData?.payment_details
      ? dynamicServiceData?.payment_details[
          dynamicServiceData?.payment_details?.length - dynamicServiceData?.payment_details?.length
        ]
      : [];
    return (
      <>
        <div className='d-flex'>
          {(isAddPayment || editData?.id) && (
            <div className='me-4 capitalize'>
              <div className='inputFieldHeader'>
                <label className='inputlabel'>Payment Type</label>
              </div>
              <Dropdown
                className='capitalize pointer '
                title={
                  <div className='capitalize'>
                    {titlizeString(paymentServiceDetails?.payment_type) || 'Payment Type'}
                  </div>
                }
              >
                {paymentData?.map((paymentOptions, index) => {
                  return paymentOptions.map((option) => {
                    if (
                      dynamicServiceData?.payment_details?.length &&
                      (option.id == 'free' || option.id == 'paid') &&
                      editIndex !== 0
                    ) {
                      return null;
                    }
                    if (option.id !== 'paid' || !isFullyPaidExists) {
                      return (
                        <Dropdown.Item onClick={() => handleItemClick(option.id, isInsurance, index)} key={option.id}>
                          {option.title}
                        </Dropdown.Item>
                      );
                    }
                    return null;
                  });
                })}
              </Dropdown>
            </div>
          )}
          {isPaymentType && isPaymentType !== 'insurance' && isPaymentType !== 'free' && isPaymentType !== 'emi' && (
            <div className='w-25 me-4'>
              <Select2DropDown
                // disabled={
                //   (editData.payment_type == 'partially_paid' && paymentServiceDetails.payment_mode) ||
                //   (editData.payment_type == 'paid' && paymentServiceDetails.payment_mode)
                // }
                retuired
                showMSG={errors?.payment_mode}
                searchable={false}
                value={paymentServiceDetails?.payment_mode || ''}
                data={[
                  {
                    id: 'cash',
                    title: 'Cash',
                  },
                  {
                    id: 'online',
                    title: 'Online',
                  },
                ]}
                label={'Mode'}
                placeholder={'Mode'}
                getObjData
                onSelect={(modeId, modeObj) => {
                  setIsPaymentMode(modeId);
                  setPaymentServiceDetails({ ...paymentServiceDetails, payment_mode: modeId });
                }}
              />
            </div>
          )}
          {isPaymentType && isPaymentType !== 'insurance' && isPaymentMode == 'online' && (
            <div className='w-25 me-4'>
              <Select2DropDown
                retuired
                showMSG={errors.payment_from}
                searchable={false}
                value={paymentServiceDetails.payment_from}
                data={[
                  { title: 'UPI', id: 'upi' },
                  { title: 'PoS', id: 'pos' },
                  { title: 'Payment Link', id: 'payment_link' },
                  { title: 'Bank Transfer', id: 'bank_transfer' },
                ]}
                label={'From'}
                placeholder={'From'}
                valueKey='id'
                labelKey='title'
                getObjData
                onSelect={(fromId) => {
                  setPaymentServiceDetails({ ...paymentServiceDetails, payment_from: fromId });
                }}
              />
            </div>
          )}
          {isPaymentType && isPaymentType !== 'free' && isPaymentType !== 'insurance' && (
            <>
              <div className='w-25 '>
                <div className='inputFieldHeader'>
                  <label className='inputlabel' htmlFor='validationCustom01 mt-3 slot-name'>
                    <span style={{ color: 'red' }}>*&nbsp;</span>
                    Final Bill
                  </label>
                </div>
                <CurrencyInput
                  disabled={editIndex > 0 || (!editData?.id && dynamicServiceData?.final_bill_amount > 0)}
                  id='validationCustom01'
                  name='input-1'
                  className={`form-control coreInput`}
                  label='Amount'
                  value={paymentServiceDetails?.final_bill_amount}
                  showMSG={errors?.final_bill_amount}
                  msgStyle={{ color: 'red' }}
                  onValueChange={(amount) => {
                    if (isPaymentType == 'paid') {
                      setPaymentServiceDetails({ ...paymentServiceDetails, final_bill_amount: amount, amount });
                    } else {
                      setPaymentServiceDetails({
                        ...paymentServiceDetails,
                        final_bill_amount: amount,
                      });
                    }
                  }}
                  placeholder='Final Bill'
                  prefix={'₹'}
                  step={1}
                />
                <p style={{ color: 'red' }}>{errors?.final_bill_amount}</p>
              </div>
            </>
          )}

          {isPaymentType == 'insurance' &&
            paymentServiceDetails.payment_type &&
            patientDetails?.profile_info?.map((item, index) => {
              return renderInsurance(item, index);
            })}
        </div>
        {isPaymentType && isPaymentType !== 'insurance' && isPaymentType !== 'free' && (
          <div className='d-flex'>
            <div className='w-25 mt-4 '>
              <div className='inputFieldHeader'>
                <label className='inputlabel'>
                  <span className='requiredInput'> * </span>
                  Amount
                </label>
              </div>
              <CurrencyInput
                disabled={isPaymentType == 'paid' && paymentServiceDetails.amount}
                id='validationCustom01'
                name='input-1'
                className={`form-control coreInput`}
                label='Amount'
                value={paymentServiceDetails.amount}
                showMSG={errors?.amount}
                msgStyle={{ color: 'red' }}
                onValueChange={(val) => setPaymentServiceDetails({ ...paymentServiceDetails, amount: val })}
                prefix={'₹'}
                step={1}
                placeholder='Amount'
              />
              <p style={{ color: 'red' }}>{errors?.amount}</p>
            </div>
            {isPaymentType !== 'emi' && (
              <div className='w-25 ms-4 mt-4'>
                <Select2DropDown
                  retuired
                  showMSG={errors.payment_with_source}
                  valueKey='id'
                  labelKey='title'
                  data={
                    userData?.role == 'ee'
                      ? [{ title: 'Nivaan Clinic', id: 'NivaanClinic' }]
                      : isNivanCare && isClinicShow
                      ? [
                          { title: 'Clinical Team', id: 'AdminProfile' },
                          { title: 'Nivaan Clinic', id: 'NivaanClinic' },
                          { title: 'Clinic', id: 'Location' },
                        ]
                      : isClinicShow
                      ? [
                          { title: 'Clinic', id: 'Location' },
                          { title: 'Doctor', id: 'AdminProfile' },
                        ]
                      : [{ title: 'Doctor', id: 'AdminProfile' }]
                  }
                  onSelect={(_id, _obj) => {
                    setPaymentServiceDetails({
                      ...paymentServiceDetails,
                      payment_with_source: _obj?.id,
                      payment_with_id:
                        _obj?.id == 'AdminProfile' && !isNivanCare
                          ? serviceDataForPayment?.doctor
                          : _obj?.id == 'Location'
                          ? serviceDataForPayment?.location ||
                            (_obj?.id !== 'NivaanClinic' && dynamicServiceData?.location?.id)
                          : null,
                    });
                  }}
                  value={paymentServiceDetails.payment_with_source || null}
                  searchable={false}
                  style={{ width: '100%' }}
                  placeholder='Payment With'
                  label='Payment With'
                />
              </div>
            )}
            <div className='w-25 ms-4 mt-4'>
              {paymentServiceDetails?.payment_with_source == 'AdminProfile' &&
              paymentServiceDetails.payment_type !== 'emi' &&
              isNivanCare ? (
                <Select2DropDown
                  retuired
                  showMSG={errors.payment_with_id}
                  data={clinicalTeamList}
                  onSelect={(_id, _obj) => {
                    setPaymentServiceDetails({ ...paymentServiceDetails, payment_with_id: _obj?.id || null });
                  }}
                  value={paymentServiceDetails?.payment_with_id}
                  labelKey={'full_name'}
                  searchable={true}
                  style={{ width: '100%' }}
                  placeholder='Clinical Team'
                  label='Clinical Team'
                />
              ) : null}
            </div>
          </div>
        )}
        {isPaymentType && isPaymentType !== 'insurance' && (
          <div className='mx-5 mt-5'>
            <div
              onClick={() => {
                editData?.id
                  ? updatePatientPaymentService(paymentServiceDetails)
                  : patientPaymentServiceCreate(paymentServiceDetails);
              }}
              class='form-control  coreBtn text-white btn btn-secondary btn-lg w-100'
            >
              {editData?.id ? 'Update Details' : 'Save Details'}
            </div>
          </div>
        )}
      </>
    );
  };
  const removeUpdateScreen = () => {
    setIsAddPayment(false);
    setEditIndex('');
    setIsEditInsurance(false);
    setEditData({});
    setPaymentServiceDetails({ ...paymentServiceDetails, payment_type: '' });
  };
  return (
    <div className=''>
      {dynamicServiceData?.payment_details?.length ? (
        <div className='d-flex justify-content-between gap-16 payment-service-detail'>
          <div className='d-flex w-100 flex-column'>
            <div className='mb-2 headingTitleLink'>Payment History</div>
            <div
              className='p-3 mb-4 insurancePart'
              style={{ borderRadius: '10px', maxHeight: '272px', overflow: 'scroll' }}
            >
              {renderPaymentDetails()}
            </div>
          </div>
        </div>
      ) : null}

      <div className='d-flex flex-column'>
        {(!dynamicServiceData?.payment_details?.length ||
          dynamicServiceData?.payment_details[dynamicServiceData?.payment_details?.length - 1]?.payment_type !=
            'free') &&
          !editData?.id &&
          !isAddPayment &&
          !dynamicServiceData?.fully_paid_flag && (
            <CoreButton
              onClick={() => {
                setIsAddPayment(true);
                setPaymentServiceDetails({ ...paymentServiceDetails, payment_type: '' });
              }}
              title={'+ Add Payment'}
              className='w-50'
            />
          )}

        {editData?.id && (
          <>
            <div className='d-flex pointer'>
              <div className=' mt-4 headingTitleLink '> Update Payment</div>
              <CommonToolTip
                placement={'top'}
                item={
                  <span onClick={() => removeUpdateScreen()} class='material-icons-outlined mt-4 ms-2'>
                    close
                  </span>
                }
                showValue={'Close Update Payment'}
              />
            </div>
            <div className='w-100 mb-3 mt-2 hrLine' />
          </>
        )}
      </div>
      {isAddPayment || editData ? showPaymentDetails() : null}
    </div>
  );
}

export default PaymentDetailsPage;
