import React, { useEffect, useState } from 'react';
// import { Select2DropDown } from '../whealth-core-web/components/index';
// import { setDoctorsList, setLocations } from 'redux/Slices';
import { useDispatch, useSelector } from 'react-redux';
import useApiManager from 'networking/ApiManager';
import { Select2DropDown } from 'whealth-core-web/components';
import { setDoctorsList } from 'redux/Slices';

function BillingDoctorFilter(props) {
  const { filterData, setFilterData, clearFiltersData } = props;
  const [selectedClinic, setSelectedClinic] = useState();
  // const locations = useSelector((state) => state.sliceReducer.locations);
  const allDoctorsList = useSelector((state) => state.sliceReducer.allDoctorsList);
  const ApiClient = useApiManager();
  const dispatch = useDispatch();

  useEffect(() => {
    let obj = { doctor_id: selectedClinic };
    setFilterData(obj);
  }, [selectedClinic]);

  useEffect(() => {
    setSelectedClinic(filterData?.doctor_id);
  }, [filterData]);

  useEffect(() => {
    setSelectedClinic();
  }, [clearFiltersData]);

  useEffect(() => {
    const getData = async () => {
      const res = await ApiClient.getServiceDoctor();
      if (res.status == 200) {
        let locationsData = [];
        res.data.map((item) => locationsData.push({ id: JSON.stringify(item.id), title: item?.full_name }));
        dispatch(setDoctorsList(locationsData));
      }
    };
    try {
      getData();
    } catch (e) {
      console.log(e);
    }
  }, []);

  const renderHeading = () => {
    return <div className='filterHeadings mt-3'>Doctor</div>;
  };

  const renderChips = () => {
    return (
      <>
        <div className='position-relative'>
          <div className='mt-3' />
          <Select2DropDown
            container={document.getElementById('clinic-dropdown-container')}
            className='zindex-popover'
            placement='bottomStart'
            placeholder='Select Doctor'
            data={allDoctorsList}
            labelStyle={{ fontWeight: 'var(--lightFont)' }}
            onSelect={(data) => {
              setSelectedClinic(data);
            }}
            value={selectedClinic || ''}
            msgStyle={{ color: 'red' }}
            style={{ position: 'relative', width: '100%' }}
          />
          <div id='clinic-dropdown-container' style={{ position: 'absolute', bottom: '0px' }}></div>
        </div>
      </>
    );
  };

  return (
    <div>
      {renderHeading()}
      {renderChips()}
    </div>
  );
}

export default BillingDoctorFilter;
