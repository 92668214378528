import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import useApiManager from 'networking/ApiManager';
import ModalBody from 'rsuite/esm/Modal/ModalBody';
import TextField from '@mui/material/TextField';

import '../../assets/style/ZohoCalendar.css';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { dateFormat, timeFormat, titlizeString } from '../Helper';
import CommonBorderBox from '../CommonBorderBox';
import Loader from '../Loader';
import Pagination from '../Pagination';
import { laptopLink } from 'whealth-core-web/assets/images';
import CommonToolTip from '../CommonToolTip';
import { editIconBlack } from 'res/images';
import { LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { Modal } from 'rsuite';
import RecordPayment from '../AppointmentVer2/RecordPayment';
import AppointmentCancel from '../AppointmentVer2/AppointmentCancel';
import CoreButton from '../CoreButton';
import CommonAlert from '../CommonAlert';
import { useGetLoginUserDataQuery } from 'redux/apiSlice';

export const ZohoConsultation = (props) => {
  let environment = process.env.REACT_APP_ENVIRONMENT;
  let serviceID = environment == 'prod' ? 3 : 52;

  const { getConsultationData, isNivanCare, configData } = props;
  let userData = useGetLoginUserDataQuery()?.data?.data || {};
  const [currentEvents, setCurrentEvents] = useState([]);
  const [consultationItem, setConsultationItem] = useState('');

  const [selectedDate, setSelectedDate] = useState(null);
  const [originalSelectDate, setOriginalSelectedDate] = useState(null);
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [errors, setErrors] = useState('');
  const [alertError, setAlertError] = useState('');
  const [open, setOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const [formVal, setFormVal] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [editStatus, setEditStatus] = useState(false);
  const [alertType, setAlertType] = useState('alert-danger');
  const [recordErrors, setRecordErrors] = useState({});
  const [consultationItemId, setConsultationItemId] = useState('');

  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [dateTotalPages, setDateTotalPages] = useState(1);
  const [dateCurrentPage, setDateCurrentPage] = useState(1);
  const [consultData, setConsultData] = useState([]);
  const [consultDateData, setConsultDateData] = useState([]);
  const [pageChanges, setpageChanges] = useState(false);
  const [createdAppointment, setCreatedAppointment] = useState({});
  const [cancelModal, setCancelModal] = useState(false);
  const [recordModal, setRecordModal] = useState(false);

  const navigate = useNavigate();
  const ApiClient = useApiManager();
  const { id } = useParams();

  useEffect(() => {
    getConsultationCalenderData();
  }, [currentPage]);

  useEffect(() => {
    if (!recordModal) {
      setRecordErrors({});
      setCreatedAppointment({
        payment_type: null,
        payment_mode: null,
        payment_from: null,
        amount: null,
        patient_attendance_status: null,
        payment_with_id: null,
        payment_with_source: null,
      });
    }
  }, [recordModal]);

  useEffect(() => {
    setpageChanges(false);

    if (originalSelectDate) {
      handleCreateConsultation(selectedDate);
    }
  }, [dateCurrentPage]);

  const getConsultationCalenderData = (page = currentPage, consult_datetime = new Date(), page_size = 4) => {
    const pages = { page, consult_datetime, page_size };
    setIsLoading(true);
    ApiClient.getConsultationDataApi(pages)
      .then((res) => {
        setIsLoading(false);
        setConsultData(res.data);
        setTotalPages(res.headers['total-pages']);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };
  const handleCreateConsultation = (selected) => {
    setIsLoading(true);
    const originalDate = new Date(selected.end);
    originalDate.setDate(originalDate.getDate() - 1);
    setOriginalSelectedDate(originalDate);
    const params = { consult_datetime: originalDate, page: dateCurrentPage, page_size: 4 };
    ApiClient.getConsultationDataApi(params)
      .then((res) => {
        setConsultDateData(res.data);
        setIsLoading(false);
        setCurrentPage(1);
        setDateTotalPages(res.headers['total-pages']);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const createFollowUp = () => {
    setIsLoading(true);
    let params = { next_consultation_datetime: formVal.next_consultation_datetime };
    ApiClient.createFollowUp(consultationItemId, id, params)
      .then((res) => {
        getConsultationData();
        setIsLoading(false);
        setOpen(false);
        setAlertType('alert-success');
        setIsShowAlert('Patient Status Updated Successfully');
        setIsShowAlert('Follow up consultation created successfully.');
      })
      .catch((err) => {
        setIsLoading(false);
        setErrors(err?.response.data?.message);
      });
  };

  const handleRecordPayment = (idAppointment, recordPaymentData) => {
    setIsLoading(true);
    ApiClient.recordPayment(idAppointment, recordPaymentData)
      .then((res) => {
        setIsLoading(false);
        handleCreateConsultation(selectedDate);
        getConsultationCalenderData();
        setAlertType('alert-success');
        setIsShowAlert('Payment Recorded Successfully');
        setRecordModal(false);
        getConsultationData();
      })
      .catch((err) => {
        setIsLoading(false);
        setRecordErrors(err?.response?.data?.errors);
      });
  };

  const handleCancelAppointment = (idAppointment, recordPaymentData, patientID) => {
    setIsLoading(true);
    ApiClient.cancelAppointment(idAppointment)
      .then((res) => {
        setIsLoading(false);
        handleCreateConsultation(selectedDate);
        getConsultationCalenderData();
        setAlertType('alert-success');
        setIsShowAlert('Appointment Canceled Successfully');
        setCancelModal(false);
        saveNoteForPatient(recordPaymentData, patientID);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  const saveNoteForPatient = (content, patientID) => {
    ApiClient.notes({ content: content?.note }, patientID)
      .then((res) => {
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handleModalClose = () => {
    // Check if next_consultation_datetime is empty, and set a default value
    setModal(false);
    if (formVal.next_consultation_datetime) {
      formVal.next_consultation_datetime = null;
      setFormVal({ ...formVal });
    }
    setOpen(false);
  };

  const status = [
    { title: 'Show', id: 'show' },
    { title: 'No Show', id: 'no_show' },
  ];

  const handleDateClick = async (selected) => {
    handleCreateConsultation(selected);
    setSelectedDate(selected);
    setDateCurrentPage(1);
  };

  const handleEventClick = (selected) => {
    setSelectedDate(selected);
  };

  const renderBoxConsultaion = () => {
    return (
      <div className='zoho-calendar-content mt-3 w-50'>
        <div className='zoho-date text-start'>
          <strong>{dateFormat(selectedDate?.startStr || new Date())}</strong>
        </div>
        {!consultData.length && <div className='text-center mb-4'>Consultation Not Available</div>}
        {consultData.map((item, index) => {
          return (
            <CommonBorderBox className=''>
              {/* <div style={{ cursor: 'pointer' }} className='d-flex justify-content-start'>
                <div
                  onClick={() =>
                    navigate(`/patient/${item.user.id}/appointment/${item.id}/edit`, {
                      state: {
                        check_in: 'check_in',
                      },
                    })
                  }
                  className='p-3 ConsultationTimeCard d-flex align-items-center text-center'
                >
                  {timeFormat(item.consult_datetime)}
                </div>
                <div className='p-3  w-100'>
                  <div className='d-flex justify-content-between align-items-start'>
                    <div
                      onClick={() =>
                        navigate(`/patient/${item.user.id}/appointment/${item.id}/edit`, {
                          state: {
                            check_in: 'check_in',
                          },
                        })
                      }
                      className=''
                    >
                      <strong className='capitalize'>
                        {item.title} {item?.category == 'follow_up' && (item?.category).replace('_', '-')}
                      </strong>
                      &nbsp;
                      <strong>{item?.category == 'follow_up' && dateFormat(item.consult_datetime)}</strong>
                    </div>
                    <div className='d-flex  justify-content-end'>
                      <span class='badge capitalize enable me-2'>
                        <label class='form-check-label'>{item?.consultation_status}</label>
                      </span>
                      <div>
                        <StatusCard
                          tagPencil
                          selectedDate={selectedDate}
                          handleCreateConsultation={handleCreateConsultation}
                          originalSelectDate={originalSelectDate}
                          consultationItem={item}
                          dashBoardStatus
                          setUpdateStatus={setEditStatus}
                          consultId={item.id}
                          patientIdConsult={item.user.id}
                          updateStatus={editStatus}
                          status={status}
                          getPatientData={getConsultationCalenderData}
                          badgeTitle={titlizeString(item.patient_attendance_status) || 'Select Status'}
                          order
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    onClick={() =>
                      navigate(`/patient/${item.user.id}/appointment/${item.id}/edit`, {
                        state: {
                          check_in: 'check_in',
                        },
                      })
                    }
                  >
                    {item?.user?.full_name}{' '}
                    {item.user?.gender
                      ? `(${item?.user?.gender.charAt(0).toUpperCase()}${item?.user?.age ? ',' : ''}${
                          item?.user?.age ? item?.user?.age : ''
                        }${item?.user?.age ? 'yrs' : ''})`
                      : null}{' '}
                    with {item.consultant?.full_name}
                  </div>
                </div>
              </div> */}
              <div className='consultationtabPart appointmentTilePart' key={index}>
                <div className='consultationTital mb-1'>
                  <Link to={`/patient/${item?.user.id}/dashboard`}>
                    {item?.user?.full_name} ({item?.user?.gender && item?.user?.gender[0].toUpperCase()}, {item?.user?.age})
                  </Link>
                </div>
                <div className='d-flex w-100 justify-content-between align-items-center'>
                  <div className='consultationTital'>
                    {dateFormat(item.consult_datetime)} | {timeFormat(item.consult_datetime)}-{timeFormat(item.consult_end_datetime)}{' '}
                    <span>({renderDuration(item)})</span>
                  </div>

                  <div className='d-flex align-items-center'>
                    {item?.consultation_status != 'canceled' && item.patient_attendance_status !== 'show' && item?.service?.id != serviceID ? (
                      <CommonToolTip
                        placement={'top'}
                        item={
                          <div
                            onClick={() => {
                              setCreatedAppointment(item);
                              setCancelModal(true);
                            }}
                            className='d-flex me-2'
                          >
                            <span class='material-icons-outlined'>close</span>
                          </div>
                        }
                        showValue={'Cancel'}
                      />
                    ) : null}
                    <div>
                      {item?.consultation_status != 'canceled' && item.patient_attendance_status !== 'show' && item?.service?.id != serviceID && (
                        <CommonToolTip
                          placement={'top'}
                          item={
                            <img
                              onClick={() =>
                                navigate(`/patient/${item.user.id}/appointment/${item.id}/edit`, {
                                  state: {
                                    edit: 'edit',
                                  },
                                })
                              }
                              src={editIconBlack}
                              className='icon'
                              style={{ cursor: 'pointer', width: '20px' }}
                            />
                          }
                          showValue={'Edit'}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className='me-2'>
                  <div className='d-flex consultationbadge align-items-end'>
                    {item?.service?.title ? (
                      <span class='badge capitalize follow me-2'>
                        <label class='form-check-label'>{item?.service?.title}</label>
                      </span>
                    ) : null}
                    {item?.consult_type == 'virtual' ? (
                      <span class='badge capitalize follow me-2'>
                        <label class='form-check-label d-flex align-items-center'>
                          <img src={laptopLink} className='me-1' /> Online
                        </label>
                      </span>
                    ) : null}
                    <span class='badge capitalize enable me-2'>
                      <label class='form-check-label'>{titlizeString(item.consultation_status) || 'Scheduled'}</label>
                    </span>
                  </div>
                </div>
                <div className='d-flex mt-1 w-100 justify-content-between align-items-end'>
                  <div className='d-flex flex-column w-50'>
                    <div className='consultationDate'>{item?.consultant?.full_name}</div>
                    <div className='consultationDate align-items-center mt-1 d-flex'>
                      <div>{item?.location?.name} </div>
                      {item?.meeting_link && item?.consult_type == 'virtual' && item?.service?.id != serviceID ? (
                        <div className='appointmentTilelink text-nowrap ms-2'>{item?.meeting_link}</div>
                      ) : null}
                    </div>
                    <p className='consultationDate'>Created By : {item?.admin?.full_name}</p>
                  </div>
                  <div className='d-flex align-items-center'>
                    {item.patient_attendance_status !== 'no_show' && item?.consultation_status != 'canceled' && item?.service?.id != serviceID ? (
                      <div className='me-2'>
                        <div
                          onClick={() => {
                            setCreatedAppointment(item);
                            setRecordModal(true);
                          }}
                          className='d-flex justify-content-center align-items-center record-btn badge'
                        >
                          Record Payment
                        </div>
                      </div>
                    ) : null}

                    <div>
                      {item?.consultation_status != 'canceled' && item?.consult_type == 'virtual' && item?.meeting_link && item?.service?.id != serviceID ? (
                        <a target='_blank' href={item?.meeting_link} class='badge capitalize lightRedBadge closebtn pointer'>
                          <label class='form-check-label pointer'>Join Link</label>
                        </a>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </CommonBorderBox>
          );
        })}
        <div className='mt-2'>
          <Pagination totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} dashboard scroll />
        </div>
      </div>
    );
  };

  const renderDuration = (item) => {
    let duration =
      new Date(item?.consult_end_datetime).getHours() * 60 +
      new Date(item?.consult_end_datetime).getMinutes() -
      (new Date(item?.consult_datetime).getHours() * 60 + new Date(item?.consult_datetime).getMinutes());
    let durationHours = parseInt(duration / 60);
    let durationMinutes = duration % 60;
    let value = '';
    if (durationHours > 0) {
      value += durationHours + ' Hour ';
    }
    if (durationMinutes > 0) {
      value += durationMinutes + ' Mins';
    }
    return value;
  };
  const renderDateBoxConsultation = () => {
    return (
      <div className='zoho-calendar-content mt-3 w-50'>
        <div className='zoho-date text-start'>
          <strong>{dateFormat(selectedDate?.startStr || new Date())}</strong>
        </div>
        {!consultDateData.length && <div className='text-center mb-4'>Consultation Not Available</div>}
        {consultDateData.map((item, index) => {
          return (
            <CommonBorderBox className=' '>
              {/* <div style={{ cursor: 'pointer' }} className='d-flex justify-content-start'>
                <div
                  onClick={() =>
                    navigate(`/patient/${item.user.id}/appointment/${item.id}/edit`, {
                      state: {
                        check_in: 'check_in',
                      },
                    })
                  }
                  className='p-3 ConsultationTimeCard d-flex align-items-center text-center'
                >
                  {timeFormat(item.consult_datetime)}
                </div>
                <div className='p-3  w-100'>
                  <div className='d-flex justify-content-between align-items-start'>
                    <div
                      onClick={() =>
                        navigate(`/patient/${item.user.id}/appointment/${item.id}/edit`, {
                          state: {
                            check_in: 'check_in',
                          },
                        })
                      }
                      className=''
                    >
                      <strong className='capitalize'>
                        {item.title} {item?.category == 'follow_up' && (item?.category).replace('_', '-')}
                      </strong>
                      &nbsp;
                      <strong>{item?.category == 'follow_up' && dateFormat(item.consult_datetime)}</strong>
                    </div>
                    <div className='d-flex  justify-content-end'>
                      <span class='badge capitalize enable me-2'>
                        <label class='form-check-label'>{item?.consultation_status}</label>
                      </span>
                      <div>
                        <StatusCard
                          handleCreateConsultation={handleCreateConsultation}
                          tagPencil
                          selectedDate={selectedDate}
                          originalSelectDate={originalSelectDate}
                          consultationItem={item}
                          dashBoardStatus
                          setUpdateStatus={setEditStatus}
                          consultId={item.id}
                          patientIdConsult={item.user.id}
                          updateStatus={editStatus}
                          status={status}
                          getPatientData={getConsultationCalenderData}
                          badgeTitle={titlizeString(item.patient_attendance_status) || 'Select Status'}
                          order
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    onClick={() =>
                      navigate(`/patient/${item.user.id}/appointment/${item.id}/edit`, {
                        state: {
                          check_in: 'check_in',
                        },
                      })
                    }
                  >
                    {item?.user?.full_name}{' '}
                    {item.user?.gender
                      ? `(${item?.user?.gender.charAt(0).toUpperCase()}${item?.user?.age ? ',' : ''}${
                          item?.user?.age ? item?.user?.age : ''
                        }${item?.user?.age ? 'yrs' : ''})`
                      : null}{' '}
                    with {item.consultant?.full_name}
                  </div>
                </div>
              </div> */}
              <div className='consultationtabPart appointmentTilePart' key={index}>
                <div className='consultationTital mb-1'>
                  <Link to={`/patient/${item?.user.id}/dashboard`}>
                    {item?.user?.full_name} ({item?.user?.gender && item?.user?.gender[0].toUpperCase()}, {item?.user?.age})
                  </Link>
                  <p className='consultationDate'>Created By : {item?.admin?.full_name}</p>
                </div>
                <div className='d-flex w-100 justify-content-between align-items-center'>
                  <div className='consultationTital'>
                    {dateFormat(item.consult_datetime)} | {timeFormat(item.consult_datetime)}-{timeFormat(item.consult_end_datetime)}{' '}
                    <span>({renderDuration(item)})</span>
                  </div>

                  <div className='d-flex align-items-center'>
                    {item?.consultation_status != 'canceled' && item.patient_attendance_status !== 'show' && item?.service?.id != serviceID ? (
                      <CommonToolTip
                        placement={'top'}
                        item={
                          <div
                            onClick={() => {
                              setCreatedAppointment(item);
                              setCancelModal(true);
                            }}
                            className='d-flex me-2'
                          >
                            <span class='material-icons-outlined'>close</span>
                          </div>
                        }
                        showValue={'Cancel'}
                      />
                    ) : null}
                    <div>
                      {item?.consultation_status != 'canceled' && item.patient_attendance_status !== 'show' && item?.service?.id != serviceID && (
                        <CommonToolTip
                          placement={'top'}
                          item={
                            <img
                              onClick={() =>
                                navigate(`/patient/${item.user.id}/appointment/${item.id}/edit`, {
                                  state: {
                                    edit: 'edit',
                                  },
                                })
                              }
                              src={editIconBlack}
                              className='icon'
                              style={{ cursor: 'pointer', width: '20px' }}
                            />
                          }
                          showValue={'Edit'}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className='me-2'>
                  <div className='d-flex consultationbadge align-items-end'>
                    {item?.service?.title ? (
                      <span class='badge capitalize follow me-2'>
                        <label class='form-check-label'>{item?.service?.title}</label>
                      </span>
                    ) : null}
                    {item?.consult_type == 'virtual' ? (
                      <span class='badge capitalize follow me-2'>
                        <label class='form-check-label d-flex align-items-center'>
                          <img src={laptopLink} className='me-1' /> Online
                        </label>
                      </span>
                    ) : null}
                    <span class='badge capitalize enable me-2'>
                      <label class='form-check-label'>{titlizeString(item.consultation_status) || 'Scheduled'}</label>
                    </span>
                  </div>
                </div>
                <div className='d-flex mt-1 w-100 justify-content-between align-items-end'>
                  <div className='d-flex flex-column w-50'>
                    <div className='consultationDate'>{item?.consultant?.full_name}</div>
                    <div className='consultationDate align-items-center mt-1 d-flex'>
                      <div>{item?.location?.name} </div>
                      {item?.meeting_link && item?.consult_type == 'virtual' && item?.service?.id != serviceID ? (
                        <div className='appointmentTilelink text-nowrap ms-2'>{item?.meeting_link}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className='d-flex align-items-center'>
                    {item.patient_attendance_status !== 'no_show' && item?.consultation_status != 'canceled' && item?.service?.id != serviceID ? (
                      <div className='me-2'>
                        <div
                          onClick={() => {
                            setCreatedAppointment(item);
                            setRecordModal(true);
                          }}
                          className='d-flex justify-content-center align-items-center record-btn badge'
                        >
                          Record Payment
                        </div>
                      </div>
                    ) : null}
                    <div>
                      {item?.consultation_status != 'canceled' && item?.consult_type == 'virtual' && item?.meeting_link && item?.service?.id != serviceID ? (
                        <a target='_blank' href={item?.meeting_link} class='badge capitalize lightRedBadge closebtn pointer'>
                          <label class='form-check-label pointer'>Join Link</label>
                        </a>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </CommonBorderBox>
          );
        })}
        <div className='mt-2'>
          <Pagination totalPages={dateTotalPages} currentPage={dateCurrentPage} setCurrentPage={setDateCurrentPage} dashboard scroll />
        </div>
      </div>
    );
  };
  return (
    <>
      <div className='calendar-Part card'>
        <div className='zoho-calendar-box'>
          <div className='cardbody'>
            <div className={'calendarDiv zoho-patient-calendar'}>
              <FullCalendar
                height={'50vh'}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
                headerToolbar={{
                  left: 'title',
                  right: 'prev,next',
                }}
                initialView='dayGridMonth'
                editable={true}
                selectable={true}
                selectMirror={true}
                dayMaxEvents={true}
                select={handleDateClick}
                eventClick={handleEventClick}
                initialEvents={currentEvents}
                events={currentEvents}
              />
            </div>

            <AppointmentCancel
              appointmentDetail={createdAppointment}
              cancelModal={cancelModal}
              setCancelModal={setCancelModal}
              handleCancelAppointment={handleCancelAppointment}
            />

            <RecordPayment
              isNivanCare={isNivanCare}
              userData={userData}
              setIsLoading={setIsLoading}
              appointmentDetail={createdAppointment}
              handleRecordPayment={handleRecordPayment}
              open={recordModal}
              setOpen={setRecordModal}
              setErrors={setRecordErrors}
              errors={recordErrors}
              isClinicEnable={configData?.findIndex((item2) => item2.key == 'departments_clinics' && item2?.isActive) != -1}
            />
            <Modal backdrop='static' size='md' open={open} onClose={handleModalClose}>
              <Modal.Header />
              <ModalBody className='pt-2 '>
                <CommonAlert isShowAlert={alertError} alertType={alertType} setIsShowAlert={setAlertError} />

                <label className='inputlabel'>{<span className='requiredInput'> * </span>}Follow up consultation date</label>
                {/* <DatePicker
            disabledDate={(date) => {
              if (new Date(date) < new Date().setHours(0, 0, 0)) {
                return true;
              } else {
                return false;
              }
            }}
            format='dd-MM-yyyy HH:mm:ss'
            placeholder='Follow up consultation date'
            defaultValue={formVal.next_consultation_datetime}
            onChange={(text) => {
              formVal.next_consultation_datetime = text;
              setFormVal({ ...formVal });
            }}
            style={{ width: '100%' }}
          /> */}

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDateTimePicker
                    renderInput={(props) => <TextField {...props} />}
                    disablePast
                    // value={consultationItem?.consult_datetime ? dayjs(consultationItem.consult_datetime) : null}
                    minDate={consultationItem?.consult_datetime ? dayjs(consultationItem.consult_datetime) : null}
                    placeholder={'Create follow-up Consult Date and Time'}
                    onChange={(text) => {
                      formVal.next_consultation_datetime = text;
                      setFormVal({ ...formVal });
                    }}
                    format='DD/MM/YYYY hh:mm A'
                    className='MobileDatePickerinputCommon'
                    // label='Consult Date and time'
                    // sx={{
                    //   width: '100%',
                    //   '& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
                    //     padding: '9.5px 14px',
                    //     borderRadius: '10px',
                    //   },
                    //   '& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
                    //     padding: '8.5px 14px',
                    //   },
                    //   '& .MuiInputLabel-root.Mui-focused': { color: '#d9dee2' },
                    //   '& .MuiOutlinedInput-root': {
                    //     '&:hover > fieldset': { borderColor: '#d9dee2' },
                    //     height: '42px',
                    //     borderRadius: '10px',
                    //   },
                    // }}
                  />
                </LocalizationProvider>
                {errors && modal && <div className='inputMsg ms-2'>{errors}</div>}
                <CoreButton
                  onClick={() => {
                    createFollowUp();
                    setModal(true);
                  }}
                  title='Save'
                />
              </ModalBody>
            </Modal>
          </div>
          {!selectedDate ? renderBoxConsultaion() : renderDateBoxConsultation()}
          <Loader show={isLoading} />
        </div>
      </div>
    </>
  );
};
