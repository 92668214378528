import React, { useRef } from 'react';
import { blackUpload, pdfIcon } from 'res/images';
import { CoreButton, CoreInputBox } from 'whealth-core-web/components';

export default function UploadReportForm(props) {
  const { reportId, onClick, valueObj, setValueObj, error } = props;

  const inputRef = useRef();
  let pdfName = valueObj?.report?.name?.slice(0, -4);

  if (pdfName?.length > 15 && pdfName) {
    pdfName = pdfName?.slice(0, 15) + '... .pdf';
  } else if (pdfName) {
    pdfName = pdfName?.slice(0, 15) + '.pdf';
  }
  return (
    <div className='cardDataBorder reportCardBorder'>
      <div className='measuringForm'>
        <div className='d-flex w-100 flexmeasuringForm'>
          <div className='w-100'>
            <CoreInputBox
              showMSG={error?.title}
              placeholder='Title'
              value={valueObj?.title}
              label='Title'
              setValue={(data) => {
                setValueObj({ ...valueObj, title: data });
              }}
              msgStyle={{ color: 'red' }}
              retuired
            />
          </div>
          <div>
            <input
              showMSG={error?.report}
              ref={inputRef}
              onChange={(data) => {
                setValueObj({ ...valueObj, report: data.target.files[0] });
              }}
              style={{ display: 'none' }}
              type='file'
              accept='application/pdf, application/vnd.ms-excel'
            />
          </div>

          <div className='w-100'>
            <div className='d-flex'>
              <div className=''>
                <label className='w-100 inputlabel'>Report</label>
                <button
                  onClick={() => {
                    inputRef.current.click();
                  }}
                  type='button'
                  className=' UploadBtnReport'
                >
                  <img src={blackUpload} className='icon  mt-1' />
                  Upload Document Report
                </button>
              </div>
              {valueObj?.report && (
                <div className='uploadReportData ms-3 mt-4'>
                  <img className='showImageStyle' src={pdfIcon} />
                  {pdfName}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='d-flex w-100 mt-3'>
          <div className='w-100'>
            <CoreInputBox
              showMSG={error?.description}
              placeholder='Description'
              value={valueObj?.description}
              label='Description'
              setValue={(data) => {
                setValueObj({ ...valueObj, description: data });
              }}
            />
          </div>
        </div>
        <CoreButton onClick={onClick} title={reportId ? 'Update Report' : 'Upload Report'} />
      </div>
    </div>
  );
}
