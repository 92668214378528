import React, { useEffect, useRef, useState } from 'react';
import CoreInputBox from '../CoreInputBox';
import { Dropdown, IconButton } from 'rsuite';
import CoreTextArea from '../CoreTextArea';
import { blackUpload, pdfIcon } from 'res/images';
import useApiManager from 'networking/ApiManager';
import { scrollToBottom, titlizeString } from '../Helper';
import { uploadDoc } from 'whealth-core-web/assets/images';
import { ErrorResponse } from '@remix-run/router';

function UploadDocument(props) {
  const {
    stepServiceDetails,
    setReportUpdateItemId,
    formCount,
    isReportData,
    setIsReportData,
    isAddPayment,
    error,
    errors,
    editTableData,
    editIndex,
    step,
    setStepServiceDetails,
    onClick,
    reportData,
    setEditTableData,
    getPatientReport,
    patientId,
    setAlertType,
    setIsLoading,
    reportUpdateItemId,
    setIsShowAlert,
    updateData,
    paymentServiceDetails,
    setPaymentServiceDetails,
    setFormCount,
    editData,
    setEditData,
    step3Data,
    dynamicServiceData,
    setStep3Data,
    step4Data,
    setStep4Data,
  } = props;
  if (!paymentServiceDetails.insurance_status) {
    setPaymentServiceDetails({
      ...paymentServiceDetails,
      insurance_status: 'pending_approval',
    });
  }
  const inputRefs = useRef(Array.from({ length: formCount }, () => null));
  const ApiClient = useApiManager();
  let pdfName = stepServiceDetails?.report?.name?.slice(0, -4);

  const status = [
    { title: 'Pending Approval', id: 'pending_approval' },
    { title: 'Approved', id: 'approved' },
    { title: 'Rejected', id: 'rejected' },
  ];

  if (pdfName?.length > 15 && pdfName) {
    pdfName = pdfName?.slice(0, 15) + '... .pdf';
  } else if (pdfName) {
    pdfName = pdfName?.slice(0, 15) + '.pdf';
  }

  const deleteReports = (reportId, index) => {
    setIsLoading(true);
    ApiClient.deleteReports(patientId, reportId)
      .then((res) => {
        setIsLoading(false);
        getPatientReport();
        const updatedStepServiceDetails = [...stepServiceDetails];
        updatedStepServiceDetails.splice(index, 1);
        setStepServiceDetails(updatedStepServiceDetails);
        setReportUpdateItemId('');
        setIsShowAlert(res.data.message);
        setAlertType('alert-success');
      })
      .catch((err) => {
        if (err.response.status == 500) {
          setIsShowAlert('Internal server error');
        } else {
          if (!Array.isArray(Object.values(err.response.data)[0])) {
            setIsShowAlert(Object.values(err.response.data)[0]);
          }
        }
        setIsLoading(false);
        setAlertType('alert-danger');
      });
  };

  const renderReportDetails = (item, index) => {
    return (
      <>
        <div className='upload-doc d-flex align-items-center gap-10 mb-4'>
          <div className='doc-file d-flex align-items-center w-25'>
            <span class='material-icons-outlined'>attach_file</span>
            <div>{item.title}</div>
          </div>
          <div className='doc-Detail d-flex align-items-center justify-content-between w-100'>
            <div className='me-4'>{item.description}</div>
            <div className='d-flex align-items-center gap-10 action-icon-part'>
              <span
                onClick={() => {
                  updateData(item);
                  scrollToBottom();
                  setEditData(item.id);
                }}
                class='material-icons-outlined d-flex align-items-center justify-content-center'
              >
                edit
              </span>
              <span
                onClick={() => {
                  deleteReports(item?.id, index);
                }}
                class='material-icons-outlined  d-flex align-items-center justify-content-center'
              >
                delete
              </span>
            </div>
          </div>
        </div>
      </>
    );
  };
  const renderInsuranceForm = () => {
    return (
      <>
        <div className='insurance-form d-flex justify-content-start mb-5'>
          <div className='w-25  me-4 insurance-status-change align-items-center justify-content-between'>
            <label>Status</label>
            <div className='insurance-status'>
              <Dropdown
                className={`capitalize pointer w-100 ${paymentServiceDetails.insurance_status}`}
                title={
                  <div className='d-flex justify-content-between w-100'>
                    <div className='capitalize'>
                      {titlizeString(paymentServiceDetails.insurance_status) || 'Pending Approval'}
                    </div>
                    <div className='action-icon-part'>
                      <span class='material-icons-outlined d-flex align-items-center justify-content-center'>edit</span>
                    </div>
                  </div>
                }
              >
                {status?.map((statusItem) => {
                  return (
                    <Dropdown.Item
                      onClick={() => {
                        setPaymentServiceDetails({
                          ...paymentServiceDetails,
                          insurance_status: statusItem?.id,
                        });
                      }}
                    >
                      {statusItem.title}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown>
            </div>
          </div>
          {/* {step == '1' &&
            (paymentServiceDetails.insurance_status == 'rejected' || paymentServiceDetails?.insured_amount) && (
              <div className='w-25  me-4 '>
                <CoreInputBox
                  onlyNumAndDecimals
                  label={'Final Bill'}
                  value={paymentServiceDetails?.final_bill_amount}
                  setValue={(amount) => {
                    setPaymentServiceDetails({ ...paymentServiceDetails, final_bill_amount: amount });
                  }}
                />
              </div>
            )} */}
          {paymentServiceDetails.insurance_status == 'approved' && (
            <div className='w-25  me-4'>
              <CoreInputBox
                retuired
                showMSG={errors.insured_amount}
                onlyNumAndDecimals
                label={'Approved Amount'}
                value={paymentServiceDetails?.insured_amount}
                setValue={(amount) => {
                  setPaymentServiceDetails({ ...paymentServiceDetails, insured_amount: amount });
                }}
              />
            </div>
          )}
        </div>
        {paymentServiceDetails.insurance_status == 'rejected' && step == 1 && (
          <div className='mb-4'>
            <CoreTextArea
              label={'Reason for Rejection'}
              rows={'3'}
              value={paymentServiceDetails?.reason || ''}
              setValue={(description) => {
                setPaymentServiceDetails({
                  ...paymentServiceDetails,
                  reason: description,
                });
              }}
            />
          </div>
        )}
      </>
    );
  };

  const renderInsuranceFormStep3 = () => {
    const lastIndexTable = dynamicServiceData?.payment_details
      ? dynamicServiceData?.payment_details[
          dynamicServiceData?.payment_details?.length - dynamicServiceData?.payment_details?.length
        ]
      : [];
    return (
      <>
        <div className='insurance-form d-flex justify-content-start mb-5'>
          <div className='w-25  me-4 insurance-status-change align-items-center justify-content-between'>
            <label>Status</label>
            <div className='insurance-status'>
              <Dropdown
                className={`capitalize pointer w-100 ${step3Data?.insurance_status}`}
                title={
                  <div className='d-flex justify-content-between w-100'>
                    <div className='capitalize'>{titlizeString(step3Data?.insurance_status) || 'Pending Approval'}</div>
                    <div className='action-icon-part'>
                      <span class='material-icons-outlined d-flex align-items-center justify-content-center'>edit</span>
                    </div>
                  </div>
                }
              >
                {status?.map((statusItem) => {
                  return (
                    <Dropdown.Item
                      onClick={() => {
                        setStep3Data({
                          ...step3Data,
                          insurance_status: statusItem?.id,
                        });
                      }}
                    >
                      {statusItem.title}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown>
            </div>
          </div>
          {step == '2' && (step3Data.insurance_status == 'approved' || step3Data.insurance_status == 'rejected') ? (
            <div className='w-25  me-4 '>
              <CoreInputBox
                retuired
                showMSG={errors?.final_bill_amount}
                // disabled={
                //   lastIndexTable?.payment_type !== paymentServiceDetails?.payment_type &&
                //   dynamicServiceData.final_bill_amount > 0
                // }
                disabled={editIndex > 0 || (!editTableData?.id && dynamicServiceData?.final_bill_amount > 0)}
                onlyNumAndDecimals
                label={'Final Bill'}
                value={step3Data?.final_bill_amount}
                setValue={(amount) => {
                  setStep3Data({ ...step3Data, final_bill_amount: amount });
                }}
              />
            </div>
          ) : null}

          {step3Data.insurance_status == 'approved' ? (
            <div className='w-25  me-4'>
              <CoreInputBox
                retuired
                showMSG={errors?.insured_amount}
                onlyNumAndDecimals
                label={'Approved Amount'}
                value={step3Data?.insured_amount || null}
                setValue={(amount) => {
                  setStep3Data({ ...step3Data, insured_amount: amount });
                }}
              />
            </div>
          ) : null}
        </div>
        {step3Data.insurance_status == 'rejected' ? (
          <div className='mb-4'>
            <CoreTextArea
              label={'Reason for Rejection'}
              rows={'3'}
              value={step3Data?.reason || ''}
              setValue={(description) => {
                setStep3Data({
                  ...step3Data,
                  reason: description,
                });
              }}
            />
          </div>
        ) : null}
      </>
    );
  };

  const handleFileChange = (index, event) => {
    setIsReportData(true);
    const newStepServiceDetailsList = [...stepServiceDetails];
    newStepServiceDetailsList[index] = { ...newStepServiceDetailsList[index], report: event.target.files[0] };
    setStepServiceDetails(newStepServiceDetailsList);
  };

  const handleInputChange = (index, field, value) => {
    const newStepServiceDetailsList = [...stepServiceDetails];
    newStepServiceDetailsList[index] = { ...newStepServiceDetailsList[index], [field]: value };
    setStepServiceDetails(newStepServiceDetailsList);
  };

  const handleDelete = (indexToDelete) => {
    setStepServiceDetails((prevDetails) => {
      const updatedDetails = [...prevDetails];
      updatedDetails.splice(indexToDelete, 1);
      return updatedDetails;
    });
    setFormCount((prevCount) => prevCount - 1); // Decrement the form count
  };

  const renderReport = (index) => {
    const url = stepServiceDetails[index]?.report;
    let fileName = '';
    if (typeof url === 'string' && url.includes('/')) {
      const urlParts = url.split('/');
      const lastPart = urlParts[urlParts.length - 1];
      fileName = lastPart.substring(0, lastPart.indexOf('?') !== -1 ? lastPart.indexOf('?') : undefined);
    }
    // Extracting the file name
    // const fileName = url?.substring(url?.lastIndexOf('/') + 1, url?.indexOf('?'));

    return (
      <div className='w-50 mt-4 ms-4 InsuranceFileUpload'>
        {/* <IconButton icon={''} label={''}>
              Add
            </IconButton> */}
        <div>
          <input
            // showMSG={error?.report}

            ref={(el) => (inputRefs.current[index] = el)}
            onChange={(event) => handleFileChange(index, event)}
            style={{ display: 'none' }}
            type='file'
            accept='application/pdf, application/vnd.ms-excel'
          />
        </div>
        <div className='w-50 mt-4 d-flex align-items-start'>
          <button
            onClick={() => {
              inputRefs.current[index].click();
            }}
            type='button'
            className=' UploadBtnReport w-100'
          >
            <img src={uploadDoc} className='icon  mt-1' />
            Upload Document
          </button>
        </div>
        {stepServiceDetails[index]?.report && (
          <div className='uploadReportData ms-3 mt-4'>
            <img className='showImageStyle' src={pdfIcon} />
            {stepServiceDetails[index]?.report?.name || fileName}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className='uploadDocumentPart'>
      {step == '1' && renderInsuranceForm()}
      {step == '2' && renderInsuranceFormStep3()}

      {reportData.map((item, index) => {
        return renderReportDetails(item, index);
      })}

      <div className='title mb-2'>Upload Supporting Document</div>
      {[...Array(formCount)].map((_, index) => (
        <div className='uploadDocument mb-4' key={index}>
          {formCount !== 1 && !editData && (
            <div
              onClick={() => handleDelete(index)}
              className='d-flex align-items-center action-icon-part justify-content-end'
            >
              <span class='material-icons-outlined d-flex align-items-center justify-content-center'>delete</span>
            </div>
          )}
          <div className='w-100 d-flex'>
            <div className='w-25  mt-4'>
              <CoreInputBox
                showMSG={error && error[index]?.title}
                label={'Title'}
                retuired
                value={stepServiceDetails[index]?.title || ''}
                setValue={(title) => handleInputChange(index, 'title', title)}
              />
            </div>
            {renderReport(index)}
          </div>
          <div className=' mt-4'>
            <CoreTextArea
              label={'Description'}
              rows={'3'}
              value={stepServiceDetails[index]?.description || ''}
              setValue={(description) => handleInputChange(index, 'description', description)}
            />
          </div>

          <div className='mx-5 mt-5'>
            <div
              onClick={() => {
                onClick(index);
              }}
              class='form-control  coreBtn text-white btn btn-secondary btn-lg w-100'
            >
              {reportUpdateItemId ? 'Update Documents' : 'Upload Documents'}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default UploadDocument;
