import { AdminPanelHeader } from 'components';
import React, { useEffect, useState } from 'react';
import { profileImage } from '../Helper';
import { useParams } from 'react-router-dom';
import AddService from 'whealth-core-web/AddService';
import useApiManager from 'networking/ApiManager';
import './PatientService.scss';
import CommonServiceProfileBadge from './CommonServiceProfileBadge';
import PaymentDetailsPage from './PaymentDetailsPage';
import InsuranceTab from './InsuranceTab';
import CommonAlert from '../CommonAlert';
import Loader from '../Loader';

function PatientServiceTab(props) {
  const { isNivanCare } = props;
  const ApiClient = useApiManager();
  const { id, pid } = useParams();
  const steps = ['Service Details', 'Payment Details'];
  const [currentStep, setCurrentStep] = useState(0);
  const [alertType, setAlertType] = useState('alert-success');
  const [isLoading, setIsLoading] = useState(false);
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [finalSubmitId, setFinalSubmitId] = useState('');
  const [errors, setErrors] = useState([]);
  const [dynamicServiceData, setDynamicServiceData] = useState({});
  const [activeTab, setActiveTab] = useState(steps[currentStep]);
  const [isEditEnsurance, setIsEditInsurance] = useState(false);
  const [patientDetails, setPatientDetails] = useState({});
  const [headerServiceDetail, setheaderServiceDetail] = useState('');
  const [headerLocationDetail, setheaderLocationDetail] = useState('');
  const [headerDoctorDetail, setheaderDoctorDetail] = useState('');
  const [headerEstimateCostDetail, setheaderEstimateCostDetail] = useState('');
  const [hasSaveButton, setHasSaveButton] = useState(false);
  const [isAddPayment, setIsAddPayment] = useState(false);
  const [editTableData, setEditTableData] = useState('');
  const [paymentServiceDetails, setPaymentServiceDetails] = useState([]);
  const [paymentUpdateServiceDetails, setPaymentUpdateServiceDetails] = useState({});
  const [isPaymentType, setIsPaymentType] = useState('');
  const [formCount, setFormCount] = useState(1);
  const [stepServiceDetails, setStepServiceDetails] = useState(Array.from({ length: formCount }, () => ({})));
  const [serviceDataForPayment, setServiceDataForPayment] = useState('');
  const [editIndex, setEditIndex] = useState('');

  const [step3Data, setStep3Data] = useState({});
  const [step4Data, setStep4Data] = useState({});
  useEffect(() => {
    getPatientData();
  }, []);
  const getPatientData = () => {
    setIsLoading(true);
    ApiClient.getPatientDetails(id)
      .then((res) => {
        setIsLoading(false);
        setPatientDetails(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const ServiceTab = () => {
    return (
      <div>
        <div className='tabDetails'>
          <ul className='nav nav-tabs'>
            {steps.map((tab, index) => {
              return (
                <li className='nav-item' key={tab}>
                  <button
                    className={`nav-link ${currentStep === index ? 'active' : ''}`}
                    onClick={() => {
                      if (pid || hasSaveButton) {
                        setCurrentStep(index);
                      }
                    }}
                  >
                    {tab}
                  </button>
                </li>
              );
            })}
          </ul>
          {isShowAlert && (
            <div className='mt-5'>
              <CommonAlert isShowAlert={isShowAlert} alertType={alertType} setIsShowAlert={setIsShowAlert} />
            </div>
          )}
          {steps[currentStep] == 'Service Details' && (
            <div className='tab-contentPart'>
              <CommonServiceProfileBadge patientDetails={patientDetails} />
              <div className='tabContent p-3'>
                <AddService
                  serviceDataForPayment={serviceDataForPayment}
                  steps={steps}
                  currentStep={currentStep}
                  hasSaveButton={hasSaveButton}
                  setServiceDataForPayment={setServiceDataForPayment}
                  dynamicServiceData={dynamicServiceData}
                  setDynamicServiceData={setDynamicServiceData}
                  setHasSaveButton={setHasSaveButton}
                  setCurrentStep={setCurrentStep}
                  setheaderServiceDetail={setheaderServiceDetail}
                  setheaderLocationDetail={setheaderLocationDetail}
                  setheaderDoctorDetail={setheaderDoctorDetail}
                  setheaderEstimateCostDetail={setheaderEstimateCostDetail}
                />
              </div>
            </div>
          )}

          {steps[currentStep] == 'Payment Details' && (
            <div className='tab-contentPart'>
              <CommonServiceProfileBadge
                pid={pid}
                serviceDataForPayment={serviceDataForPayment}
                patientDetails={patientDetails}
                dynamicServiceData={dynamicServiceData}
                isPaymentDetail
                headerServiceDetail={headerServiceDetail}
                headerLocationDetail={headerLocationDetail}
                headerDoctorDetail={headerDoctorDetail}
                headerEstimateCostDetail={headerEstimateCostDetail}
              />
              <div className='tabContent p-3'>
                <PaymentDetailsPage
                  finalSubmitId={finalSubmitId}
                  isEditEnsurance={isEditEnsurance}
                  setIsEditInsurance={setIsEditInsurance}
                  editIndex={editIndex}
                  setEditIndex={setEditIndex}
                  currentStep={currentStep}
                  step3Data={step3Data}
                  setStep3Data={setStep3Data}
                  step4Data={step4Data}
                  setStep4Data={setStep4Data}
                  isAddPayment={isAddPayment}
                  setIsAddPayment={setIsAddPayment}
                  setDynamicServiceData={setDynamicServiceData}
                  editData={editTableData}
                  setEditData={setEditTableData}
                  patientDetails={patientDetails}
                  setAlertType={setAlertType}
                  setIsLoading={setIsLoading}
                  setIsShowAlert={setIsShowAlert}
                  isShowAlert={isShowAlert}
                  dynamicServiceData={dynamicServiceData}
                  serviceDataForPayment={serviceDataForPayment}
                  isNivanCare={isNivanCare}
                  isPaymentType={isPaymentType}
                  setIsPaymentType={setIsPaymentType}
                  pid={pid}
                  paymentUpdateServiceDetails={paymentUpdateServiceDetails}
                  setPaymentUpdateServiceDetails={setPaymentUpdateServiceDetails}
                  patientId={id}
                  paymentServiceDetails={paymentServiceDetails}
                  setPaymentServiceDetails={setPaymentServiceDetails}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  let userDetails = localStorage.getItem('userDetails');
  userDetails = JSON.parse(userDetails);
  const showTree = [
    { iconClass: 'person_outline', title: 'Patient', link: '/patient' },
    {
      icon: profileImage(userDetails.gender, userDetails.image),
      title: userDetails.name,
      link: `/patient/${id}/dashboard`,
    },
    { title: (pid ? 'Update' : 'New') + ' ' + 'Service', link: '' },
  ];
  const showPatient = () => (
    <>
      <div className='articleHeader stickyHeader'>
        <div className='headerFiles'>
          <AdminPanelHeader iconTitle='person_outline' showTree={showTree} title='Patient' />
        </div>

        {/* <div className='addnewform-part'>{pid ? 'Update' : 'Add New'} Diet Plan</div> */}
      </div>
      <div className='px-4 wrapperContent'>
        {ServiceTab()}
        {isPaymentType == 'insurance' &&
          steps[currentStep] !== 'Service Details' &&
          paymentServiceDetails.payment_type && (
            <div className='insurancePart'>
              <InsuranceTab
                isAddPayment={isAddPayment}
                finalSubmitId={finalSubmitId}
                setFinalSubmitId={setFinalSubmitId}
                isEditEnsurance={isEditEnsurance}
                setIsEditInsurance={setIsEditInsurance}
                errors={errors}
                setErrors={setErrors}
                editIndex={editIndex}
                setEditIndex={setEditIndex}
                alertType={alertType}
                currentStep={currentStep}
                step3Data={step3Data}
                setStep3Data={setStep3Data}
                step4Data={step4Data}
                setStep4Data={setStep4Data}
                setIsAddPayment={setIsAddPayment}
                editTableData={editTableData}
                setEditTableData={setEditTableData}
                dynamicServiceData={dynamicServiceData}
                setDynamicServiceData={setDynamicServiceData}
                serviceDataForPayment={serviceDataForPayment}
                paymentServiceDetails={paymentServiceDetails}
                setPaymentServiceDetails={setPaymentServiceDetails}
                formCount={formCount}
                setFormCount={setFormCount}
                setAlertType={setAlertType}
                setIsLoading={setIsLoading}
                setIsShowAlert={setIsShowAlert}
                isShowAlert={isShowAlert}
                patientId={id}
                pid={pid}
                stepServiceDetails={stepServiceDetails}
                setStepServiceDetails={setStepServiceDetails}
              />
            </div>
          )}
      </div>
    </>
  );

  return (
    <div className='wrapper'>
      {showPatient()}
      <Loader show={isLoading} />
    </div>
  );
}

export default PatientServiceTab;
