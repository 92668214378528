import useApiManager from 'networking/ApiManager';
import React, { useEffect, useState } from 'react';
import { editTheme, trashRed } from 'res/images';
import { Modal } from 'rsuite';
import AppoinmentServiceForm from 'whealth-core-web/AppoinmentServiceForm';
import { CommonBorderBox, EmptyView, Pagination } from 'whealth-core-web/components';
import CommonToolTip from 'whealth-core-web/components/CommonToolTip';
import { dateFormat, teamRoles, timeFormat } from 'whealth-core-web/components/Helper';

function AppointmentServiceSettingView(props) {
  const { setIsLoading, isLoading, setIsShowAlert, setAlertType, isBabyMd } = props;
  const [serviceData, setServiceData] = useState({});
  const [updateServiceData, setupdateServiceData] = useState({});
  const [error, seterror] = useState('');
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [listData, setListData] = useState([]);
  const ApiClient = useApiManager();

  useEffect(() => {
    getServiceList();
  }, [currentPage]);

  const getServiceList = () => {
    setIsLoading(true);
    const pageQry = { page: currentPage };
    ApiClient.getAppointmentService(currentPage)
      .then((res) => {
        setIsLoading(false);
        setTotalPages(res.headers['total-pages']);
        setListData(res.data);
        setServiceData({});
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const createService = () => {
    seterror([]);
    setIsLoading(true);
    const body = {
      title: serviceData.title,
      description: serviceData.description,
      enable: serviceData.enable,
      service_fee: serviceData.service_fee,
      service_schema: serviceData.service_schema || '[]',
      required_fields: isBabyMd && 'service_fee',
    };
    ApiClient.createAppointmentService(body)
      .then((res) => {
        setIsLoading(false);
        setCurrentPage(1);
        getServiceList();
        setAlertType('alert-success');
        setIsShowAlert('Service Created Successfully.');
      })
      .catch((err) => {
        setIsLoading(false);
        setAlertType('alert-danger');
        if (err.response.data.detail) {
          setIsShowAlert(err.response.data.detail);
        } else {
          seterror(err.response.data.errors);
        }
        console.log(err);
      });
  };

  const updateService = () => {
    let serviceSchema = '[]';
    if (updateServiceData?.service_schema) {
      serviceSchema = updateServiceData.service_schema;
    }
    const body = {
      title: updateServiceData.title,
      description: updateServiceData.description,
      enable: updateServiceData.enable,
      service_fee: updateServiceData.service_fee,
      service_schema: serviceSchema,
      required_fields: isBabyMd && 'service_fee',
    };
    ApiClient.updatAppointmentService(updateServiceData?.id, body)
      .then((res) => {
        setAlertType('alert-success');
        setIsShowAlert('Service Updated Successfully.');
        getServiceList();
        handleModalClose();
      })
      .catch((err) => {
        setAlertType('alert-danger');
        if (err.response?.data?.detail) {
          setIsShowAlert(err.response.data.detail);
          setOpen(false);
        } else if (err?.response?.data?.errors) {
          seterror(err.response.data.errors);
        } else {
          seterror(err.response.data);
        }
      });
  };

  const deleteData = (id) => {
    seterror([]);
    const windowConfirm = window.confirm('Are you sure you want to delete ?');
    if (windowConfirm) {
      ApiClient.deleteAppointmentService(id)
        .then((res) => {
          setAlertType('alert-success');
          setIsShowAlert(res.data.message);
          getServiceList();
        })
        .catch((err) => {
          console.warn('s', err);
          setAlertType('alert-danger');
          setIsShowAlert(err.response.data.detail || err.response.data.message);
          console.log(err);
        });
    }
  };

  const handleModalClose = () => {
    setOpen(false);
    setServiceData({});
    setupdateServiceData({});
    seterror('');
  };

  const updateModal = () => {
    return (
      <div>
        <Modal backdrop='static' size='md' open={open} onClose={handleModalClose}>
          <Modal.Header />
          {renderDepartmentForm(updateServiceData, setupdateServiceData, true)}
        </Modal>
      </div>
    );
  };

  const renderDepartmentForm = (data, setData, isUpdate) => {
    return (
      <div className='commonBorderBox settingFormOutline mb-4'>
        <AppoinmentServiceForm
          error={error}
          isBabyMd={isBabyMd}
          valueObj={data}
          setValueObj={setData}
          isUpdate={isUpdate}
          onClick={isUpdate ? updateService : createService}
          // statuses={departmentStatuses}
          // careManagersList={careManagersList}
        />
      </div>
    );
  };

  const updateDataModalOpen = (data) => {
    setOpen(true);
    setupdateServiceData({ ...data, service_schema: JSON.stringify(data.service_schema) });
    seterror('');
  };

  const listDataMap = () => {
    return listData.map((item, index) => {
      return (
        <tr key={index} className='my-3 font-12px'>
          <table className='table'>
            <tr>
              <td colSpan={2}>
                <div className='d-flex mb-1 align-items-center justify-content-between'>
                  <div className='align-items-flex-start'>
                    <div className='medicinename'> {item.title}</div>
                    <div className='badge lightGrayBadge mt-1'>{item.description}</div>
                  </div>
                  <div
                    className='d-flex text-muted justify-content-between align-items-center medicineDataList'
                    style={{ gap: '10px' }}
                  >
                    <div>
                      Created by · {item.admin ? item.admin.full_name : item?.created_by?.full_name} (
                      {teamRoles(item.admin ? item.admin.role : item?.created_by?.role)})
                      <div>
                        Created <span className='lowercase'> at </span>· {dateFormat(item?.created_at)}
                        &nbsp;
                        {timeFormat(item.created_at)}
                      </div>
                      {item?.service_fee && <div>Service Amount : INR {item?.service_fee}</div>}
                    </div>
                    <div className='action-images d-flex'>
                      <CommonToolTip
                        placement={'top'}
                        item={
                          <img
                            onClick={() => {
                              updateDataModalOpen(item);
                            }}
                            src={editTheme}
                          />
                        }
                        showValue={'Edit'}
                      />

                      <CommonToolTip
                        placement={'top'}
                        item={
                          <img
                            onClick={() => {
                              deleteData(item.id);
                            }}
                            src={trashRed}
                          />
                        }
                        showValue={'Delete'}
                      />
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </table>
        </tr>
      );
    });
  };

  const patination = () => {
    return (
      <Pagination
        seterror={seterror}
        totalPages={totalPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    );
  };
  const showDepartmentsList = () => {
    return <>{listDataMap()}</>;
  };
  const renderEmptyView = () => {
    return <EmptyView title='No data found' />;
  };

  return (
    <div className='medicine-form'>
      <CommonBorderBox>{renderDepartmentForm(serviceData, setServiceData)}</CommonBorderBox>
      <div className='table-responsive'>
        <table className='w-100'>{listData?.length > 0 ? showDepartmentsList() : renderEmptyView()}</table>
      </div>

      <div className='d-flex justify-content-end mt-3'>{patination()}</div>
      {updateModal()}
    </div>
  );
}

export default AppointmentServiceSettingView;
