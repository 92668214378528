import React, { useEffect, useState } from 'react';
import { closeIcon } from 'res/images';
import {
  DateRange,
  CategoryFilter,
  TagsFilter,
  AuthorFilter,
  LanguageView,
  GenderFilterView,
  BMIFilter,
} from './index';
import './filterModal.scss';
import { clickOnElmOnBodyClick } from './Helper';
import labels from '../../inputFields.json';
import BillingDoctorFilter from './BillingDoctorFilter';

function FilterModal(props) {
  const {
    getData,
    data,
    filter,
    clearFiltersData,
    setClearFilterData,
    hideBmiFilter,
    BillingView,
    selectDoctorListBilling,
  } = props;
  const [filterData, setFilterData] = useState({});

  useEffect(() => {
    setFilterData(data);
  }, [data]);

  const getFilterData = (data) => {
    setFilterData({ ...filterData, ...data });
  };

  const handleClearDate = () => {
    let obj = { ...filterData };
    if (obj['date']) {
      delete obj['date'];
    }

    setFilterData((prewState) => {
      return {};
    });
  };
  const filterHeader = () => {
    return (
      <div className='filterHeader'>
        <div>Apply Filter</div>
        <div className='' data-bs-dismiss='offcanvas' aria-label='Close'>
          <img src={closeIcon} />
        </div>
      </div>
    );
  };

  const genderFilter = () => {
    return (
      <div className='ml-8'>
        <GenderFilterView filterData={data} setFilterData={getFilterData} />
      </div>
    );
  };

  const renderBMIFilter = () => {
    return (
      <div className='ml-8'>
        <BMIFilter filterData={data} setFilterData={getFilterData} />
      </div>
    );
  };

  const showDateRange = () => {
    return (
      <div>
        <DateRange
          disableDate={(date) => {
            if (date >= new Date()) {
              return true;
            } else {
              return false;
            }
          }}
          clearFiltersData={clearFiltersData}
          filterData={data}
          setFilterData={getFilterData}
          handleClearDate={handleClearDate}
        />
      </div>
    );
  };

  const patientDoctorFilter = () => {
    return (
      <div className='authorFilter capitalize'>
        <BillingDoctorFilter
          title='Doctor'
          placeholder='Select Doctor'
          filterData={data}
          api='patient'
          setFilterData={getFilterData}
          clearFiltersData={clearFiltersData}
        />
      </div>
    );
  };

  const categoryFilter = () => {
    return (
      <div className='categoryFileter'>
        <CategoryFilter clearData={clearFiltersData} filterData={data} setFilterData={getFilterData} />
      </div>
    );
  };

  const showAutherFilter = () => {
    return (
      <div className='authorFilter'>
        <AuthorFilter
          title='Select Author'
          placeholder='Search Author'
          api='article'
          filterData={data}
          setFilterData={getFilterData}
        />
      </div>
    );
  };

  const teamPatientsFilter = () => {
    return (
      <div className='authorFilter'>
        <AuthorFilter
          title='Assigned Patients'
          placeholder='Search Assigned Patients'
          api='team'
          filterData={data}
          setFilterData={getFilterData}
        />
      </div>
    );
  };

  const patientAdminFilter = () => {
    return (
      <div className='authorFilter'>
        <AuthorFilter
          title={labels?.patientFilterSearch?.label || 'Doctor, Health Coach'}
          placeholder={labels?.patientFilterSearch?.placeholder || 'Search Doctor, Health Coach'}
          filterData={data}
          api='patient'
          setFilterData={getFilterData}
        />
      </div>
    );
  };

  const languagesFilter = () => {
    return (
      <div className='ml-8'>
        <LanguageView clearFiltersData={clearFiltersData} filterData={data} setFilterData={getFilterData} />
      </div>
    );
  };

  const tagsFilter = () => (
    <div className='categoryFileter'>
      <TagsFilter filterData={data} setFilterData={getFilterData} />
    </div>
  );

  const filterButtons = () => {
    return (
      <div className='footerButtons'>
        <div
          className='button clearBtn'
          onClick={() => {
            setFilterData('');
            getData({});
            setClearFilterData(!clearFiltersData);
            clickOnElmOnBodyClick('datePickerContainer', 'rs-picker-toggle-clean', 'class');
          }}
        >
          Clear All
        </div>
        <div
          onClick={() => {
            getData(filterData);
          }}
          className='button applyBtn'
          data-bs-dismiss='offcanvas'
          aria-label='Close'
        >
          Apply Filter
        </div>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div className='renderFooter'>
        <div className='hrLine' />
        <div>{filterButtons()}</div>
      </div>
    );
  };

  const renderFilters = () => {
    if (filter == 'team') {
      return (
        <>
          {showDateRange()}
          <div className='my-3' />
          {languagesFilter()}
          {teamPatientsFilter()}
        </>
      );
    } else if (filter == 'patient') {
      return (
        <>
          {showDateRange()}
          {!hideBmiFilter && renderBMIFilter()}
          <div className='my-3' />
          {genderFilter()}
          {patientAdminFilter()}
        </>
      );
    } else if (filter == 'billing') {
      return (
        <>
          {showDateRange()}
          <div className='my-3' />
          {patientDoctorFilter()}
        </>
      );
    } else {
      return (
        <>
          {showDateRange()}
          {categoryFilter()}
          {showAutherFilter()}
          {tagsFilter()}
        </>
      );
    }
  };

  const renderModal = () => {
    return (
      <div className='filterModal'>
        <div
          className='offcanvas offcanvas-end'
          tabIndex='-1'
          id='offcanvasRight'
          aria-labelledby='offcanvasRightLabel'
        >
          <div className='whiteBackground'>
            {filterHeader()}
            <div className='hrLine' />
            <div className='filterHeaderData'>{renderFilters()}</div>
            {renderFooter()}
          </div>
        </div>
      </div>
    );
  };

  return renderModal();
}

export default FilterModal;
