import useApiManager from 'networking/ApiManager';
import React, { useEffect, useRef, useState } from 'react';
import {
  CoreButton,
  CoreDropDown,
  CoreInputBox,
  CoreSearchDropDown,
  Select2DropDown,
} from 'whealth-core-web/components';
import { formulationData } from 'whealth-core-web/components/Helper';

function MedicineForm(props) {
  const { valueObj, error, isUpdate, setValueObj, onClick } = props;
  const ApiClient = useApiManager();

  const [searchArr, setSearchArr] = useState([]);
  const [medicineCategoriesData, setMedicineCategoriesData] = useState([]);

  useEffect(() => {
    medicineCategoriesGet();
  }, []);

  const medicineCategoriesGet = () => {
    ApiClient.medicineCategoriesGet()
      .then((res) => {
        setMedicineCategoriesData(res.data);
      })
      .catch((err) => console.log(err));
  };

  const handleItemClick = (item) => {
    valueObj.medicine_category_title = item.title;
    setValueObj({ ...valueObj });
    setSearchArr([]);
  };
  const searchMedicinesCategory = (inputValue) => {
    ApiClient.searchMedicinesByCategories(inputValue)
      .then((res) => {
        setSearchArr(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const typeArr = [
    { title: 'Published', id: 'published' },
    { title: 'Unpublished', id: 'unpublished' },
    { title: 'Draft', id: 'draft' },
  ];

  const typeArr2 = [
    { title: 'Primary', id: 'primary' },
    { title: 'Secondary', id: 'secondary' },
  ];

  return (
    <div className='medicineForm'>
      <div className='formTitle'>Add Medicine </div>

      <div style={{ margin: '20px 0', gap: '10px' }} className='d-flex w-100 justify-content-between medicineNamerow'>
        <div className='w-100'>
          <CoreInputBox
            showMSG={error?.name}
            placeholder='Medicine Name'
            value={valueObj?.name || ''}
            label='Medicine Name'
            setValue={(inputVal) => {
              setValueObj({ ...valueObj, name: inputVal });
            }}
            maxLength={255}
            msgStyle={{ color: 'red' }}
            retuired
          />
        </div>
        <div className=' w-100'>
          <Select2DropDown
            value={valueObj?.formulation || ''}
            data={formulationData()}
            labelKey='title'
            label={'Medicine Formulation'}
            placeholder={'Medicine Formulation'}
            valueKey='id'
            retuired
            onSelect={(inputVal) => {
              if (inputVal && inputVal != '') {
                setValueObj({ ...valueObj, formulation: inputVal });
              } else {
                // delete valueObj.formulation;
                setValueObj({ ...valueObj, formulation: null });
              }
            }}
            showMSG={error?.formulation}
          />
        </div>
      </div>
      <div className=' medicineNamerow'>
        <div className='d-flex gap-10 w-100 justify-content-between'>
          <div className='w-100 medicine_dropdown'>
            <CoreSearchDropDown
              hideClose
              {...props}
              isAutoSave
              inputValue={valueObj?.medicine_category_title || ''}
              inputStyle={{ height: '39px' }}
              data={searchArr}
              setData={setSearchArr}
              setInputValue={(inputVal) => {
                searchMedicinesCategory(inputVal);
                setValueObj({ ...valueObj, medicine_category_title: inputVal });
              }}
              showMSG={error?.medicine_category_title}
              onClickItem={handleItemClick}
              placeholder='Category Name'
              value={valueObj?.medicine_category_title || ''}
              label='Category Name'
              maxLength={255}
              dropDownStyle={{ position: 'absolute', right: '0px', left: '0px' }}
              searchKey='title'
              isClearValue
              isRightIconref
            />
          </div>
          <div className=' w-100'>
            <CoreInputBox
              showMSG={error?.info}
              placeholder='Medicine Information'
              value={valueObj?.info || ''}
              label='Medicine Information'
              setValue={(inputVal) => {
                setValueObj({ ...valueObj, info: inputVal });
              }}
              maxLength={255}
              msgStyle={{ color: 'red' }}
            />
          </div>
        </div>
      </div>
      <div style={{ margin: '20px 0', gap: '10px' }} className='d-flex gap-10 w-100 justify-content-between'>
        <div className='w-100'>
          <Select2DropDown
            value={valueObj?.medicine_type || ''}
            data={typeArr2}
            labelKey='title'
            label={'Medicine Type'}
            placeholder={'Medicine Type'}
            valueKey='id'
            onSelect={(inputVal) => {
              setValueObj({ ...valueObj, medicine_type: inputVal });
            }}
          />
        </div>
        <div className=' w-100'>
          <CoreDropDown
            value={valueObj?.status || 'published'}
            data={typeArr}
            labelKey='title'
            label={'Status'}
            placeholder={'Status'}
            valueKey='id'
            setValue={(inputVal) => {
              setValueObj({ ...valueObj, status: inputVal });
            }}
          />
        </div>
      </div>
      <CoreButton
        onClick={() => {
          onClick();
        }}
        title={isUpdate ? 'Update' : 'Save'}
      />
    </div>
  );
}

export default MedicineForm;
