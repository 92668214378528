import useApiManager from 'networking/ApiManager';
import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Panel, Steps } from 'rsuite';
import UploadDocument from './UploadDocument';
import { step1Hover } from 'whealth-core-web/assets/images';
import { scrollToTop } from '../Helper';
import PaymentDocumentation from './PaymentDocumentation';
import { useNavigate } from 'react-router-dom';

function InsuranceTab(props) {
  const {
    serviceDataForPayment,
    stepServiceDetails,
    errors,
    setErrors,
    isAddPayment,
    finalSubmitId,
    setFinalSubmitId,
    isShowAlert,
    setIsAddPayment,
    isEditEnsurance,
    setIsEditInsurance,
    editIndex,
    setEditIndex,
    alertType,
    editTableData,
    setEditTableData,
    setStepServiceDetails,
    patientId,
    currentStep,
    pid,
    setAlertType,
    setIsLoading,
    setIsShowAlert,
    formCount,
    setFormCount,
    paymentServiceDetails,
    setPaymentServiceDetails,
    dynamicServiceData,
    setDynamicServiceData,
    step3Data,
    setStep3Data,
    step4Data,
    setStep4Data,
  } = props;
  const [step, setStep] = React.useState(0);
  const [reportData, setReportData] = useState([]);
  const [reportUpdateItemId, setReportUpdateItemId] = useState('');
  const [error, setError] = useState('');
  const [editData, setEditData] = useState('');
  const [stepsData, setStepsData] = useState('');
  const [isSaveBtn, setIsSaveBtn] = useState(false);
  const [saveResponse, setSaveResponse] = useState(false);
  const [isReportData, setIsReportData] = useState(false);
  const [isSubmitBtn, setIsSubmitBtn] = useState(false);

  const ApiClient = useApiManager();
  const navigate = useNavigate();
  const onAddDocument = () => {
    setFormCount(formCount + 1);
  };
  useEffect(() => {
    getPatientReport();
  }, [patientId, step]);
  useEffect(() => {
    if (dynamicServiceData) {
      if (dynamicServiceData.last_step == 'step_1' && !isSaveBtn) {
        setStep(0);
      } else if (dynamicServiceData.last_step == 'step_2' && !isSaveBtn) {
        setStep(1);
      } else if (dynamicServiceData.last_step == 'step_3' && !isSaveBtn) {
        setStep(2);
      } else if (dynamicServiceData.last_step == 'step_4' && !isSaveBtn) {
        setStep(3);
      }

      if (dynamicServiceData?.steps_info) {
        if (editTableData) {
          setPaymentServiceDetails({
            payment_type: paymentServiceDetails?.payment_type,
            patient_attendance_status: editTableData?.patient_attendance_status,
            insurance_status: dynamicServiceData?.steps_info[1]?.insurance_status,
            insured_amount: dynamicServiceData?.steps_info[1]?.insured_amount,
            reason: dynamicServiceData?.steps_info[1]?.reason,
          });
        }
        setStep3Data({
          insurance_status: dynamicServiceData?.steps_info[2]?.insurance_status || null,
          insured_amount:
            dynamicServiceData?.steps_info[2]?.insured_amount || dynamicServiceData?.steps_info[1]?.insured_amount,
          final_bill_amount:
            dynamicServiceData?.steps_info[2]?.final_bill_amount || dynamicServiceData?.final_bill_amount,
          reason: dynamicServiceData?.steps_info[2]?.reason || null,
        });
        setStep4Data({
          insurance_status: dynamicServiceData?.steps_info[3]?.insurance_status || null,
          insured_amount:
            dynamicServiceData?.steps_info[3]?.insured_amount || dynamicServiceData?.steps_info[2]?.insured_amount,
          final_bill_amount:
            dynamicServiceData?.steps_info[3]?.final_bill_amount || dynamicServiceData?.final_bill_amount,
          reason: dynamicServiceData?.steps_info[3]?.reason || '',
        });
      }
    }
  }, [dynamicServiceData?.steps_info]);

  const getServiceData = (patientId, paymentUpdateId) => {
    setIsLoading(true);
    ApiClient.singleDataService(patientId, paymentUpdateId)
      .then((res) => {
        setIsLoading(false);
        setDynamicServiceData(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const getPatientReport = () => {
    setIsLoading(true);
    let params = { patient_service_id: serviceDataForPayment?.id || dynamicServiceData?.id };
    ApiClient.getPatientReportsService(patientId, false, step + 1, params)
      .then((res) => {
        // setTotalPages(res.headers['total-pages']);
        setReportData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const onChange = (nextStep) => {
    setStep(nextStep < 0 ? 0 : nextStep > 3 ? 3 : nextStep);
  };
  // const onNext = () => {
  //   if (alertType !== 'alert-danger') {
  //     onChange(step + 1);
  //   }
  //   if (reportData.length > 0 && step == 0) {
  //     onChange(step + 1);
  //   }
  //   if (paymentServiceDetails.insurance_status === 'approved' && step == 1) {
  //     onChange(step + 1);
  //   }
  //   if (step3Data.insurance_status === 'approved' && step == 2) {
  //     onChange(step + 1);
  //     if (isShowAlert) {
  //       return;
  //     }
  //   }
  //   if (step4Data.insurance_status === 'approved' && step == 3) {
  //     onChange(step + 1);
  //   }
  //   scrollToTop();
  //   setIsSaveBtn(true);
  //   setStepServiceDetails([]);
  //   finalSubmitForm();
  // };

  const onNext = () => {
    switch (step) {
      case 0:
        if (reportData.length > 0) {
          onChange(step + 1);
        }
        break;
      case 1:
        setStep3Data({
          ...step3Data,
          insured_amount: paymentServiceDetails.insured_amount,
          final_bill_amount: dynamicServiceData.final_bill_amount > 0 ? dynamicServiceData.final_bill_amount : null,
        });
        if (paymentServiceDetails.insurance_status === 'approved') {
          onChange(step + 1);
        }
        break;
      case 2:
        setStep4Data({
          ...step4Data,
          insured_amount: step3Data.insured_amount,
          final_bill_amount:
            dynamicServiceData.final_bill_amount > 0
              ? dynamicServiceData.final_bill_amount
              : step3Data.final_bill_amount,
        });
        if (step3Data.insurance_status === 'approved') {
          onChange(step + 1);
        }
        break;
      case 3:
        if (step4Data.insurance_status === 'approved') {
          onChange(step + 1);
        }
        break;
      default:
        break;
    }
    scrollToTop();
    setIsSaveBtn(true);
    setStepServiceDetails([]);
  };

  const handleFinalSubmit = () => {
    finalSubmitForm()
      .then(() => {
        onNext(); // Call onNext only if finalSubmitForm resolves successfully
      })
      .catch(() => {
        console.log('Final submit failed, do not proceed to next step');
      });
  };
  const onPrevious = () => {
    // onChange(step - 1);
    !reportUpdateItemId ? onAddDocument() : alert('Please Update  Supporting Document First');
  };
  const stepWiseParam = () => {
    const { profile_answers, ...paymentDetails } = paymentServiceDetails;
    let params = {};

    if (step === 0) {
      params = {
        steps: [
          {
            id: stepsData?.steps_info
              ? stepsData?.steps_info[0]?.id
              : dynamicServiceData.steps_info && dynamicServiceData.steps_info[0]?.id,
            insurance_status: 'approved',
            step: 'step_1',
            user_order_id: serviceDataForPayment?.user_order_id || dynamicServiceData.user_order_id,
          },
        ],
        profile_answers,
      };
    } else if (step === 1) {
      const step1Data = {
        id: stepsData?.steps_info
          ? stepsData?.steps_info[0]?.id
          : dynamicServiceData.steps_info && dynamicServiceData.steps_info[0]?.id,
        insurance_status: 'approved',
        step: 'step_1',
        user_order_id: serviceDataForPayment?.user_order_id || dynamicServiceData.user_order_id,
      };

      params = {
        steps: [
          step1Data,
          {
            ...paymentDetails,
            id: stepsData?.steps_info
              ? stepsData?.steps_info[1]?.id
              : dynamicServiceData.steps_info && dynamicServiceData.steps_info[1]?.id,
            insurance_status: paymentDetails?.insurance_status ? paymentDetails?.insurance_status : 'pending_approval',
            step: 'step_2',
            user_order_id: serviceDataForPayment?.user_order_id || dynamicServiceData.user_order_id,
          },
        ],
        profile_answers,
      };
    } else if (step === 2) {
      const step1Data = {
        id: stepsData?.steps_info
          ? stepsData?.steps_info[0]?.id
          : dynamicServiceData.steps_info && dynamicServiceData.steps_info[0]?.id,
        insurance_status: 'approved',
        step: 'step_1',
        user_order_id: serviceDataForPayment?.user_order_id || dynamicServiceData.user_order_id,
      };
      const step2Data = {
        ...paymentDetails,
        id: stepsData?.steps_info
          ? stepsData?.steps_info[1]?.id
          : dynamicServiceData.steps_info && dynamicServiceData.steps_info[1]?.id,
        insurance_status: paymentDetails?.insurance_status ? paymentDetails?.insurance_status : 'pending_approval',
        step: 'step_2',
        user_order_id: serviceDataForPayment?.user_order_id || dynamicServiceData.user_order_id,
      };
      params = {
        steps: [
          step1Data,
          step2Data,
          {
            ...step3Data,
            id: stepsData?.steps_info
              ? stepsData?.steps_info[2]?.id
              : dynamicServiceData.steps_info && dynamicServiceData.steps_info[2]?.id,
            insurance_status: step3Data.insurance_status ? step3Data.insurance_status : 'pending_approval',
            step: 'step_3',
            user_order_id: serviceDataForPayment?.user_order_id || dynamicServiceData.user_order_id,
          },
        ],
        profile_answers,
      };
    } else {
      const step1Data = {
        id: stepsData?.steps_info
          ? stepsData?.steps_info[0]?.id
          : dynamicServiceData.steps_info && dynamicServiceData.steps_info[0]?.id,
        insurance_status: 'approved',
        step: 'step_1',
        user_order_id: serviceDataForPayment?.user_order_id || dynamicServiceData.user_order_id,
      };
      const step2Data = {
        ...paymentDetails,
        id: stepsData?.steps_info
          ? stepsData?.steps_info[1]?.id
          : dynamicServiceData.steps_info && dynamicServiceData.steps_info[1]?.id,
        insurance_status: paymentDetails.insurance_status ? paymentDetails.insurance_status : 'pending_approval',
        step: 'step_2',
        user_order_id: serviceDataForPayment?.user_order_id || dynamicServiceData.user_order_id,
      };
      const step3DataFinal = {
        ...step3Data,
        id: stepsData?.steps_info
          ? stepsData?.steps_info[2]?.id
          : dynamicServiceData.steps_info && dynamicServiceData.steps_info[2]?.id,
        insurance_status: step3Data.insurance_status ? step3Data.insurance_status : 'pending_approval',
        step: 'step_3',
        user_order_id: serviceDataForPayment?.user_order_id || dynamicServiceData.user_order_id,
      };
      params = {
        steps: [
          step1Data,
          step2Data,
          step3DataFinal,
          {
            ...step4Data,
            id: stepsData?.steps_info
              ? stepsData?.steps_info[3]?.id
              : dynamicServiceData.steps_info && dynamicServiceData.steps_info[3]?.id,
            insurance_status: step4Data.insurance_status ? step4Data.insurance_status : 'pending_approval',
            step: 'step_4',
            user_order_id: serviceDataForPayment?.user_order_id || dynamicServiceData.user_order_id,
          },
        ],
        profile_answers,
      };
    }
    if (editTableData.payment_type !== 'insurance' && paymentServiceDetails?.payment_type && isEditEnsurance) {
      params.payment_id = editTableData?.id;
    }

    return params;
  };
  console.warn('isSubmitBtn', dynamicServiceData);
  const finalSubmitForm = () => {
    return new Promise((resolve, reject) => {
      const params = stepWiseParam();
      setIsLoading(true);
      setError('');
      setErrors({});
      if (reportData.length > 0 || isSubmitBtn) {
        ApiClient.finalServiceButtonApi(patientId, params, serviceDataForPayment?.id || dynamicServiceData?.id)
          .then((res) => {
            setIsLoading(false);
            setSaveResponse(true);
            setStepsData(res.data);
            setFinalSubmitId(res?.data?.id);
            setIsEditInsurance(false);
            step == 3 && setIsAddPayment(false);
            step == 3 && setEditTableData('');
            step == 3 && setPaymentServiceDetails({ ...paymentServiceDetails, payment_type: '' });
            setAlertType('alert-success');
            setIsShowAlert(`payment ${editTableData ? 'updated' : 'created'} successfully`);
            step == 3 &&
              setTimeout(() => {
                navigate(`/patient/${patientId}/dashboard`);
              }, 2500);
            getServiceData(patientId, res?.data?.id);
            resolve(); // Resolve the promise if API call is successful
          })
          .catch((err) => {
            const lastErrorMessage = err?.response?.data[err?.response?.data?.length - 1]?.message;
            const errorMsg = err?.response?.data[err?.response?.data?.length - 1];

            setIsLoading(false);
            setErrors(errorMsg);
            setAlertType('alert-danger');
            setIsShowAlert(lastErrorMessage);
            reject(); // Reject the promise if API call encounters an error
          });
      } else {
        alert('please attach document first');
        setIsLoading(false);
      }
    });
  };

  const stepHead = (count, label, icon, status) => {
    return (
      <>
        <div className='d-flex stepHead-part'>
          <div className='step-icon d-flex align-items-center justify-content-center'></div>
          <div className='stepTitlePart'>
            <div className='stepTitle'>{count}</div>
            <div className='steplabel'>{label}</div>
            <div className={`stepStatus d-flex align-items-center text-capitalize ${status}`}>
              <span class='material-icons'>{icon}</span>
              {status}
            </div>
          </div>
        </div>
      </>
    );
  };

  const prepateFormData = (data, index) => {
    const formData = new FormData();
    stepServiceDetails[index]?.report &&
      typeof stepServiceDetails[index]?.report != 'string' &&
      formData.append('report', stepServiceDetails[index]?.report || '');
    formData.append('title', stepServiceDetails[index]?.title || '');
    formData.append('description', stepServiceDetails[index]?.description || '');
    formData.append('status', `step_${step + 1}` || '');
    formData.append('channel', 'dashboard_service' || '');
    formData.append('patient_service_id', serviceDataForPayment?.id || dynamicServiceData?.id);
    return formData;
  };

  const createReports = (index) => {
    setError('');
    setIsLoading(true);
    if (isReportData) {
      ApiClient.createPatientReport(patientId, prepateFormData(stepServiceDetails, index))
        .then((res) => {
          setIsLoading(false);
          getPatientReport();
          setError('');
          const updatedStepServiceDetails = [...stepServiceDetails];
          updatedStepServiceDetails.splice(index, 1);
          setStepServiceDetails(updatedStepServiceDetails);
          console.warn('09', updatedStepServiceDetails);
          setFormCount(formCount - 1);
          if (formCount == 1) {
            setFormCount(formCount);
          }
          setAlertType('alert-success');
          setIsShowAlert('Report created successfully');
        })
        .catch((err) => {
          setIsLoading(false);
          setAlertType('alert-danger');
          setError({ ...error, [index]: err.response.data.errors });
        });
    } else {
      alert('Please attach document first.');
      setIsLoading(false);
    }
  };

  const updateReports = (index) => {
    setError('');
    setIsLoading(true);
    ApiClient.updateReports(patientId, prepateFormData(stepServiceDetails, index), reportUpdateItemId)
      .then((res) => {
        setIsLoading(false);
        setAlertType('alert-success');
        setIsShowAlert('Report updated successfully');
        getPatientReport();
        setEditData('');
        setReportUpdateItemId('');
        if (formCount === 1) {
          const updatedStepServiceDetails = [...stepServiceDetails];
          updatedStepServiceDetails[index] = {};
          setStepServiceDetails(updatedStepServiceDetails);
        } else {
          setFormCount(formCount - 1);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setAlertType('alert-danger');
        setError(err.response.data.errors);
        setError({ ...error, [index]: err.response.data.errors });
      });
  };

  const updateData = (item) => {
    setReportUpdateItemId(item?.id);
    if (item) {
      setFormCount(1);
      const updatedStepServiceDetails = [...stepServiceDetails];
      updatedStepServiceDetails[0] = {
        title: item.title || '',
        description: item.description || '',
        report: item.report || '',
      };
      setStepServiceDetails(updatedStepServiceDetails);
    }
  };

  const uploadDocumentTab = () => {
    const handleStepClick = (index) => {
      if (index > step) {
        return;
      }

      setStep(index);
      setStepServiceDetails([]);
      setErrors({});
      setReportUpdateItemId('');
    };
    return (
      <div className='insuranceTab'>
        <Steps current={step}>
          <Steps.Item
            onClick={() => handleStepClick(0)}
            title={stepHead(
              'Step 1',
              'Upload Documents',
              `${
                reportData?.length || (!reportData?.length && step == 1) || step == 2 || step == 3 ? 'check_circle' : ''
              }`,
              `${
                reportData?.length || (!reportData?.length && step == 1) || step == 2 || step == 3
                  ? 'Documents Uploaded'
                  : ''
              }`
            )}
          />
          <Steps.Item
            onClick={() => handleStepClick(1)}
            title={stepHead(
              'Step 2',
              'Pre Approvals',
              `${
                paymentServiceDetails.insurance_status == 'approved'
                  ? 'check_circle'
                  : paymentServiceDetails.insurance_status == 'pending_approval'
                  ? ''
                  : 'error'
              }`,
              paymentServiceDetails?.insurance_status !== 'pending_approval' && paymentServiceDetails?.insurance_status
            )}
          />
          <Steps.Item
            onClick={() => handleStepClick(2)}
            title={stepHead(
              'Step 3',
              'Post Procedure',
              `${step3Data.insurance_status == 'approved' && step !== 2 ? 'check_circle' : ''}`,
              `${step3Data.insurance_status == 'approved' && step !== 2 ? 'Submitted' : ''}`
            )}
          />
          <Steps.Item onClick={() => handleStepClick(3)} title={stepHead('Step 4', 'Final Billing')} />
        </Steps>
        <hr />
        <Panel>
          {step !== 3 ? (
            <UploadDocument
              isAddPayment={isAddPayment}
              isReportData={isReportData}
              setIsReportData={setIsReportData}
              errors={errors}
              editIndex={editIndex}
              step3Data={step3Data}
              setEditTableData={setEditTableData}
              dynamicServiceData={dynamicServiceData}
              setStep3Data={setStep3Data}
              step4Data={step4Data}
              setStep4Data={setStep4Data}
              editData={editData}
              setEditData={setEditData}
              editTableData={editTableData}
              error={error}
              paymentServiceDetails={paymentServiceDetails}
              setPaymentServiceDetails={setPaymentServiceDetails}
              step={step}
              setFormCount={setFormCount}
              formCount={formCount}
              updateData={updateData}
              patientId={patientId}
              setAlertType={setAlertType}
              setIsLoading={setIsLoading}
              setIsShowAlert={setIsShowAlert}
              getPatientReport={getPatientReport}
              reportData={reportData}
              setReportUpdateItemId={setReportUpdateItemId}
              reportUpdateItemId={reportUpdateItemId}
              onClick={reportUpdateItemId ? updateReports : createReports}
              stepServiceDetails={stepServiceDetails}
              setStepServiceDetails={setStepServiceDetails}
            />
          ) : (
            <PaymentDocumentation
              errors={errors}
              setIsSubmitBtn={setIsSubmitBtn}
              editIndex={editIndex}
              setEditIndex={setEditIndex}
              step3Data={step3Data}
              finalSubmitForm={finalSubmitForm}
              step4Data={step4Data}
              setStep4Data={setStep4Data}
              setIsAddPayment={setIsAddPayment}
              setDynamicServiceData={setDynamicServiceData}
              editTableData={editTableData}
              setEditTableData={setEditTableData}
              dynamicServiceData={dynamicServiceData}
              paymentServiceDetails={paymentServiceDetails}
              setPaymentServiceDetails={setPaymentServiceDetails}
              serviceDataForPayment={serviceDataForPayment}
              setAlertType={setAlertType}
              setIsLoading={setIsLoading}
              setIsShowAlert={setIsShowAlert}
              patientId={patientId}
              step={step}
              formCount={formCount}
              setFormCount={setFormCount}
            />
          )}
        </Panel>
        {step !== 3 && <hr />}

        {step !== 3 && (
          <div className='d-flex gap-10 Documentbtn w-100'>
            <div
              onClick={onPrevious}
              disabled={step === 0}
              className='form-control coreBtn  btn mt-3 btn-outline-primary  btn-lg w-100'
            >
              + Add Another Document
            </div>
            <div
              onClick={() => {
                handleFinalSubmit();
              }}
              disabled={step === 3}
              className='form-control  coreBtn text-white btn btn-secondary btn-lg w-100'
            >
              Save Details
            </div>
          </div>
        )}
      </div>
    );
  };
  return uploadDocumentTab();
}

export default InsuranceTab;
