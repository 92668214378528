import React from 'react';
import { dateFormat, profileImage, timeFormat } from '../Helper';
function CommonServiceProfileBadge(props) {
  const {
    patientDetails,
    isPaymentDetail,
    headerServiceDetail,
    headerLocationDetail,
    headerDoctorDetail,
    headerEstimateCostDetail,
    dynamicServiceData,
    pid,
  } = props;

  const getPrcDate = headerServiceDetail?.service_schema?.find((item) => item.name == 'prc_date');
  const getPrcUpdateDate = dynamicServiceData?.data?.find((item) => item.name == 'prc_date');

  return (
    <>
      {!pid ? (
        <div
          className={`mt-4  d-flex justify-content-start align-items-center ${
            isPaymentDetail && 'ServiceProfile-head'
          } gap-10`}
        >
          <div className='service-profile d-flex align-items-center capitalize'>
            <img src={profileImage(patientDetails.gender, patientDetails.image)} className='userImage' />
            <div className='d-flex user-detail'>
              {patientDetails.full_name} ({patientDetails.gender}, {patientDetails.age}yrs)
            </div>
          </div>
          {isPaymentDetail && (
            <div className='d-flex justify-content-between align-items-start ServiceProfile-part w-100'>
              <div>
                <span>Service Type</span>
                <br />
                {isPaymentDetail && headerServiceDetail.title}
              </div>
              <div>
                <span>PRC Date</span>
                <br />
                {isPaymentDetail && dateFormat(getPrcDate?.answer)}
                &nbsp;
                {isPaymentDetail && timeFormat(getPrcDate?.answer)}
              </div>
              <div>
                <span>Location</span>
                <br />
                {isPaymentDetail && headerLocationDetail.title}
              </div>
              <div>
                <span>Doctor</span>
                <br />
                {isPaymentDetail && headerDoctorDetail.full_name}
              </div>
              <div>
                <span>Service Cost</span>
                <br />
                <strong>
                  <span class='material-icons-outlined'>currency_rupee</span>{' '}
                  {dynamicServiceData?.final_bill_amount > 0
                    ? dynamicServiceData?.final_bill_amount
                    : headerEstimateCostDetail}
                </strong>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div
          className={`mt-4  d-flex justify-content-start align-items-center ${
            isPaymentDetail && 'ServiceProfile-head'
          } gap-10`}
        >
          <div className='service-profile d-flex align-items-center capitalize '>
            <img src={profileImage(patientDetails.gender, patientDetails.image)} className='userImage' />
            <div className='d-flex  user-detail'>
              {patientDetails.full_name} ({patientDetails.gender}, {patientDetails.age}yrs)
            </div>
          </div>
          {isPaymentDetail && (
            <div className='d-flex justify-content-between align-items-start ServiceProfile-part w-100'>
              <div>
                <span>Service Type</span>
                <br />
                {isPaymentDetail && dynamicServiceData?.service?.title}
              </div>
              <div>
                <span>PRC Date</span>
                <br />
                {isPaymentDetail && dateFormat(getPrcUpdateDate?.answer)}
                &nbsp;
                {isPaymentDetail && timeFormat(getPrcUpdateDate?.answer)}
              </div>
              <div>
                <span>Location</span>
                <br />
                {isPaymentDetail && dynamicServiceData?.location?.name}
              </div>
              <div>
                <span>Doctor</span>
                <br />
                {isPaymentDetail && dynamicServiceData?.doctor?.full_name}
              </div>
              <div>
                <span>Service Cost</span>
                <br />
                <strong>
                  <span class='material-icons-outlined'>currency_rupee</span>{' '}
                  {dynamicServiceData?.final_bill_amount > 0
                    ? dynamicServiceData?.final_bill_amount
                    : dynamicServiceData?.estimated_cost}
                </strong>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}
export default CommonServiceProfileBadge;
