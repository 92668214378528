import useApiManager from 'networking/ApiManager';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { searchLight } from 'res/images';
import {
  CommonBorderBox,
  CoreButton,
  CoreCheckBox,
  CoreDropDown,
  CoreInputBox,
  DragAndDropFiles,
} from 'whealth-core-web/components';
import TimezoneSelect from 'react-timezone-select';
import CommonRenderCheckBox from 'whealth-core-web/components/CommonRenderCheckBox';
import { LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import TimeKeeper from 'react-timekeeper';
function ConfigForm(props) {
  const { error, valueObj, setValueObj, onClick, setIsLoading, logo } = props;
  const ApiClient = useApiManager();
  const [data, setData] = useState([]);
  const [showFrom, setShowFrom] = useState(false);
  const [showTo, setShowTo] = useState(false);

  useEffect(() => {
    getGoalsData();
  }, []);

  const getGoalsData = () => {
    setIsLoading(true);
    ApiClient.getGoals()
      .then((res) => {
        setIsLoading(false);
        setData(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const renderZohoMeetingCheckBox = () => {
    return (
      <div className='mt-1 d-flex flex-wrap '>
        <div className='mt-3 d-flex flex-wrap'>
          <div className='form-check '>
            <input
              className='form-check-input'
              style={{ cursor: 'pointer' }}
              checked={valueObj.enable_zoho_meeting}
              onChange={(data) => {
                setValueObj({
                  ...valueObj,
                  enable_zoho_meeting: !valueObj.enable_zoho_meeting,
                });
              }}
              type='checkbox'
              id='flexCheckDefault6'
            />
          </div>
          <label className='pointer' htmlFor='flexCheckDefault6'>
            Enable Zoho Meeting Integration
          </label>
        </div>
      </div>
    );
  };

  const renderBox = () => {
    return (
      <>
        <div className='commonBorderBox settingFormOutline mb-4 pb-4'>
          <div className='formTitle'>Business Info</div>

          <CommonBorderBox style={{ marginTop: 10 }}>
            <CoreInputBox
              showMSG={error.business_name}
              placeholder='Business Name'
              value={valueObj.business_name || ''}
              label='Business Name'
              setValue={(data) => {
                setValueObj({ ...valueObj, business_name: data });
              }}
              maxLength={255}
              msgStyle={{ color: 'red' }}
            />
            <div style={{ marginTop: '10px' }}>
              <CoreInputBox
                validateNumbers
                maxLength={10}
                showMSG={error.contact_phone}
                placeholder='Contact Phone'
                value={valueObj.contact_phone || ''}
                label='Contact Phone'
                setValue={(data) => {
                  setValueObj({ ...valueObj, contact_phone: data });
                }}
                msgStyle={{ color: 'red' }}
              />
            </div>
            <div style={{ marginTop: '10px' }}>
              <CoreInputBox
                showMSG={error.contact_email}
                placeholder='Contact Email'
                value={valueObj.contact_email || ''}
                label='Contact Email'
                setValue={(data) => {
                  setValueObj({ ...valueObj, contact_email: data });
                }}
                maxLength={255}
                msgStyle={{ color: 'red' }}
              />
            </div>
            <div className='mt-3'>Business Hours</div>
            <div
              style={{ margin: '20px 0', gap: '10px' }}
              className='d-flex w-100 justify-content-between medicineNamerow'
            >
              <div
                className='w-100 daypart-time'
                onBlur={() => {
                  setShowFrom(false);
                }}
              >
                <CoreInputBox
                  validateNumbers
                  placeholder='Start Time'
                  value={valueObj?.business_start_time?.formatted24 || valueObj?.business_start_time || ''}
                  label='Start Time'
                  msgStyle={{ color: 'red' }}
                  handleOnFocus={() => {
                    setShowTo(false);
                    setShowFrom(true);
                  }}
                />

                {showFrom && (
                  <TimeKeeper
                    time={valueObj?.business_start_time?.formatted24 || valueObj?.business_start_time}
                    switchToMinuteOnHourSelect
                    closeOnMinuteSelect={false}
                    onDoneClick={() => {
                      setShowFrom(false);
                    }}
                    onChange={(time) => {
                      setValueObj({ ...valueObj, business_start_time: time?.formatted24 });
                    }}
                  />
                )}
              </div>
              <div
                className='w-100 daypart-time'
                onBlur={() => {
                  setShowTo(false);
                }}
              >
                <CoreInputBox
                  validateNumbers
                  placeholder='End Time'
                  value={valueObj?.business_end_time?.formatted24 || valueObj?.business_end_time || ''}
                  label='End Time'
                  msgStyle={{ color: 'red' }}
                  handleOnFocus={() => {
                    setShowTo(true);
                    setShowFrom(false);
                  }}
                />
                {showTo && (
                  <TimeKeeper
                    time={valueObj?.business_end_time?.formatted24 || valueObj?.business_end_time}
                    switchToMinuteOnHourSelect
                    closeOnMinuteSelect={false}
                    onDoneClick={(time) => {
                      setShowTo(false);
                    }}
                    onChange={(time) => {
                      setValueObj({ ...valueObj, business_end_time: time?.formatted24 });
                    }}
                  />
                )}
              </div>
            </div>

            <div style={{ marginTop: '10px' }}>
              <CoreInputBox
                showMSG={error.website_url}
                placeholder='Website Url'
                value={valueObj.website_url || ''}
                label='Website Url'
                setValue={(data) => {
                  setValueObj({ ...valueObj, website_url: data });
                }}
                maxLength={255}
                msgStyle={{ color: 'red' }}
              />
            </div>
            <div className='mt-4'>
              <CoreInputBox
                showMSG={error.redcliffe_iframe_url}
                placeholder='Terms and Condition URL'
                value={valueObj.terms_and_privacy_policy_url || ''}
                label='Terms and Condition URL'
                setValue={(data) => {
                  setValueObj({
                    ...valueObj,
                    terms_and_privacy_policy_url: data,
                  });
                }}
              />
            </div>
            <div className='mt-4'>
              <CoreInputBox
                showMSG={error.redcliffe_iframe_url}
                placeholder='Privacy Policy URL'
                value={valueObj.privacy_policy_url || ''}
                label='Privacy Policy URL'
                setValue={(data) => {
                  setValueObj({ ...valueObj, privacy_policy_url: data });
                }}
              />
            </div>
            <div style={{ margin: '20px 0' }}>
              <CoreInputBox
                showMSG={error.image || ''}
                value=''
                label='Logo'
                setValue={(data) => {
                  setValueObj({ ...valueObj, logo_url: data[0] });
                }}
                inputStyle={{ height: 'unset', paddingLeft: 'unset' }}
                msgStyle={{ color: 'red' }}
                type='file'
                accept='image/*'
              />
            </div>
            {valueObj.logo_url && (
              <div>
                <img className='showImageStyle' src={URL.createObjectURL(valueObj.logo_url)} />
              </div>
            )}
            {!valueObj.logo_url && logo && (
              <div>
                <img className='showImageStyle' src={logo} />
              </div>
            )}
          </CommonBorderBox>
        </div>
        <div className='commonBorderBox settingFormOutline mb-4 pb-4'>
          <div className='formTitle'>Support</div>
          <CommonBorderBox style={{ marginTop: 10 }}>
            <div className='App'>
              <div className='select-wrapper'>
                <label className='w-100 inputlabel'>TimeZone</label>

                <TimezoneSelect
                  value={valueObj.time_zone || ''}
                  onChange={(data) => {
                    setValueObj({ ...valueObj, time_zone: data.value });
                  }}
                />
              </div>
            </div>
            <div style={{ marginTop: 10 }}>
              <CoreInputBox
                showMSG={error.phone}
                validateNumbers
                placeholder='Phone'
                value={valueObj.phone || ''}
                label='Phone'
                setValue={(data) => {
                  setValueObj({ ...valueObj, phone: data });
                }}
                maxLength={10}
                msgStyle={{ color: 'red' }}
              />
            </div>
            <div style={{ marginTop: '10px' }}>
              <CoreInputBox
                showMSG={error.email}
                placeholder='Email'
                value={valueObj.email || ''}
                label='Email'
                setValue={(data) => {
                  setValueObj({ ...valueObj, email: data });
                }}
                maxLength={255}
                msgStyle={{ color: 'red' }}
              />
            </div>
          </CommonBorderBox>
        </div>

        <div className='commonBorderBox settingFormOutline mb-4 pb-4'>
          <div className='formTitle'>Miscellaneous Info</div>
          <div className='mt-4'>
            <CoreInputBox
              showMSG={error.redcliffe_iframe_url}
              placeholder='Redcliffe Iframe URL'
              value={valueObj.redcliffe_iframe_url || ''}
              label='Redcliffe Iframe URL'
              setValue={(data) => {
                setValueObj({ ...valueObj, redcliffe_iframe_url: data });
              }}
            />
          </div>
          <div className='mt-4'>
            <CoreInputBox
              showMSG={error.slack_bot_token}
              placeholder='Slack Bot Token'
              value={valueObj.slack_bot_token || ''}
              label='Slack bot token'
              setValue={(data) => {
                setValueObj({ ...valueObj, slack_bot_token: data });
              }}
            />
          </div>
          <div className='mt-4'>
            <CoreInputBox
              showMSG={error.patient_id_format}
              placeholder='Patient ID Format'
              value={valueObj.patient_id_format || ''}
              label='Patient ID Format'
              setValue={(data) => {
                setValueObj({ ...valueObj, patient_id_format: data });
              }}
            />
          </div>
          <div className='mt-4'>
            <CoreInputBox
              showMSG={error.patient_id_prefix}
              placeholder='Patient ID Prefix'
              value={valueObj.patient_id_prefix || ''}
              label='Patient ID Prefix'
              setValue={(data) => {
                setValueObj({ ...valueObj, patient_id_prefix: data });
              }}
            />
          </div>
        </div>
        <div className='commonBorderBox settingFormOutline mb-4 pb-4'>
          <div className='formTitle'>Zoho Configuration</div>

          <div className='commonBorderBox settingFormOutline mb-4 pb-4'>
            <div className='formTitle'>Zoho Webhooks</div>
            <CommonRenderCheckBox
              label=' Enable Zoho CRM Integration'
              id='flexCheckDefault1'
              htmlFor='flexCheckDefault1'
              onChange={(data) => {
                setValueObj({
                  ...valueObj,
                  enable_zoho_crm: !valueObj.enable_zoho_crm,
                });
              }}
              checked={valueObj.enable_zoho_crm}
            />
            <CommonRenderCheckBox
              label='Zoho Lead Update Webhook'
              id='flexCheckDefault2'
              htmlFor='flexCheckDefault2'
              onChange={(data) => {
                setValueObj({
                  ...valueObj,
                  zoho_outgoing_lead_update_webhook: !valueObj.zoho_outgoing_lead_update_webhook,
                });
              }}
              checked={valueObj.zoho_outgoing_lead_update_webhook}
            />
            <div className='mt-4'>
              <CoreInputBox
                showMSG={error.zoho_outgoing_lead_update_webhook_url}
                placeholder='Zoho Lead Update Webhook Url'
                value={valueObj.zoho_outgoing_lead_update_webhook_url || ''}
                label='Zoho Lead Update Webhook Url'
                setValue={(data) => {
                  setValueObj({
                    ...valueObj,
                    zoho_outgoing_lead_update_webhook_url: data,
                  });
                }}
              />
            </div>
          </div>

          <div className='commonBorderBox settingFormOutline mb-4 pb-4'>
            <div className='formTitle'>Zoho Meetings</div>
            <div className='mt-4'>
              <CoreInputBox
                showMSG={error.zoho_client_id}
                placeholder='Zoho Client ID'
                value={valueObj.zoho_client_id || ''}
                label='Zoho Client ID'
                setValue={(data) => {
                  setValueObj({ ...valueObj, zoho_client_id: data });
                }}
              />
            </div>
            <div className='mt-4'>
              <CoreInputBox
                showMSG={error.zoho_client_secret}
                placeholder='Zoho Client Secret'
                value={valueObj.zoho_client_secret || ''}
                label='Zoho Client Secret'
                setValue={(data) => {
                  setValueObj({ ...valueObj, zoho_client_secret: data });
                }}
              />
            </div>
            <div className='mt-4'>
              <CoreInputBox
                showMSG={error.zoho_scope}
                placeholder='Scope'
                value={valueObj.zoho_scope || ''}
                label='Scope'
                setValue={(data) => {
                  setValueObj({ ...valueObj, zoho_scope: data });
                }}
              />
            </div>
            <div className='mt-4'>
              <CoreInputBox
                showMSG={error.zoho_redirection_uri}
                placeholder='Zoho Redirect URL'
                value={valueObj.zoho_redirection_uri || ''}
                label='Zoho Redirect URL'
                setValue={(data) => {
                  setValueObj({ ...valueObj, zoho_redirection_uri: data });
                }}
              />
            </div>
            {renderZohoMeetingCheckBox()}
          </div>
        </div>

        <div className='commonBorderBox settingFormOutline mb-4 pb-4'>
          <div className='formTitle'>WATI Configuration</div>

          <div className='commonBorderBox settingFormOutline mb-4 pb-4'>
            <div className='formTitle'>Account Configuration</div>
            <div className='mt-4'>
              <CoreInputBox
                showMSG={error.wati_access_token}
                placeholder='Access Token'
                value={valueObj.wati_access_token || ''}
                label='Access Token'
                setValue={(data) => {
                  setValueObj({ ...valueObj, wati_access_token: data });
                }}
              />
            </div>
            <div className='mt-4'>
              <CoreInputBox
                showMSG={error.wati_api_endpoint}
                placeholder='Wati Base URL'
                value={valueObj.wati_api_endpoint || ''}
                label='WATI Base URL'
                setValue={(data) => {
                  setValueObj({ ...valueObj, wati_api_endpoint: data });
                }}
              />
            </div>
            <div className='mt-4'>
              <CoreInputBox
                showMSG={error.wati_broadcast_name_prefix}
                placeholder='WATI Broadcast Name'
                value={valueObj.wati_broadcast_name_prefix || ''}
                label='WATI Broadcast Name'
                setValue={(data) => {
                  setValueObj({
                    ...valueObj,
                    wati_broadcast_name_prefix: data,
                  });
                }}
              />
            </div>
            <div className='mt-4'>
              <CoreInputBox
                showMSG={error.wati_api_key}
                placeholder='WATI API Key'
                value={valueObj.wati_api_key || ''}
                label='WATI API Key'
                setValue={(data) => {
                  setValueObj({ ...valueObj, wati_api_key: data });
                }}
              />
            </div>
            <div className='mt-4'>
              <CoreInputBox
                showMSG={error.wati_token_secret}
                placeholder='WATI Token Secret'
                value={valueObj.wati_token_secret || ''}
                label='WATI Token Secret'
                setValue={(data) => {
                  setValueObj({ ...valueObj, wati_token_secret: data });
                }}
              />
            </div>
          </div>

          <div className='commonBorderBox settingFormOutline mb-4 pb-4'>
            <div className='formTitle'>Goal Notification Configuration</div>
            <div className='d-flex justify-content-left'>
              <div className=' w-50'>
                <CommonRenderCheckBox
                  label='Goal Assignment Notification'
                  id='flexCheckDefault3'
                  htmlFor='flexCheckDefault3'
                  onChange={(data) => {
                    setValueObj({
                      ...valueObj,
                      wati_goal_assignment_notification: !valueObj.wati_goal_assignment_notification,
                    });
                  }}
                  checked={valueObj.wati_goal_assignment_notification}
                />
              </div>
              <div className=' w-50'>
                <CoreInputBox
                  showMSG={error.wati_goal_assignment_notification_template_name}
                  placeholder='Goal Assignment Notification Template Name'
                  value={valueObj.wati_goal_assignment_notification_template_name || ''}
                  label='Goal Assignment Notification Template Name'
                  setValue={(data) => {
                    setValueObj({
                      ...valueObj,
                      wati_goal_assignment_notification_template_name: data,
                    });
                  }}
                />
              </div>
            </div>
            <div className='d-flex justify-content-between mt-4'>
              <div className=' w-50'>
                <CommonRenderCheckBox
                  label='Goal Reminder Notification'
                  id='flexCheckDefault4'
                  htmlFor='flexCheckDefault4'
                  onChange={(data) => {
                    setValueObj({
                      ...valueObj,
                      wati_goal_reminder_notification: !valueObj.wati_goal_reminder_notification,
                    });
                  }}
                  checked={valueObj.wati_goal_reminder_notification}
                />
              </div>

              <div className=' w-50'>
                <CoreInputBox
                  showMSG={error.wati_goal_reminder_notification_template_name}
                  placeholder='Goal Reminder Notification Template Name'
                  value={valueObj.wati_goal_reminder_notification_template_name || ''}
                  label='Goal Reminder Notification Template Name'
                  setValue={(data) => {
                    setValueObj({
                      ...valueObj,
                      wati_goal_reminder_notification_template_name: data,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div className='commonBorderBox settingFormOutline mb-4 pb-4'>
            <div className='formTitle'>Prescription Notification Configuration</div>
            <div className='d-flex justify-content-left'>
              <div className=' w-50'>
                <CommonRenderCheckBox
                  label='Prescription Publish Notification'
                  id='flexCheckDefault5'
                  htmlFor='flexCheckDefault5'
                  onChange={(data) => {
                    setValueObj({
                      ...valueObj,
                      wati_prescription_publish_notification: !valueObj.wati_prescription_publish_notification,
                    });
                  }}
                  checked={valueObj.wati_prescription_publish_notification}
                />
              </div>
              <div className=' w-50'>
                <CoreInputBox
                  showMSG={error.wati_prescription_publish_notification_template_name}
                  placeholder='Prescription Publish Notification Template Name'
                  value={valueObj.wati_prescription_publish_notification_template_name || ''}
                  label='Prescription Publish Notification Template Name'
                  setValue={(data) => {
                    setValueObj({
                      ...valueObj,
                      wati_prescription_publish_notification_template_name: data,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className='commonBorderBox settingFormOutline mb-4 pb-4'>
          <div className='formTitle'>Web Engage Configuration</div>

          <div className='commonBorderBox settingFormOutline mb-4 pb-4'>
            <div className='formTitle'>Account Configuration</div>
            <div className='mt-4'>
              <CoreInputBox
                showMSG={error.web_engage_license_code}
                placeholder='License Code'
                value={valueObj.web_engage_license_code || ''}
                label='License Code'
                setValue={(data) => {
                  setValueObj({ ...valueObj, web_engage_license_code: data });
                }}
              />
            </div>
            <div className='mt-4'>
              <CoreInputBox
                showMSG={error.web_engage_api_key}
                placeholder='API Key'
                value={valueObj.web_engage_api_key || ''}
                label='API Key'
                setValue={(data) => {
                  setValueObj({ ...valueObj, web_engage_api_key: data });
                }}
              />
            </div>
          </div>
        </div>
        <div className='commonBorderBox settingFormOutline mb-4 pb-4'>
          <div className='formTitle'>Branch credentials</div>

          <div className='commonBorderBox settingFormOutline mb-4 pb-4'>
            <div className='formTitle'>Branch Configuration</div>
            <div className='mt-4'>
              <CoreInputBox
                retuired
                showMSG={error.branch_io_base_url}
                placeholder='Branch IO Base Url'
                value={valueObj.branch_io_base_url || ''}
                label='Branch IO Base Url'
                setValue={(data) => {
                  setValueObj({ ...valueObj, branch_io_base_url: data });
                }}
              />
            </div>
            <div className='mt-4'>
              <CoreInputBox
                retuired
                showMSG={error.branch_io_api_key}
                placeholder='Branch IO Api Key'
                value={valueObj.branch_io_api_key || ''}
                label='Branch IO Api Key'
                setValue={(data) => {
                  setValueObj({ ...valueObj, branch_io_api_key: data });
                }}
              />
            </div>
          </div>
        </div>
        <div className='commonBorderBox settingFormOutline mb-4 pb-4'>
          <div className='formTitle'>Mobile Configuration</div>
          <CommonRenderCheckBox
            label='Google Fit Enable'
            id='flexCheckDefault8'
            htmlFor='flexCheckDefault8'
            onChange={(data) => {
              setValueObj({
                ...valueObj,
                google_fit: !valueObj.google_fit,
              });
            }}
            checked={valueObj.google_fit}
          />
          <CommonRenderCheckBox
            label='Apple Fit Enable'
            id='flexCheckDefault9'
            htmlFor='flexCheckDefault9'
            onChange={(data) => {
              setValueObj({
                ...valueObj,
                apple_health: !valueObj.apple_health,
              });
            }}
            checked={valueObj.apple_health}
          />
          <CommonRenderCheckBox
            label='Goal Reminder Pop-up Enable'
            id='flexCheckDefault10'
            htmlFor='flexCheckDefault10'
            onChange={(data) => {
              setValueObj({
                ...valueObj,
                show_goal_reminder: !valueObj.show_goal_reminder,
              });
            }}
            checked={valueObj.show_goal_reminder}
          />
        </div>
      </>
    );
  };

  return (
    <>
      <div style={{ gap: '10px' }} className='d-flex w-100 justify-content-between '>
        <div className='w-100 '>{renderBox()}</div>
      </div>
      <CoreButton onClick={onClick} title={'Save'} />
    </>
  );
}

export default ConfigForm;
