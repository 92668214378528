import useApiManager from 'networking/ApiManager';
import React, { useEffect, useRef, useState } from 'react';
import { useGetLoginUserDataQuery } from 'redux/apiSlice';
import { upload } from 'res/images';
import { CoreInputBox, CoreButton, TagsShow, CommonBorderBox, BorderBtnWithBg, Loader, Select2DropDown } from 'whealth-core-web/components';
import CoreSearchDropDown from 'whealth-core-web/components/CoreSearchDropDown';
import { scrollToTop, teamRoles } from 'whealth-core-web/components/Helper';
import './teamForm.css';
import { useDispatch, useSelector } from 'react-redux';
import { useGetCockpitDataQuery } from 'redux/cockpitSlice';

import panelRoles from '../../../src/role.json';
import { setActiveTabTeamForm, setSelectedRole } from 'redux/Slices';
import { DatePicker, Slider, Steps, Toggle } from 'rsuite';
import { ToastContainer, toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';

function TeamForm(props) {
  const ApiClient = useApiManager();
  const { onClick, id, errors, status, assignRole, hideAssignRole, isNivanCareTeam, isBabyMd } = props;
  const [formData, setFormData] = useState({});
  const [image, setImage] = useState('');
  const [serverImage, setServerImage] = useState('');
  const [careManagersList, setCareManagersList] = useState([]);
  const [isLoading, setIsLoading] = useState('');
  const [data, setData] = useState('');
  const [pms, setPms] = useState('');
  const [listData, setListData] = useState([]);
  const dispatch = useDispatch();
  const location = useLocation();

  let userData = {};
  userData = useGetLoginUserDataQuery()?.data?.data || {};
  let { isLoading: reduxIsloading } = useGetLoginUserDataQuery();
  const inputRef = useRef();
  const locations = useSelector((state) => state.sliceReducer.locations);
  const departments = useSelector((state) => state.sliceReducer.allDepartments);
  const [isNivanCare, setIsNivanCare] = useState(false);
  const [isClinic, setIsClinic] = useState(false);

  const [appointmentSlot, setAppointmentSlot] = useState(5); // state for the appointment slot duration

  const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']; // array of days
  const statuses = ['Available', 'Not Available'];
  const [currentStep, setCurrentStep] = useState(0);
  const steps = ['Personal Information', 'Credentials', 'Working Hours & Appointments', 'Zoho Details'];
  const [activeTab, setActiveTab] = useState(steps[currentStep]);

  const [unsavedChanges, setUnsavedChanges] = useState(false);

  const [appointmentSlotWindow, setAppointmentSlotWindow] = useState('');
  const [tabErrors, setTabErrors] = useState([false, false, false]);
  const [selectedClinics, setSelectedClinics] = useState([]);
  const [serviceData, setServiceData] = useState([]);

  const initialTimeSlots = days.map((day, index) => ({
    week_day: index,
    id: '',
    is_available: false,
    slots: [],
  }));

  useEffect(() => {
    getServicesClinic();
  }, []);

  useEffect(() => {
    const path = location.pathname;
    if (path === `/team/${userData.id}/edit`) {
      setActiveTab('Personal Information');
    }
  }, [location]);

  useEffect(() => {
    dispatch(setActiveTabTeamForm(activeTab));
  }, [activeTab]);

  useEffect(() => {
    dispatch(setSelectedRole(formData.role));
  }, [formData.role]);

  const [timeSlots, setTimeSlots] = useState(() => initialTimeSlots);

  const getCockpitData = useGetCockpitDataQuery();
  let configData = getCockpitData?.data?.data?.business_config;
  useEffect(() => {
    if (configData) {
      configData.map((item) => {
        if (item.key == 'enable_summary' && item.isActive) {
          setIsNivanCare(item.isActive);
        }
      });
    }
  }, [configData]);
  useEffect(() => {
    if (configData) {
      configData.map((item) => {
        if (item.key == 'departments_clinics' && item.isActive) {
          setIsClinic(item.isActive);
        }
      });
    }
  }, [configData, formData]);

  useEffect(() => {
    if ((errors && errors.email) || errors.phone) {
      setCurrentStep(0);
      setActiveTab(steps[0]);
    } else if (errors && errors.working_hours) {
      setCurrentStep(2);
      setActiveTab(steps[2]);
    }
  }, [errors]);

  useEffect(() => {
    if (id) {
      getTeamData();
      getTeamWorkingHourData();
    }
  }, [id]);
  useEffect(() => {
    getServiceList();
  }, []);

  const getTeamData = () => {
    ApiClient.teamGet(id)
      .then((res) => {
        setListData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getServiceList = () => {
    if (isNivanCareTeam && ApiClient?.getEnableAppointmentService) {
      ApiClient?.getEnableAppointmentService()
        .then((res) => {
          setServiceData(res?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      ApiClient?.getAppointmentService()
        .then((res) => {
          setServiceData(res?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getTeamWorkingHourData = () => {
    ApiClient.getTeamWorkingHours(id)
      .then((res) => {
        const workingHoursData = res.data.working_hours;
        if (workingHoursData.length == 0) {
          const initialTimeSlots = days.map((day, index) => ({
            week_day: index,
            id: '',
            is_available: false,
            slots: [],
          }));
        } else {
          setTimeSlots(
            workingHoursData.map((workingHour) => ({
              week_day: workingHour.week_day,
              id: workingHour.id,
              is_available: workingHour ? workingHour.is_available : false,
              slots: workingHour.slots || [],
            }))
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteTeamWorkingHourData = (id) => {
    ApiClient.deleteTeamWorkingHours(id)
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  const getServicesClinic = () => {
    setIsLoading(true);
    ApiClient.getCareManagersByCareManager('')
      .then((res) => {
        setIsLoading(false);
        setCareManagersList(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    setServerImage(listData?.profile_url);
    let teamStatus = { ...listData };
    delete teamStatus?.profile_url;
    teamStatus.status_uuid = listData?.status?.uuid;
    let locations = [];
    let services = [];
    let admins = [];

    teamStatus?.locations?.map((item) => locations.push(item.id));
    teamStatus?.departments?.map((item) => locations.push(item.id));
    teamStatus?.services?.map((item) => services.push(item.id));
    teamStatus?.admins?.map((item) => admins.push(item.id));

    setFormData({
      ...teamStatus,
      admins_ids: admins,
      locations: locations,
      services: services,
      department: teamStatus?.department?.id,
      appointment_slot_window: appointmentSlotWindow,
    });
    setSelectedClinics(locations);
    setAppointmentSlotWindow(listData?.appointment_slot_window || '5');
    setIsLoading(listData?.isFetching);
  }, [listData, locations, serviceData]);

  useEffect(() => {
    const unloadHandler = (event) => {
      if (unsavedChanges) {
        const message = 'You have unsaved changes. Are you sure you want to leave?';
        event.returnValue = message;
        return message;
      }
    };

    window.addEventListener('beforeunload', unloadHandler);

    return () => {
      window.removeEventListener('beforeunload', unloadHandler);
    };
  }, [unsavedChanges]);

  useEffect(() => {
    if (appointmentSlotWindow) {
      setFormData({ ...formData, appointment_slot_window: appointmentSlotWindow });
    }
  }, [appointmentSlotWindow]);

  useEffect(() => {
    if (timeSlots && typeof timeSlots === 'object' && !Array.isArray(timeSlots)) {
      // Convert timeSlots to an array if it's an object
      const timeSlotsArray = Object.values(timeSlots);
      setFormData({ ...formData, timeSlots: timeSlotsArray });
    } else if (Array.isArray(timeSlots)) {
      setFormData({ ...formData, timeSlots });
    }
  }, [timeSlots]);

  const handleChange = (key, val) => {
    if (key === 'locations') {
      setSelectedClinics(val);
    }
    formData[key] = val;
    setFormData({ ...formData, appointment_slot_window: appointmentSlotWindow });
    setPms(val);
    setData([]);
    setUnsavedChanges(true); // Marking changes as unsaved
  };

  const handleTabClick = (index) => {
    // Check if there are unsaved changes
    if (unsavedChanges) {
      const confirmLeave = window.confirm('You have unsaved changes. Do you really want to leave?');
      if (confirmLeave) {
        setUnsavedChanges(false);
        setCurrentStep(index);
        setActiveTab(steps[index]);
      }
    } else {
      setCurrentStep(index);
      setActiveTab(steps[index]);
    }
  };

  const searchAdminProfiles = () => {
    let qry = { role: assignRole ? assignRole : 'health_coach_lead', search_str: formData?.team_lead?.full_name };
    ApiClient.getSearchTeam(qry)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const roles = Object.keys(panelRoles)
    .filter((item) => item !== 'doctor_pms')
    .map((item) => {
      return {
        id: item,
        title: teamRoles(item),
      };
    });

  const languages = [
    { title: 'Hindi', id: 'hindi' },
    { title: 'English', id: 'english' },
    { title: 'Marathi', id: 'marathi' },
    { title: 'Telugu', id: 'telugu' },
  ];

  const genderData = [
    { title: 'Male', id: 'male' },
    { title: 'Female', id: 'female' },
    { title: 'Other', id: 'other' },
  ];

  const inputFieldsData = [
    {
      key: 'full_name',
      placeholder: 'Full Name',
      singleView: true,
      label: 'Name',
      viewType: 'text',
      retuired: true,
      maxLength: 120,
      message: errors?.full_name,
    },
    {
      key: 'role',
      placeholder: 'Select Role ',
      label: 'Role in Team',
      viewType: 'dropDown',
      retuired: true,
      data: roles,
      message: errors?.role,
      hide: userData.id == id && userData.role != 'super_admin',
    },

    {
      viewType: 'searchBox',
      // hide: userData.id == id,
    },
    {
      key: 'status_uuid',
      placeholder: 'Account Status',
      label: 'Status',
      viewType: 'dropDown',
      data: status,
      style: { maxWidth: '50%' },
      message: errors?.status,
      setKey: 'uuid',
    },
    {
      key: 'phone',
      label: 'Mobile No.',
      placeholder: '10 Digit Mobile Number',
      viewType: 'text',
      retuired: true,
      maxLength: 10,
      validateNumbers: true,
      showCode: true,
      message: errors?.phone,
    },
    {
      key: 'email',
      placeholder: 'Email Address',
      label: 'Email Address',
      viewType: 'text',
      retuired: true,
      message: errors?.email,
    },
    isNivanCare && {
      key: 'department',
      placeholder: 'Select Department',
      label: 'Department',
      viewType: 'dropDown',
      data: departments.filter((item) => {
        if (pms == 'doctor_pms') {
          if (item.title === 'PMS') {
            return item;
          }
        } else {
          return item;
        }
      }),
      message: errors?.department,
    },
    {
      key: 'services',
      multiple: true,
      placeholder: 'Select Service',
      label: 'Select Service',
      viewType: 'tags',
      data: serviceData,
      emptyRecordMsg: 'No Service Available',
    },
    isClinic && {
      key: 'locations',
      multiple: true,
      message: errors?.locations_id,
      placeholder: isNivanCareTeam ? 'Select Hospitals' : 'Select Clinic',
      label: isNivanCareTeam ? 'Select Hospitals' : 'Select Clinic',
      viewType: 'tags',
      multiple: true,
      data: locations,
      retuired: true,
    },
    isNivanCareTeam && {
      key: 'can_accept_payment',
      message: errors?.error,
      placeholder: 'Can Accept Payment ',
      label: 'Can Accept Payment ',
      viewType: 'toggle',
      retuired: true,
    },
  ];

  const otherInputFieldsData = [
    {
      key: 'intro',
      placeholder: 'Short information about team member',
      singleView: true,

      label: 'Short Intro',
      viewType: 'text',
      retuired: true,
      message: errors?.intro,
    },
    {
      key: 'meeting_url',
      placeholder: 'Meeting Url',
      label: 'Meeting Url',
      viewType: 'text',
    },

    {
      key: 'gender',
      placeholder: 'Select Gender',
      label: 'Select Gender',
      viewType: 'dropDown',
      retuired: true,
      data: genderData,
      message: errors?.gender && 'This field may not be blank.',
    },
    {
      key: 'language',
      multiple: true,
      message: errors?.language,
      placeholder: formData?.language?.length > 0 ? ' ' : 'Add Known Languages',
      label: 'Add Language',
      viewType: 'tags',
      retuired: true,
      multiple: true,
      data: languages,
    },
    {
      key: 'specialization',
      placeholder: 'Specialization/Designation',
      label: 'Specialization/Designation',
      viewType: 'text',
      // retuired: true,
      // message: errors?.intro,
    },
    {
      key: 'degree',
      placeholder: 'Degree',
      label: 'Degree',
      viewType: 'text',
      message: errors?.degree,
      hideField: formData.role !== 'doctor' && formData.role !== 'health_coach',
    },

    {
      key: 'license_number',
      placeholder: 'License Number',
      label: 'License Number',
      viewType: 'text',
      message: errors?.license_number,
      hideField: formData.role !== 'doctor',
    },

    // {
    //   key: 'signature',
    //   placeholder: 'signature ',
    //   label: 'signature',
    //   viewType: 'text',
    //   message: errors?.signature,
    //   hideField: formData.role !== 'doctor',
    //   type: 'file',
    //   accept: 'image/*',
    // },
  ];

  const renderZohoDetails = () => {
    return (
      <>
        <div className='articleInputDiv'>
          <CoreInputBox
            validateNumbers
            labelStyle={{ fontWeight: 'var(--lightWeight)' }}
            placeholder='Staff Id'
            label='Staff Id'
            setValue={(data) => {
              setFormData({ ...formData, staff_id: data });
            }}
            value={formData.staff_id || ''}
            msgStyle={{ color: 'red' }}
          />
        </div>
        <div className='articleInputDiv'>
          <CoreInputBox
            validateNumbers
            labelStyle={{ fontWeight: 'var(--lightWeight)' }}
            placeholder='Service Id'
            label='Service Id'
            setValue={(data) => {
              setFormData({ ...formData, service_id: data });
            }}
            value={formData.service_id || ''}
            msgStyle={{ color: 'red' }}
          />
        </div>
      </>
    );
  };

  const handleItemClick = (item, key) => {
    formData[key] = item;
    setFormData({ ...formData, appointment_slot_window: appointmentSlotWindow });
    setData([]);
  };
  console.warn('formData', formData);
  const renderFormData = (showData) => {
    return showData.map((item, index) => {
      if (item?.hide) {
        return;
      }
      if (item?.hideField) {
        return;
      }

      if (item?.viewType == 'text') {
        return (
          <div key={index} className='articleInputDiv' style={item.singleView && { width: '100%' }}>
            <CoreInputBox
              validateNumbers={item.validateNumbers}
              showCode={item.showCode}
              details={item.detail}
              retuired={item.retuired}
              labelStyle={{ margin: '24px 0 4px 0', fontWeight: 'var(--lightWeight)' }}
              placeholder={item.placeholder}
              label={item.label}
              maxLength={item.maxLength}
              setValue={(data) => {
                if (item.type == 'file') {
                  data = data[0];
                }
                handleChange(item.key, data);
              }}
              value={item.type != 'file' ? formData[item.key] : ''}
              showMSG={item.message}
              msgStyle={{ color: 'red' }}
              accept={item.accept}
              type={item.type}
            />
            {formData[item.key] &&
              item.type == 'file' &&
              (typeof formData[item.key] == 'object' ? (
                <div className='mt-4'>
                  <img className='showImageStyle' src={URL.createObjectURL(formData[item.key])} />
                </div>
              ) : (
                <div className='mt-4'>
                  <img className='showImageStyle' src={formData[item.key]} />
                </div>
              ))}
          </div>
        );
      } else if (item?.viewType == 'dropDown') {
        return (
          <div key={index} className='articleInputDiv' style={item.singleView && { width: '100%' }}>
            <Select2DropDown
              data={item.data}
              retuired={item.retuired}
              labelStyle={{ margin: '24px 0 4px 0', fontWeight: 'var(--lightWeight)' }}
              placeholder={item.placeholder}
              label={item.label}
              onSelect={(data) => handleChange(item.key, data, item)}
              value={formData[item.key]}
              showMSG={item.message}
              msgStyle={{ color: 'red' }}
              valueKey={item.setKey}
            />
          </div>
        );
      } else if (item?.viewType == 'searchBox' && !hideAssignRole) {
        if (formData?.role == 'doctor' || formData.role == 'health_coach') {
          return (
            <div key={index} className='articleInputDiv mt-4' style={item.singleView && { width: '100%' }}>
              {!isNivanCareTeam ? (
                <CoreSearchDropDown
                  {...props}
                  setTimeOutData={searchAdminProfiles}
                  isAutoSave
                  inputValue={formData?.team_lead?.full_name || ''}
                  inputStyle={{ height: '39px' }}
                  data={data}
                  setData={setData}
                  setInputValue={(data) => {
                    setFormData({ ...formData, team_lead: { full_name: data } });
                  }}
                  onClickItem={(item) => handleItemClick(item, 'team_lead')}
                  placeholder={'Assign ' + teamRoles(assignRole ? assignRole : 'health_coach_lead')}
                  value={formData?.team_lead?.full_name || ''}
                  label={'Assign ' + teamRoles(assignRole ? assignRole : 'health_coach_lead')}
                  dropDownStyle={{ position: 'relative', right: '0px', left: '0px' }}
                  searchKey='full_name'
                  isClearValue
                  isRightIconref
                />
              ) : (
                <div key={index} className='articleInputDiv articletagshow'>
                  <TagsShow
                    displayValue='full_name'
                    data={careManagersList}
                    keepSearchTerm={true}
                    retuired={item.retuired}
                    // labelStyle={{ margin: '24px 0 4px 0', fontWeight: 'var(--lightWeight)' }}
                    // placeholder={'Assign Care Manager Lead'}
                    label={'Assign Care Manager Lead'}
                    setValue={(data) => setFormData({ ...formData, admins_ids: data })}
                    value={formData?.admins_ids || ''}
                    // showMSG={item.message}
                    msgStyle={{ color: 'red' }}
                  />
                </div>
              )}
            </div>
          );
        } else {
          return;
        }
      } else if (item?.viewType == 'tags') {
        return (
          <div key={index} className='articleInputDiv articletagshow'>
            <TagsShow
              details={item.detail}
              tagsStyle={item.tagsStyle}
              data={item.data}
              keepSearchTerm={true}
              retuired={item.retuired}
              labelStyle={{ margin: '24px 0 4px 0', fontWeight: 'var(--lightWeight)' }}
              placeholder={item.placeholder}
              label={item.label}
              setValue={(data) => handleChange(item.key, data)}
              value={formData[item.key]}
              showMSG={item.message}
              msgStyle={{ color: 'red' }}
              emptyRecordMsg={item?.emptyRecordMsg}
            />
          </div>
        );
      } else if (item?.viewType == 'toggle') {
        return (
          <div className='articleInputDiv mt-4' style={{ width: '100%' }}>
            <Toggle className='ms-2' onChange={(data) => handleChange(item.key, data)} type='checkbox' checked={formData?.can_accept_payment} />
            <label className='capitalize pointer' style={{ marginLeft: '10px' }}>
              {item.label}
            </label>
          </div>
        );
      }
    });
  };

  const handleNext = () => {
    if (
      (!formData?.phone || !formData?.email || !formData?.full_name || !formData?.role || (formData?.locations.length == 0 && isClinic)) &&
      currentStep == 0
    ) {
      toast.error('Please fill all the required fields');
    } else if ((!formData?.intro || !formData?.gender || !formData?.language || formData?.language.length == 0) && currentStep == 1) {
      toast.error('Please fill all the required fields');
    } else {
      let nextStep = currentStep + 1;

      if (formData.role !== 'doctor' && formData.role !== 'doctor_pms' && currentStep === 1) {
        nextStep += 1;
      }

      if (nextStep < steps.length) {
        setCurrentStep(nextStep);
        setActiveTab(steps[nextStep]);
      }
    }
  };

  const handleBack = () => {
    let prevStep = currentStep - 1;

    if (formData.role !== 'doctor' && formData.role !== 'doctor_pms' && currentStep === 3) {
      prevStep -= 1;
    }

    if (prevStep >= 0) {
      setCurrentStep(prevStep);
      setActiveTab(steps[prevStep]);
    }
  };

  const addTimeSlot = (day) => {
    setTimeSlots((prevTimeSlots) => {
      const newTimeSlots = [...prevTimeSlots];
      const dayIndex = days.indexOf(day);
      const timeSlot = newTimeSlots[dayIndex];
      const weekDay = timeSlot.week_day;
      const newSlots = [...(timeSlot.slots || [])];
      newSlots.push({ start_time: '', end_time: '', location_id: '' });
      timeSlot.slots = newSlots;
      timeSlot.is_available = true;
      newTimeSlots[dayIndex] = timeSlot;
      return newTimeSlots;
    });
  };

  const removeTimeSlot = (day, index) => {
    // Implement the logic to remove a time slot
    setTimeSlots((prevTimeSlots) => {
      const updatedSlots = [...prevTimeSlots[day].slots];
      updatedSlots.splice(index, 1);
      return { ...prevTimeSlots, [day]: { ...prevTimeSlots[day], slots: updatedSlots } };
    });
  };

  // Helper function to calculate the time difference in hours
  const isTimeDifferenceValid = (start, end) => {
    const startTime = new Date(`2000-01-01T${start}`);
    const endTime = new Date(`2000-01-01T${end}`);
    const timeDiff = Math.abs(endTime - startTime) / 36e5; // Difference in hours
    return timeDiff >= 1;
  };

  const renderDayRow = (day) => {
    const dayIndex = days.indexOf(day);
    const timeSlot = timeSlots[dayIndex];
    const weekDay = days[dayIndex];
    const isAvailable = timeSlot?.is_available;
    const slots = timeSlot?.slots || [];

    const formatTime = (time) => {
      if (typeof time !== 'string') {
        return null;
      }
      const [hours, minutes, seconds] = time.split(':');
      let newSlotDate = new Date();
      newSlotDate.setHours(hours, minutes, seconds);
      return new Date(newSlotDate);
    };
    return (
      <tr key={day}>
        <td>{weekDay}</td>
        <td style={{ width: '20%' }}>
          <select
            className='form-select'
            value={isAvailable ? 'Available' : 'Not Available'}
            onChange={(e) => {
              const newStatus = e.target.value;
              const newTimeSlots = [...timeSlots];
              newTimeSlots[dayIndex].is_available = newStatus === 'Available';
              if (newStatus === 'Not Available') {
                newTimeSlots[dayIndex].slots = [];
              } else {
                addTimeSlot(day);
              }
              setTimeSlots(newTimeSlots);
            }}
            style={{ color: isAvailable ? 'black' : 'red' }}
          >
            <>
              <option value='Available' style={{ color: 'black' }}>
                Available
              </option>
              <option value='Not Available' style={{ color: 'red' }}>
                Not Available
              </option>
            </>
          </select>
        </td>
        <td>
          {isAvailable && (
            <>
              {slots.map((slot, index) => (
                <div className='slot-row' key={index}>
                  <div className='slots-time'>
                    {!isBabyMd && (
                      <div className='input-container'>
                        <input
                          type='time'
                          value={slot?.start_time || ''}
                          onChange={(e) => {
                            const startTime = e.target.value;
                            const newTimeSlots = [...timeSlots];
                            newTimeSlots[dayIndex].slots[index].start_time = startTime;
                            setTimeSlots(newTimeSlots);
                          }}
                          onBlur={(e) => {
                            e.target.blur(); // Triggering blur to close the time picker
                          }}
                        />
                        <div className='inputMsg'>{errors?.working_hours?.[dayIndex]?.slots?.[index]?.start_time || ''}</div>
                      </div>
                    )}

                    {!isBabyMd && (
                      <div className='input-container'>
                        <input
                          type='time'
                          value={slot?.end_time || ''}
                          onChange={(e) => {
                            const endTime = e.target.value;
                            const newTimeSlots = [...timeSlots];
                            newTimeSlots[dayIndex].slots[index].end_time = endTime;
                            setTimeSlots(newTimeSlots);
                          }}
                          min={slot?.start_time || ''}
                          onBlur={(e) => {
                            e.target.blur(); // Triggering blur to close the time picker
                          }}
                        />
                        <div className='inputMsg'>{errors?.working_hours?.[dayIndex]?.slots?.[index]?.end_time || ''}</div>
                      </div>
                    )}

                    {isBabyMd && (
                      <div>
                        <DatePicker
                          format='hh:mm a'
                          showMeridian={true}
                          hideMinutes={(minute) => minute % 15 !== 0}
                          onClean={(time) => {
                            const newTimeSlots = [...timeSlots];
                            newTimeSlots[dayIndex].slots[index].start_time = null;
                            setTimeSlots(newTimeSlots); // Update state to trigger rerender
                          }}
                          onSelect={(time) => {
                            let consultStartTime = new Date(time);
                            const hours = consultStartTime.getHours();
                            const minutes = consultStartTime.getMinutes();
                            const seconds = consultStartTime.getSeconds();

                            const timeVal = `${hours}:${minutes}:${seconds}`;
                            const newTimeSlots = [...timeSlots];
                            newTimeSlots[dayIndex].slots[index].start_time = timeVal;
                            setTimeSlots(newTimeSlots);
                          }}
                          value={slot?.start_time ? formatTime(slot.start_time) : null}
                        />
                        <div className='inputMsg'>{errors?.working_hours?.[dayIndex]?.slots?.[index]?.start_time || ''}</div>
                      </div>
                    )}
                    {isBabyMd && (
                      <div>
                        <DatePicker
                          format='hh:mm a'
                          showMeridian={true}
                          hideMinutes={(minute) => minute % 15 !== 0}
                          onClean={(time) => {
                            const newTimeSlots = [...timeSlots];
                            newTimeSlots[dayIndex].slots[index].end_time = null;
                            setTimeSlots(newTimeSlots);
                          }}
                          onSelect={(time) => {
                            let consultStartTime = new Date(time);
                            const hours = consultStartTime.getHours();
                            const minutes = consultStartTime.getMinutes();
                            const seconds = consultStartTime.getSeconds();

                            const timeVal = `${hours}:${minutes}:${seconds}`;
                            const newTimeSlots = [...timeSlots];
                            newTimeSlots[dayIndex].slots[index].end_time = timeVal;
                            setTimeSlots(newTimeSlots);
                          }}
                          value={slot?.end_time ? formatTime(slot.end_time) : null}
                        />
                        <div className='inputMsg'>{errors?.working_hours?.[dayIndex]?.slots?.[index]?.end_time || ''}</div>
                      </div>
                    )}
                  </div>
                  <div className='slot-clinic'>
                    <select
                      className='form-select'
                      value={slot?.location_id || (isNivanCareTeam ? 'Select Hospitals' : 'Select Clinic')}
                      onChange={(e) => {
                        const newLocationId = e.target.value;

                        setTimeSlots((prevTimeSlots) => {
                          const newTimeSlots = [...prevTimeSlots];
                          newTimeSlots[dayIndex].slots[index].location_id = newLocationId;

                          return newTimeSlots;
                        });
                      }}
                    >
                      <option value=''>{isNivanCareTeam ? 'Select Hospital' : 'Select Clinic'}</option>

                      {selectedClinics.map((clinic) => {
                        const selectedLocation = locations.find((loc) => loc.id === clinic);

                        return (
                          <option value={selectedLocation.id} key={clinic.id}>
                            {selectedLocation ? selectedLocation.title : 'Unknown Clinic'}
                          </option>
                        );
                      })}
                    </select>
                    <div className='inputMsg'>{errors?.working_hours?.[dayIndex]?.slots?.[index]?.location_id || ''}</div>
                  </div>
                  <div className='slot-remove'>
                    {slots.length > 1 && (
                      <button
                        style={{
                          border: '1px solid #FF0000',
                          borderRadius: '8px',
                          padding: '8px',
                          background: '#fff',
                          color: '#FF0000',
                          marginLeft: '10px',
                        }}
                        onClick={() => {
                          const slotId = slot?.id;
                          deleteTeamWorkingHourData(slotId);
                          setTimeSlots((prevTimeSlots) => {
                            const newTimeSlots = [...prevTimeSlots];
                            const dayIndex = days.indexOf(day);
                            const timeSlot = newTimeSlots[dayIndex];
                            timeSlot.slots = timeSlot.slots.filter((_, i) => i !== index);
                            newTimeSlots[dayIndex] = timeSlot;
                            return newTimeSlots;
                          });
                        }}
                      >
                        X
                      </button>
                    )}
                  </div>
                </div>
              ))}
              <div className='slot-add'>
                <button
                  style={{
                    border: '1px solid',
                    borderRadius: '8px',
                    padding: '8px',
                    background: '#fff',
                    marginTop: '2px',
                  }}
                  onClick={() => addTimeSlot(day)}
                >
                  + Add Another Slot
                </button>
              </div>
            </>
          )}
        </td>
      </tr>
    );
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 'Credentials':
        return (
          <CommonBorderBox className='justify-content-between teamBorderBox mt-3'>
            <div className='mb-4 semiBoldWeight'>Other Details</div>
            <div className='teamFormDiv'>{renderFormData(otherInputFieldsData)}</div>
          </CommonBorderBox>
        );
      case 'Zoho Details':
        return (
          <CommonBorderBox className='justify-content-between teamBorderBox mt-3'>
            <div className='mb-4 semiBoldWeight'>Zoho Details</div>
            <div className='teamFormDiv'>{renderZohoDetails()}</div>
          </CommonBorderBox>
        );
      case 'Working Hours & Appointments':
        return (
          <div className='working-hours-form'>
            <CommonBorderBox className='justify-content-between teamBorderBox mt-3'>
              <div className='mb-4 semiBoldWeight'>Appointment Slot</div>
              <div className='slider-container mb-4' style={{ paddingBottom: '25px' }}>
                <Slider
                  min={5}
                  max={60}
                  step={5}
                  graduated
                  progress
                  renderMark={(mark) => {
                    if ([5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60].includes(mark)) {
                      return (
                        <div style={{ textAlign: 'center' }}>
                          <span style={{ fontWeight: '400', color: '#B2B1B1', fontSize: '20px' }}>{mark}</span>
                          <br />
                          <span style={{ fontSize: '10px', color: '#B2B1B1' }}>min</span>
                        </div>
                      );
                    }
                    return null;
                  }}
                  value={appointmentSlotWindow}
                  defaultValue={5}
                  onChange={(value) => setAppointmentSlotWindow(value)}
                  style={{ width: '50%' }}
                  disabled={props?.id}
                />
              </div>

              <hr style={{ marginTop: '25px', border: '1px solid', color: '#D8D8D8' }} />

              <div className='mb-4 semiBoldWeight'>Working Hours</div>
              <div className='table-responsive' style={{ border: '1px solid', borderColor: '#E3E5E7', borderRadius: '8px', padding: '20px' }}>
                <table className='table'>
                  <thead>
                    <tr style={{ color: '#474D53' }}>
                      <th>Day</th>
                      <th>Status</th>
                      <th>Time Slots & {`${isNivanCareTeam ? 'Hospitals' : 'Clinics'}`}</th>
                    </tr>
                  </thead>
                  <tbody>{days.map((day) => renderDayRow(day))}</tbody>
                </table>
              </div>
            </CommonBorderBox>
          </div>
        );
      default:
        return (
          <CommonBorderBox className='d-flex justify-content-between teamBorderBox'>
            <div className='d-flex teamAddForm' style={{ gap: '32px', justifyContent: 'space-between' }}>
              <div className='teamFormDiv'>
                <div style={{ fontWeight: 600 }}>Basic Info</div>
                {renderFormData(inputFieldsData)}
              </div>
              {/* <div>
                <div className='profilePhotoStyle'>
                  <input
                    ref={inputRef}
                    type='file'
                    className='d-none'
                    value=''
                    accept='image/*'
                    onChange={(e) => {
                      formData.profile_url = e.target.files[0];
                      setImage(e.target.files[0]);
                    }}
                  />
                  {image && <img src={URL.createObjectURL(image)} />}
                  {serverImage && !image && <img src={serverImage} />}
                  {!image && !serverImage && <div className='w-50'>Drop Profile Photo</div>}
                </div>
                <div>
                  <BorderBtnWithBg
                    onClick={() => {
                      inputRef.current.click();
                    }}
                    className='uploadPhotoBtn'
                    iconImg={upload}
                    title='Upload'
                  />
                </div>
              </div> */}
            </div>
          </CommonBorderBox>
        );
    }
  };

  return (
    <div>
      <div>
        <ul className='nav nav-tabs'>
          {steps.map(
            (tab, index) =>
              (tab !== 'Working Hours & Appointments' || formData.role === 'doctor' || formData.role === 'doctor_pms') &&
              (isClinic || tab !== 'Working Hours & Appointments') && (
                <li className='nav-item' key={tab}>
                  <button
                    className={`nav-link ${currentStep === index ? 'active' : ''}`}
                    onClick={() => {
                      if (props.id) {
                        handleTabClick(index);
                      }
                    }}
                    style={{
                      backgroundColor: activeTab === tab ? '#DEE5FB' : 'white',
                      color: activeTab === tab ? '#243B86' : 'black',
                      border: '1px solid #DEE5FB',
                    }}
                  >
                    {tab}
                  </button>
                </li>
              )
          )}
        </ul>

        <div className='tab-content'>{renderTabContent()}</div>
      </div>

      <div className='w-100'>
        <ToastContainer />
        {currentStep > 0 && !props.id && <CoreButton type='button' onClick={handleBack} title='Previous' />}
        {currentStep < steps.length - 1 && !props.id && <CoreButton type='button' onClick={handleNext} title='Next' />}

        {props.id && (
          <CoreButton
            onClick={() => {
              onClick(formData);
              scrollToTop();
            }}
            title='Update Details'
          />
        )}
        {!props.id && currentStep === steps.length - 1 && (
          <CoreButton
            onClick={() => {
              onClick(formData);
              scrollToTop();
            }}
            title='Save Details'
          />
        )}
      </div>

      <Loader show={isLoading || reduxIsloading} />
    </div>
  );
}

export default TeamForm;
