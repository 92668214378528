import { useGetCockpitDataQuery } from 'redux/cockpitSlice';

const useCockpit = () => {
  return useGetCockpitDataQuery(undefined, {
    selectFromResult: (res) => {
      const data = res?.data?.data?.business_config;
      if (!data) return {};
      return data.reduce((acc, item) => {
        return { ...acc, [item.key]: item.isActive };
      }, {});
    },
  });
};

export default useCockpit;
