import useApiManager from 'networking/ApiManager';
import React, { useEffect, useState } from 'react';
import {
  CommonAlert,
  CoreButton,
  CoreInputBox,
  CoreTextArea,
  PrescriptionMedicines,
  Select2DropDown,
  TagSelectPicker,
} from 'whealth-core-web/components';
import { useLocation, useParams } from 'react-router-dom';
import { Button, DatePicker, Modal } from 'rsuite';
import { formulationData, splitArrData, splitArrObjData } from '../Helper';

function AddPrescriptionFormDynamic(props) {
  const {
    onClick,
    setErrors,
    errors,
    dynamicPerscriptionData,
    selectedDepartment,
    setDynamicPerscriptionData,
    setAlertType,
    setIsShowAlert,
    isShowAlert,
    alertType,
    isNivanCareTeam,
  } = props;
  const location = useLocation();
  const [fieldErrors, setFieldErrors] = useState([]);
  const [doctorList, setDoctorList] = useState([]);
  const duplicateId = location?.state?.id;
  let { id } = useParams();

  useEffect(() => {
    ApiClient.prescriptionDoctorList(id, 'doctor').then((res) => {
      setDoctorList(res.data);
    });
  }, []);

  const initialValues = {
    dosage: '000',
    medicine_id: '',
    duration: 0,
    frequency: '',
    note: '',
    meal_duration_unit: '',
    meal_duration_value: null,
    is_optional: false,
    medicine: { name: '' },
  };

  const [medicineList, setMedicineList] = useState([]);
  const [medicineListId, setMedicineListId] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [isCreateModal, setIsCreateModal] = useState();
  const [newMedicineName, setNewMedicineName] = useState('');
  const [medicineFormulation, setMedicineFormulation] = useState();
  const [medicineError, setMedicineError] = useState({});
  const [createMedicineIndex, setCreateMedicineIndex] = useState();

  const ApiClient = useApiManager();

  useEffect(() => {
    handleOnFocus();
  }, []);

  useEffect(() => {
    setMedicineListId(medicineListId);
  }, [medicineListId]);

  useEffect(() => {
    let data = dynamicPerscriptionData;
    if (data && data.isUpdate) {
      let tempData = { ...data };
      tempData.goal_id = data.goal.id;
      tempData.title = data.goal.title;

      if (tempData.prescription_medicines) {
        tempData.prescription_medicines.forEach((item, index) => {
          item.medicine_id = item?.medicine?.id;
          if (duplicateId) {
            delete item.id;
          }
        });
      }
      if (duplicateId) {
        delete tempData.status;
      }
      setDynamicPerscriptionData(tempData);
    }
  }, []);

  const removeForm = (index) => {
    let dataObj = dynamicPerscriptionData;
    dataObj.prescription_medicines?.splice(index, 1);
    errors.prescription_medicines?.splice(index, 1);
    setErrors({ ...errors });
    setDynamicPerscriptionData((prevState) => {
      return { ...prevState, ...dataObj };
    });
  };

  const handleChangeFields = (data, key) => {
    dynamicPerscriptionData[key] = data;
    setDynamicPerscriptionData({ ...dynamicPerscriptionData });
  };

  const handleArrChange = (data, key, index) => {
    dynamicPerscriptionData.prescription_medicines[index][key] = data;
    setDynamicPerscriptionData({ ...dynamicPerscriptionData });
  };

  const handleArrChangeNewSearch = (data, key, index) => {
    dynamicPerscriptionData.prescription_medicines[index][key] = data;
    setDynamicPerscriptionData({ ...dynamicPerscriptionData });
  };

  const handleCheckBoxClick = (val, isChecked, index, arrvalues) => {
    let values = arrvalues;
    if (val && isChecked) {
      values[val] = '1';
    } else if (val && !isChecked) {
      values[val] = 0;
    }

    // if (values.includes('1')) {
    //   if (!dynamicPerscriptionData.prescription_medicines[index].meal_time) {
    //     dynamicPerscriptionData.prescription_medicines[index].meal_time = 'before_meal';
    //   }
    // } else {
    //   delete dynamicPerscriptionData.prescription_medicines[index].meal_time;
    //   dynamicPerscriptionData.prescription_medicines[index].meal_duration_value = '';
    //   dynamicPerscriptionData.prescription_medicines[index].meal_duration_unit = '';
    // }

    let arrToString = values.join('');
    dynamicPerscriptionData.prescription_medicines[index].dosage = arrToString;
    setDynamicPerscriptionData({ ...dynamicPerscriptionData });
  };

  const handleMealTime = (val, index) => {
    dynamicPerscriptionData.prescription_medicines[index].meal_time = val;
    if (val == 'with_meal') {
      dynamicPerscriptionData.prescription_medicines[index].meal_duration_unit = null;
      dynamicPerscriptionData.prescription_medicines[index].meal_duration_value = null;
    }
    setDynamicPerscriptionData({ ...dynamicPerscriptionData });
  };

  const handleOnFocus = () => {
    let params = {
      page: 1,
    };
    ApiClient.getMedicines(params)
      .then((res) => {
        setMedicineList(res.data);
      })
      .catch((err) => console.log(err));
  };

  // const createMedicines = (index) => {
  //   let newMedicine = dynamicPerscriptionData.searchData[index][0];
  //   let newMedicineName = newMedicine?.name?.slice(19, newMedicine.name.length);
  //   newMedicine = { ...newMedicine, name: newMedicineName };
  //   ApiClient.createMedicinesDraft(newMedicine)
  //     .then((res) => {
  //       handleArrChangeNewSearch(res.data?.id, 'medicine_id', index);
  //       setMedicineList([...medicineList, res.data]);
  //       dynamicPerscriptionData.searchData = [];
  //       setDynamicPerscriptionData({ ...dynamicPerscriptionData });
  //       handleArrChange(res.data?.id, 'medicine_id', index);
  //     })
  //     .catch((err) => {
  //       if (err?.response?.data?.detail) {
  //         if (errors?.prescription_medicines?.length - 1 == index) {
  //           errors.prescription_medicines[index].medicine_id = [err?.response?.data?.detail];
  //         } else {
  //           let oldPrescriptionMedicine = errors.prescription_medicines;
  //           let newPrescriptionMedicine = [];
  //           Array.from(Array(index + 1).keys()).map((indexItem) => {
  //             if (index == indexItem) {
  //               newPrescriptionMedicine.push({ medicine_id: [err?.response?.data?.detail] });
  //             } else if (oldPrescriptionMedicine[indexItem]?.medicine_id) {
  //               newPrescriptionMedicine.push(oldPrescriptionMedicine[indexItem]);
  //             } else {
  //               newPrescriptionMedicine.push({});
  //             }
  //           });
  //           errors.prescription_medicines = newPrescriptionMedicine;
  //         }
  //         setErrors({ ...errors });
  //       }
  //     });
  // };

  const createMedicines = (index) => {
    setIsCreateModal(true);
    setCreateMedicineIndex(index);
    let newMedicine = dynamicPerscriptionData.searchData[index][0];
    let medicineName = newMedicine?.name?.slice(19, newMedicine.name.length);
    setNewMedicineName(medicineName);
    dynamicPerscriptionData.searchData = [];
    setDynamicPerscriptionData({ ...dynamicPerscriptionData });
  };

  const handleCancelClick = () => {
    setIsCreateModal(false);
    setNewMedicineName('');
    setMedicineFormulation('');
    setMedicineError({});
  };
  const handleSave = () => {
    let params = { name: newMedicineName };
    if (medicineFormulation && medicineFormulation != '') {
      params.formulation = medicineFormulation;
    }
    ApiClient.createMedicinesDraft(params)
      .then((res) => {
        setMedicineList([...medicineList, res.data]);
        setAlertType('alert-success');
        setIsShowAlert('Medicine Created Successfully');
        handleCancelClick();
        handleArrChange(res.data?.id, 'medicine_id', createMedicineIndex);
      })
      .catch((err) => {
        console.warn('createMedicinesDraft', err?.response);
        if (err?.response?.data?.errors || err?.response?.data?.detail) {
          setAlertType('alert-danger');
          setMedicineError(err.response.data.errors);
          setIsShowAlert(err?.response?.data?.detail);
        }
      });
  };
  const createMedicineModal = () => {
    return (
      <Modal backdrop='static' keyboard={false} open={isCreateModal} onClose={handleCancelClick}>
        <Modal.Header>
          <Modal.Title>Create Medicine</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className='d-flex flex-column'>
            <CommonAlert
              className='mt-3'
              setIsShowAlert={setIsShowAlert}
              isShowAlert={isShowAlert}
              alertType={alertType}
              setAlertType={setAlertType}
              errorMessage={medicineError}
            />
            <CoreInputBox
              showMSG={medicineError?.name}
              placeholder='Medicine Name'
              value={newMedicineName || ''}
              label='Medicine Name'
              setValue={(inputVal) => {
                setNewMedicineName(inputVal);
                // setValueObj({ ...valueObj, name: inputVal });
              }}
              maxLength={255}
              msgStyle={{ color: 'red' }}
              retuired
            />

            <div className=' w-100 mt-4'>
              <Select2DropDown
                value={medicineFormulation || ''}
                data={formulationData()}
                labelKey='title'
                label={'Medicine Formulation'}
                placeholder={'Medicine Formulation'}
                valueKey='id'
                retuired
                onSelect={(inputVal) => {
                  setMedicineFormulation(inputVal);
                }}
                showMSG={medicineError?.formulation}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => handleSave()} appearance='primary'>
            Create
          </Button>
          <Button onClick={() => handleCancelClick()} appearance='default'>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const searchMedicines = (query, id) => {
    if (!dynamicPerscriptionData.searchData) {
      dynamicPerscriptionData.searchData = [];
    }
    setSearchValue(query);
    if (query?.trim()?.length > 2) {
      ApiClient.searchMedicines(query)
        .then((res) => {
          dynamicPerscriptionData.searchData[id] = res.data.length
            ? res.data
            : [
                {
                  name: `Add New Medicine : ${query}`,
                  medicine_title_repr: `Add New Medicine : ${query}`,
                  iscreate: true,
                },
              ];
          setDynamicPerscriptionData({ ...dynamicPerscriptionData });
        })
        .catch((err) => console.log(err));
    } else if (
      dynamicPerscriptionData?.searchData[id]?.length &&
      dynamicPerscriptionData?.searchData[id][0]?.name?.includes('Add New Medicine : ')
    ) {
      dynamicPerscriptionData.searchData = [];
      setDynamicPerscriptionData({ ...dynamicPerscriptionData });
    }
  };

  const renderNestingFields = () => {
    if (dynamicPerscriptionData.prescription_medicines) {
      return (
        <>
          <PrescriptionMedicines
            handleArrChange={handleArrChange}
            searchMedicines={searchMedicines}
            removeForm={removeForm}
            medicineList={medicineList}
            setMedicineList={setMedicineList}
            prescriptionData={dynamicPerscriptionData}
            setPrescriptionData={setDynamicPerscriptionData}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            createMedicines={createMedicines}
            handleCheckBoxClick={handleCheckBoxClick}
            initialValues={initialValues}
            setErrors={setErrors}
            handleMealTime={handleMealTime}
            errors={errors}
          />
          <CoreButton
            onClick={() => {
              dynamicPerscriptionData.prescription_medicines.push({
                ...initialValues,
              });
              errors.prescription_medicines.push({});
              setDynamicPerscriptionData({ ...dynamicPerscriptionData });
            }}
            title='+ Add Medicine'
          />
        </>
      );
    }
  };

  const renderTextArea = (item, error) => {
    return (
      <CoreTextArea
        disabled={item.name == 'primary_diagnosis'}
        showMSG={error}
        labelStyle={{
          margin: '0 0 8px 0px',
          fontWeight: 'var(--lightWeight)',
        }}
        rows={2}
        label={item.label}
        value={item.answer || ''}
        retuired={item?.required}
        setValue={(data) => {
          item.answer = data;
          setDynamicPerscriptionData({ ...dynamicPerscriptionData });
        }}
      />
    );
  };

  const renderInputBox = (item, error) => {
    return (
      <CoreInputBox
        validateNumbers={item.type == 'number'}
        validatedecimal={item.type == 'float'}
        retuired={item?.required}
        labelStyle={{ fontWeight: 'var(--lightWeight)' }}
        label={item.label}
        value={item.answer || ''}
        showMSG={error}
        setValue={(data) => {
          item.answer = data;
          setDynamicPerscriptionData({ ...dynamicPerscriptionData });
        }}
      />
    );
  };

  const renderFields = (item, error) => {
    if (item.type == 'date') {
      return renderInputDateField(item, error);
    } else if (item.type == 'single_select') {
      return renderSingalSelect(item, error);
    } else if (
      item.type == 'multi_select' &&
      (item.name == 'diagnosis' ||
        item.name == 'co_morbidities' ||
        item.name == 'allergies' ||
        item.name == 'investigations') &&
      selectedDepartment == 1
    ) {
      return renderMultiSelectWithAdd(item, error);
    } else if (item.type == 'multi_select') {
      return renderMultiSelect(item, error);
    } else if (item.type == 'text') {
      return renderTextArea(item, error);
    } else {
      return renderInputBox(item, error);
    }
  };

  const checkOptionExit = (data, item) => {
    if (data && data?.length && !item.options.includes(data)) {
      item.searchData = data;
      setDynamicPerscriptionData({ ...dynamicPerscriptionData });
    } else {
      delete item.searchData;
      setDynamicPerscriptionData({ ...dynamicPerscriptionData });
    }
  };

  const addOptionInFields = (item, titleArray) => {
    let nonExitsString = titleArray.find((item2) => !item.options.includes(item2));
    if (nonExitsString) {
      let newOption = nonExitsString?.slice(6, nonExitsString.length);
      let apiData = {
        option: newOption,
        field_name: item?.name,
      };
      ApiClient.updateDepartmentFieldOption(selectedDepartment, apiData)
        .then((res) => {
          delete item.searchData;
          item.options = item.options + ' | ' + newOption;
          let oldAnswer = titleArray.filter((item2) => item2 != nonExitsString);
          oldAnswer.push(newOption);
          item.answer = oldAnswer.join('|');
          setDynamicPerscriptionData({ ...dynamicPerscriptionData });
        })
        .catch((err) => console.log(err));
    } else {
      item.answer = titleArray.join('|');
      setDynamicPerscriptionData({ ...dynamicPerscriptionData });
    }
  };

  const renderMultiSelectWithAdd = (item, error) => {
    let activeData = splitArrData(item?.answer?.toLowerCase(), '|');
    let arrData = splitArrObjData(item?.options, '|', 'title');
    if (item?.searchData?.length) {
      arrData.push({ title: 'Add : ' + item.searchData });
    }
    return (
      <div className='mb-3 capitalize'>
        <TagSelectPicker
          showMSG={error}
          label={item.label}
          data={arrData}
          style={{ width: '100%' }}
          labelKey='title'
          valueKey='value'
          placeholder={item.label}
          onSelect={(val, titleArray) => {
            addOptionInFields(item, titleArray);
          }}
          onSearch={(data) => checkOptionExit(data, item)}
          msgStyle={{ color: 'red' }}
          value={activeData}
        />
      </div>
    );
  };

  const renderMultiSelect = (item, error) => {
    let activeData = splitArrData(item?.answer?.toLowerCase(), '|');
    let arrData = splitArrObjData(item?.options, '|', 'title');
    return (
      <div className='mb-3 capitalize'>
        <TagSelectPicker
          showMSG={error}
          label={item.label}
          data={arrData}
          style={{ width: '100%' }}
          labelKey='title'
          valueKey='value'
          placeholder={item.label}
          onSelect={(val, titleArray) => {
            item.answer = titleArray.join('|');
            setDynamicPerscriptionData({ ...dynamicPerscriptionData });
          }}
          retuired={isNivanCareTeam && item?.required}
          msgStyle={{ color: 'red' }}
          value={activeData}
        />
      </div>
    );
  };

  const renderSingalSelect = (item, error) => {
    let arrData = splitArrObjData(item.options, '|', 'title');

    return (
      <Select2DropDown
        value={item?.answer}
        data={arrData}
        label={item.label}
        placeholder={item.label}
        valueKey='value'
        labelKey='title'
        getObjData
        retuired={true}
        onSelect={(selectedValue, dataObj) => {
          item.answer = dataObj.title;
          setDynamicPerscriptionData({ ...dynamicPerscriptionData });
        }}
        showMSG={error}
      />
    );
  };

  const renderInputDateField = (item, error) => {
    let defaultValue = '';
    if (item?.answer) {
      let date = new Date(item.answer);
      if (date != 'Invalid Date') {
        defaultValue = date;
      }
    }

    return (
      <div style={{ position: 'relative' }}>
        <div>
          <label class='inputlabel'>
            {item.required && <span class='requiredInput'> * </span>}
            {item.label}
          </label>
        </div>
        <DatePicker
          disabledDate={(date) => {
            if (new Date(date) < new Date().setHours(0, 0, 0)) {
              return true;
            } else {
              return false;
            }
          }}
          defaultValue={defaultValue}
          preventOverflow
          format='yyyy-MM-dd'
          placeholder='YYYY-MM-DD'
          onChange={(date) => {
            item.answer = date;
            setDynamicPerscriptionData({ ...dynamicPerscriptionData });
          }}
          placement='autoVerticalStart'
          calendarDefaultDate={defaultValue || new Date()}
          onOk={(date) => {
            item.answer = date;
            setDynamicPerscriptionData({ ...dynamicPerscriptionData });
          }}
          style={{ width: '100%', padding: 0, margin: 0 }}
        />
        {error && <div class='inputMsg'>{error}</div>}
      </div>
    );
  };

  const renderDataFields = () => {
    let renderData = () => {
      if (dynamicPerscriptionData?.prescription_schema) {
        return dynamicPerscriptionData?.prescription_schema.map((item, index) => {
          let fieldError = '';
          if (fieldErrors[index]) {
            fieldError = fieldErrors[index].answer;
          }
          return (
            <div key={index} className='w-100 mb-3'>
              {renderFields(item, fieldError)}
            </div>
          );
        });
      } else {
        return <div>No Record Found</div>;
      }
    };
    return (
      <>
        {alertType == 'alert-danger' && (
          <CommonAlert
            className='mt-3'
            setIsShowAlert={setIsShowAlert}
            isShowAlert={isShowAlert}
            alertType={alertType}
            setAlertType={setAlertType}
            errorMessage={medicineError}
          />
        )}
        <h5 className='card-title pt-2 pb-2'>Observation Details</h5>
        {renderData()}
      </>
    );
  };

  const checkrequired = (data) => {
    let isErrors = false;
    let errors = [];

    data.map((item) => {
      if (item.required) {
        if (item.answer) {
          if (typeof item.answer == 'string' && item.answer.trim().length == 0) {
            errors.push({ answer: 'This field is required' });
            isErrors = true;
          } else {
            errors.push({});
          }
        } else {
          errors.push({ answer: 'This field is required' });
          isErrors = true;
        }
      } else {
        errors.push({});
      }
    });

    return { isErrors, errors };
  };

  const renderFormDetails = () => {
    return (
      <>
        <div className='card mt-3'>
          <div className='card-body'>{renderDataFields()}</div>
        </div>

        {dynamicPerscriptionData?.enable_medicine && renderNestingFields()}
        {createMedicineModal()}
        <CoreButton
          onClick={() => {
            setErrors({ prescription_medicines: [] });
            setFieldErrors([]);
            let prescription_data = {
              data: dynamicPerscriptionData.prescription_schema,
            };

            if (dynamicPerscriptionData?.enable_medicine) {
              prescription_data.prescription_medicines = dynamicPerscriptionData.prescription_medicines;
            }

            let checkIserror = checkrequired(prescription_data.data);

            if (checkIserror.isErrors) {
              setFieldErrors(checkIserror.errors);
            } else {
              onClick(prescription_data);
            }
          }}
          customClass='btn btn-secondary btn-lg'
          title='Save Details'
        />
      </>
    );
  };

  return <div className='prescription-form'>{renderFormDetails()}</div>;
}

export default AddPrescriptionFormDynamic;
