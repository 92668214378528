import useApiManager from 'networking/ApiManager';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { setAlerts } from 'redux/Slices';
import { add, editIconY, uploaded } from 'res/images';
import { CommonAlert, EmptyView } from 'whealth-core-web/components';
import { dateFormat, profileImage, splitArrData, teamRoles, timeFormat } from 'whealth-core-web/components/Helper';
import CommonToolTip from './components/CommonToolTip';

function RenderService(props) {
  const { newQuestions } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const [serviceData, setServiceData] = useState([]);
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [alertType, setAlertType] = useState('alert-danger');
  const [totalPages, setTotalPages] = useState(1);
  const ApiClient = useApiManager();
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    getListData();
  }, []);
  const getListData = () => {
    setIsLoading(true);
    // let params = { page };
    // if (departmentID) {
    //   params.department_id = departmentID;
    // }
    ApiClient.patientServiceList(id)
      .then((res) => {
        setIsLoading(false);
        setServiceData(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const renderMultiSelect = (data) => {
    let ansArr = splitArrData(data.answer, '|');
    return (
      <ul>
        {ansArr.map((item, index) => {
          return <li key={index}>{item}</li>;
        })}
      </ul>
    );
  };

  const serviceDelete = (questionId) => {
    setIsLoading(true);
    // seterror({});
    ApiClient.deleteService(id, questionId)
      .then((res) => {
        setIsLoading(false);
        getListData();
        dispatch(setAlerts({ patient: { title: 'Patient Service deleted successfully.', type: 'alert-success' } }));
      })
      .catch((err) => {
        // setIsLoading(false);
        if (err.response.status == 500) {
          setIsShowAlert('Internal server error');
        } else {
          setIsShowAlert(err.response.data.message);
        }
        setAlertType('alert-danger');
        setIsShowAlert(err.response.data.detail || err.response.data.message);
        console.log(err);
      });
  };

  const serviceDataObj = (data) => {
    if (data?.length) {
      let sortedData = [...data].sort(function (a, b) {
        return a.order - b.order;
      });
      return sortedData.map((item, i) => {
        console.warn('item', item);
        return (
          <div className='d-flex'>
            <div className='w-100'>
              <span className='semiBold capitalize'>{item.label} · </span>
              <span className='prescriptiontime'>
                <pre className='zeefitness prefont_family firstletter_capitalize'>
                  {item.type == 'multi_select'
                    ? renderMultiSelect(item)
                    : item.type == 'date' && dateFormat(item.answer) != 'Invalid Date'
                    ? dateFormat(item.answer)
                    : item.type !== 'date_time' && item.answer}
                  {item.type == 'date_time' && dateFormat(item.answer)}{' '}
                  {item.type == 'date_time' && timeFormat(item.answer)}
                </pre>
              </span>
            </div>
          </div>
        );
      });
    }
  };

  const renderServiceList = () => {
    return serviceData.map((item, index) => {
      const newText = item;
      const buttonsData = () => {
        return (
          <div className='headingMilestone d-flex justify-content-between'>
            <div className='d-flex'>
              <div>
                <b>Assigned On &nbsp; &nbsp;</b>
                {dateFormat(item.created_at)} · {timeFormat(item.created_at)}
              </div>
              <div className='d-block  ms-2 '>
                <span className={`badge bg-info  capitalize ${item.status}`}>{item.status}</span>
              </div>
            </div>

            <div className='memberShipDetails' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <>
                {/* <div
                  className='mb-1 ms-2 pointer'
                  onClick={() => {
                    let windoConfirm = window.confirm('Are you sure you want to delete this service?');
                    if (windoConfirm) {
                      serviceDelete(item.id);
                    }
                  }}
                >
                  <div className='pt-1'>
                    <CommonToolTip
                      placement={'top'}
                      item={<span class='material-icons-outlined'>delete_outline</span>}
                      showValue={'Delete'}
                    />
                  </div>
                </div> */}

                <div
                  onClick={() => {
                    navigate(`/patient/${id}/serviceDetails/${item.id}/edit`, { state: { id: item.id, item: item } });
                  }}
                  className='mb-2 ms-2 pointer'
                >
                  <CommonToolTip
                    placement={'top'}
                    item={<img src={editIconY} className='icon' style={{ cursor: 'pointer' }} />}
                    showValue={'Edit'}
                  />
                </div>
              </>
            </div>
          </div>
        );
      };
      if (item?.service) {
        return (
          <div className='cardDataBorder'>
            <div key={index} className='d-flex'>
              <div className='w-100'>
                <div className='cardMilestone'>
                  {buttonsData()}
                  <div className='d-flex mt-1 mb-3 justify-content-between align-items-center'>
                    <div className='d-flex'>
                      <div className='me-2'>
                        <img
                          className='rounded coachImage float-start img-fluid'
                          src={profileImage(item.admin.gender, item.admin.profile_url)}
                        />
                      </div>
                      <div className='w-75 '>
                        <h5 className='coachtext capitalize'>{item.admin.full_name}</h5>
                        <p className='zeefitness capitalize'>{teamRoles(item.admin.role)}</p>
                      </div>
                    </div>
                  </div>
                  {item.service ? (
                    <div className='w-100 mb-2'>
                      <span className='semiBold capitalize'>
                        Service ·
                        <div>
                          <span className='prescriptiontime'>{item.service.title} </span>
                        </div>
                      </span>
                    </div>
                  ) : null}
                  {item.doctor ? (
                    <div className='w-100 mb-2'>
                      <span className='semiBold capitalize'>
                        Doctor ·
                        <div>
                          <span className='prescriptiontime'>{item.doctor.full_name} </span>
                        </div>
                      </span>
                    </div>
                  ) : null}
                  {item.location ? (
                    <div className='w-100 mb-2'>
                      <span className='semiBold capitalize'>
                        Hospital ·
                        <div>
                          <span className='prescriptiontime'>{item.location.name} </span>
                        </div>
                      </span>
                    </div>
                  ) : null}
                  {item.care_manager ? (
                    <div className='w-100 mb-2'>
                      <span className='semiBold capitalize'>
                        Care Manager ·
                        <div>
                          <span className='prescriptiontime'>{item.care_manager.full_name} </span>
                        </div>
                      </span>
                    </div>
                  ) : null}
                  {item?.data && serviceDataObj(item?.data)}
                  {item.estimated_cost || item.final_bill_amount ? (
                    <div className='w-100 mb-2'>
                      <span className='semiBold capitalize'>
                        Service Cost ·
                        <div>
                          <span className='prescriptiontime'>
                            {item.final_bill_amount > 0 ? item.final_bill_amount : item.estimated_cost}{' '}
                          </span>
                        </div>
                      </span>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        );
      }
    });
  };

  return (
    <div>
      {/* <CommonAlert isShowAlert={isShowAlert} setIsShowAlert={setIsShowAlert} alertType={alertType} /> */}
      <div className='d-flex'>
        <div className='pt-3'>
          <button onClick={newQuestions} type='button' className='btn mt-3 btn-outline-primary'>
            <img src={add} className='icon' /> Add Service
          </button>
        </div>
      </div>

      {renderServiceList()}

      <div className='d-flex justify-content-end'>
        {/* <div className='patientPagination mt-3'>
          <Pagination totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
        </div> */}
      </div>
    </div>
  );
}

export default RenderService;
